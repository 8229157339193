/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Text, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useEffect, useState, useRef } from 'react';

// import TimeAggregationFilter from '../table/TimeAggregationFilter';
import {
  getAllInstrumentOutputs,
  getAllInstruments,
  setAllInstruments,
  updateInstrument,
  createInstrument,
  getSelectedDashboardScenario,
  setEditInstrumentOutput,
  setEditInstrumentData,
  getEditInstrumentData,
  setAllInstrumentOutputs,
} from 'redux/DashboardSliceV2';
import Spinner from 'components/Spinner';
import { AppDispatch, getRandomColor } from 'utils/GlobalHelpers';
import TabsFilter from 'pages/PlanPageOutputOptimization/Plan/Filters/TabsFilter';

import PriviewFilterByComponent from '../PreviewFilterBy';
import TimeRangeFilter from '../TimeRangeFilter';
import EditableTitle from '../EditableTitle';

import PieChart from './PieChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import ComboChart from './ComboChart';

const ChartCard = ({ chartData, isDashboardLayout, isPreview }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const allInstrumentsOutputs = useSelector(getAllInstrumentOutputs);
  const allInstruments = useSelector(getAllInstruments);
  const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);
  const editInstrumentData = useSelector(getEditInstrumentData);

  const timeGranularity =
    chartData?.time_granularity === 'month'
      ? 'M'
      : chartData?.time_granularity === 'quarter'
      ? 'Q'
      : 'Y';

  const isDimensionFilter = useMemo(() => {
    const filterData = chartData?.instrument_indicators?.filter((indicator: any) => {
      return (
        indicator?.formatting &&
        Array.isArray(indicator?.formatting?.dimensionItems) &&
        indicator?.formatting?.dimensionItems?.length > 1 &&
        indicator?.dimension_id !== null &&
        indicator?.dimension_id !== ''
      );
    });
    return filterData || false;
  }, [chartData?.instrument_indicators]);

  const handleTimeGranulirity = (value: any) => {
    const editData = JSON.parse(JSON.stringify(chartData));
    const newTimeGranularity =
      value === 'M' || value === 'm'
        ? 'month'
        : value === 'Q' || value === 'q'
        ? 'quarter'
        : 'year';
    if (editData.id) {
      const foundIndex = allInstruments.findIndex(
        (instrument: any) => instrument.id === editData.id,
      );
      if (foundIndex > -1 && !isPreview) {
        const list = JSON.parse(JSON.stringify(allInstruments));
        list[foundIndex].time_granularity = newTimeGranularity;
        dispatch(setAllInstruments([...list]));
        dispatch(
          updateInstrument({
            request_body: { ...list[foundIndex], save: true, data_values: false },
          }),
        );
      }
    }
  };

  const handleApplyFilter = (filterAppliedData: any, filterItem: any) => {
    const widget = JSON.parse(JSON.stringify(chartData));
    console.log({ widget });

    const matchingIndicatorIndex = widget?.instrument_indicators?.findIndex(
      (indicator: any) => indicator?.dimension_id === filterItem[0]?.dimensionId,
    );
    if (matchingIndicatorIndex > -1 && selectedDashboardScenario && filterAppliedData?.length) {
      const matchingIndicator = widget?.instrument_indicators[matchingIndicatorIndex];
      // Create a new object with filtered dimensionItems
      const updatedIndicator = {
        ...matchingIndicator,
        formatting: {
          ...matchingIndicator.formatting,
          dimensionItems: filterAppliedData
            .filter((item: any) => item.status)
            .map((item: any) => {
              return {
                dimension_item_id: item.dimension_item_id,
                dimension_item_name: item.name,
                color: getRandomColor(),
              };
            }),
        },
      };
      if (widget?.id) {
        const foundIndex = allInstruments.findIndex(
          (instrument: any) => instrument.id === widget.id,
        );
        if (foundIndex > -1 && !isPreview) {
          const list = JSON.parse(JSON.stringify(allInstruments));
          list[foundIndex].instrument_indicators[matchingIndicatorIndex] = updatedIndicator;
          dispatch(setAllInstruments([...list]));
          dispatch(
            updateInstrument({
              request_body: { ...list[foundIndex], save: true, data_values: true },
            }),
          ).then((result) => {
            dispatch(
              setAllInstrumentOutputs({
                ...allInstrumentsOutputs,
                [result.payload.id]: { ...result.payload.data },
              }),
            );
          });
        }
      }
    }
  };

  const blockId = useMemo(() => {
    if (chartData?.instrument_indicators) {
      const firstMetric = chartData.instrument_indicators[0];
      return firstMetric ? firstMetric.block_id : null;
    }
    return null;
  }, [chartData?.instrument_indicators]);

  const displayChart = () => {
    switch (chartData?.instrument_type) {
      // case 'pie':
      //   return (
      //     <PieChart chartFormatting={chartData?.formatting} graphData={chartData} />
      //     // <PieChart chartFormatting={chartData?.formatting} />
      //   );
      case 'line':
        return (
          <LineChart
            timeGranularityFilter={timeGranularity}
            chartFormatting={chartData?.formatting}
            graphData={allInstrumentsOutputs?.[chartData?.id]}
            isDashboardLayout={isDashboardLayout}
          />
        );
      case 'bar':
        return (
          <BarChart
            timeGranularityFilter={timeGranularity}
            chartFormatting={chartData?.formatting}
            graphData={allInstrumentsOutputs?.[chartData?.id]}
            isDashboardLayout={isDashboardLayout}
          />
        );
      case 'combi':
        return <ComboChart />;
      default:
        return <PieChart />;
    }
  };

  return (
    <>
      <Flex
        borderRadius='15px'
        flexDirection={'column'}
        border={isDashboardLayout ? '' : '1px solid #787878'}
        boxShadow={isDashboardLayout ? '' : '0px 4px 4px rgba(0, 0, 0, 0.25)'}
        height={'100%'}
      >
        {isLoading && (
          <Flex
            height={'100%'}
            direction='column'
            alignItems='center'
            justifyContent='center'
            justify='center'
            align='center'
            w={'full'}
          >
            <Spinner styleProperties={{ my: '4vh' }} />
          </Flex>
        )}
        {!isLoading && (
          <>
            <Flex
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              m={1}
            >
              <Flex>
                {isDimensionFilter.length > 0 && (
                  <PriviewFilterByComponent
                    widget={chartData}
                    handleApplyFilter={handleApplyFilter}
                  />
                )}
              </Flex>
              <Flex>
                {timeGranularity && (
                  <TabsFilter
                    timeGranularity={timeGranularity}
                    onChangeSummeryMode={handleTimeGranulirity}
                    fontColor={'#787878'}
                    bg={'#F7F7F815'}
                    color='white'
                    selectedYear='all'
                  />
                )}
              </Flex>
            </Flex>
            <Flex justifyContent={'center'} alignItems={'center'} height={'100%'}>
              <Box
                className='dashboard-drag-header'
                cursor='grab'
                width={
                  chartData?.instrument_type === 'bar' || chartData?.instrument_type === 'line'
                    ? '95%'
                    : ''
                }
                height={
                  chartData?.instrument_type === 'bar' || chartData?.instrument_type === 'line'
                    ? '97%'
                    : ''
                }
              >
                {displayChart()}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default ChartCard;

import React, { useEffect, useState } from 'react';
import { Text, Input, Box } from '@chakra-ui/react';

export interface Properties {
  value: string;
  handleChange: (value: string) => void;
  isEditable: boolean;
  width?: string;
  placeholder?: string;
}

const EditableTitle = ({ value, handleChange, isEditable, width, placeholder }: Properties) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(value || '');
  useEffect(() => {
    setText(value);
  }, [value]);
  const handleDoubleClick = () => {
    // eslint-disable-next-line no-constant-condition
    if (true || isEditable) {
      setIsEditing(true);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      handleChange(event.target.value);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    handleChange(text);
  };

  return (
    <Box title={text} p={1}>
      {isEditing ? (
        <Input
          px={0}
          color={'black.900'}
          fontWeight={'bold'}
          fontSize={{ base: 'xl', sm: 'md', md: 'lg', lg: 'xl', xl: '2xl' }}
          fontFamily={'regular_roboto'}
          autoFocus
          border={'2px solid blue'}
          value={text}
          onChange={(event) => setText(event.target.value)}
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
        />
      ) : (
        <Text
          px={0}
          width={width}
          minH={'35px'}
          fontFamily={'regular_roboto'}
          fontSize={{ base: 'xl', sm: 'md', md: 'lg', lg: 'xl', xl: '2xl' }}
          fontWeight={'extrabold'}
          color={'blackAlpha.900'}
          textAlign={'left'}
          textOverflow='ellipsis'
          whiteSpace={'nowrap'}
          overflow='hidden'
          onDoubleClick={handleDoubleClick}
        >
          {text || placeholder}
        </Text>
      )}
    </Box>
  );
};

export default EditableTitle;

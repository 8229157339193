import { Menu, MenuButton, MenuList, Button, Flex } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import AddIconComponent from 'components/Icons/AddIcon';
import ArrowDropdownIconComponent from 'components/Icons/ArrowDropdownIconComponent';
// import TextBoxIconComponent from 'components/Icons/TextBoxIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';
import Spinner from 'components/Spinner';
import { AppDispatch } from 'utils/GlobalHelpers';
import {
  prepareCreatePayload,
  getDashboard,
  setDashboardTimeFilter,
  setTimeFilter,
} from 'redux/DashboardSliceV2';

import TimeRangeFilter from './TimeRangeFilter';
import SelectScenario from './SelectScenario';
import InstrumentMenuList from './InstrumentMenuList';

export interface Properties {
  onExport: () => void;
  pdfExport: boolean;
  refetchAllInstruments: () => void;
}
const Header = ({ onExport, refetchAllInstruments, pdfExport }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const dashboard = useSelector(getDashboard);
  const onSelectInstrument = (widgetType: string) => dispatch(prepareCreatePayload(widgetType));
  const rangeSelected = (start: string, end: string) => {
    if (dashboard?.id) {
      dispatch(setTimeFilter({ start, end }));
      dispatch(
        setDashboardTimeFilter({
          request_body: { value: start, second_value: end, id: dashboard.id },
        }),
      ).then(() => {
        refetchAllInstruments();
      });
    }
  };

  return (
    <Flex justifyContent={'space-between'}>
      <SelectScenario />
      <Flex gap={4}>
        {dashboard && <TimeRangeFilter rangeSelected={rangeSelected} />}

        <Button
          backgroundColor={'#5A58AB'}
          padding={'.5rem 1rem'}
          as={Button}
          border={'none'}
          disabled={pdfExport}
          leftIcon={pdfExport ? <Spinner size={'md'} /> : <DownloadIcon />}
          fontSize={'md'}
          fontWeight={'400'}
          width={'fit-content'}
          height={'45px'}
          onClick={onExport}
        >
          Export
        </Button>
        <Menu variant={'dashboarding'}>
          {({ isOpen }) => {
            return (
              <>
                <MenuButton
                  backgroundColor={'#5A58AB'}
                  padding={'.5rem 1rem'}
                  as={Button}
                  border={'none'}
                  rightIcon={
                    <ArrowDropdownIconComponent
                      style={{ margin: '0 .5rem' }}
                      height={12}
                      width={12}
                      color='white'
                    />
                  }
                  leftIcon={
                    <AddIconComponent
                      style={{ margin: '0 .5rem' }}
                      color='#62B5BA'
                      height={18}
                      width={18}
                    />
                  }
                  fontSize={'md'}
                  fontWeight={'400'}
                  width={'fit-content'}
                  height={'45px'}
                >
                  Create
                </MenuButton>
                {isOpen && (
                  <MenuList zIndex={18}>
                    <InstrumentMenuList onSelectInstrument={onSelectInstrument} />
                  </MenuList>
                )}
              </>
            );
          }}
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Header;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-lonely-if */
import '@silevis/reactgrid/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Box, useToast } from '@chakra-ui/react';
import { Cell, ReactGrid } from '@silevis/reactgrid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomToast from 'components/CustomToast';
import DeleteGroupModal from 'components/DeleteGroupModal';
import FullScreenLoader from 'components/FullScreenLoader';
import {
  AddItemProperty,
  DeleteProperty,
  GetDimensionGroup,
  GetTableItemsProperties,
  GetTableProperties,
  UpdateProperty,
  UpdatePropertyBulk,
  UpdatePropertyName,
  resetTableOrgChart,
  getDimensionProperties,
  getTableViewStatus,
  getOrgTableGroup,
  getFilteredProperties,
  handleDimItemsChange,
  getUpdatedDimensionProperties,
} from 'redux/ItemPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import PlanPermission from 'container/PlanPermission';
import { DateCellTemplate } from 'components/DateCell';
import { AddRowCellTemplate } from 'components/AddRowCell';
import { DeleteRowCellTemplate } from 'components/DeleteRowCell';
import { HeaderRowTemplate } from 'components/HeaderRow';
import { TextCellTemplate } from 'components/TextCell';
import { DropdownCellTemplate } from 'components/DropdownCell';
import { FiltersCellTemplate } from 'components/FiltersCell';
import { AppDispatch } from 'utils/GlobalHelpers';
import {
  FetchBlockItemDimensions,
  getSelectedDriver,
  handleFilters,
  resetFilters,
} from 'redux/PlanPageSlice';

import { getRows, getColumns } from './TableHelpers';

export interface AddCell extends Cell {
  type: 'add';
  text: string;
  onClick: () => void;
  refetchRequest?: any;
}

const TableViewComponent = ({ planPermission, refetchRequest }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const { blockId } = useParams();
  const [tableData, setTableData]: any = React.useState();
  const [gridProperties, setGridProperties] = useState({});

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState<any>({});
  const toastMessage = useToast();
  const baseScenario = useSelector(getBaseScenario);
  const tableReference: any = useRef(null);

  const currentDriver = useSelector(getSelectedDriver);
  const orgTableViewStatus = useSelector(getTableViewStatus);
  const orgTableGroup = useSelector(getOrgTableGroup);
  const tableProperty = useSelector(getDimensionProperties);
  const updatedTableProperty = useSelector(getUpdatedDimensionProperties);
  const tableItemsProperty = useSelector(getFilteredProperties);

  const rows: any = useMemo(() => {
    return (
      updatedTableProperty?.length >= 0 &&
      tableData?.length >= 0 &&
      tableReference?.current?.offsetWidth &&
      getRows(tableData, updatedTableProperty)
    );
  }, [tableData, updatedTableProperty]);

  const memorizedColumns: any = useMemo(() => {
    return (
      updatedTableProperty?.length >= 0 &&
      tableReference?.current?.offsetWidth &&
      getColumns(updatedTableProperty, tableReference?.current?.offsetWidth)
    );
  }, [updatedTableProperty, tableReference?.current?.offsetWidth]);

  const [columns, setColumns]: any = useState(memorizedColumns);

  useEffect(() => {
    const getData = async () => {
      if (currentDriver && currentDriver.type === 'dimension') {
        await dispatch(
          GetTableProperties({
            selectedId: currentDriver?.dim_id,
            parameters: { scenario_id: baseScenario?.id },
          }),
        );
        await dispatch(
          GetTableItemsProperties({
            selectedId: currentDriver?.dim_id,
            parameters: { scenario_id: baseScenario?.id },
          }),
        );
      }
    };
    getData();
  }, [currentDriver, baseScenario?.id]);

  useEffect(() => {
    setTableData(tableItemsProperty);
  }, [tableItemsProperty]);

  useEffect(() => {
    setColumns(memorizedColumns);
    dispatch(handleDimItemsChange());
  }, [memorizedColumns]);

  useEffect(() => {
    if ((rows?.length && columns?.length && !orgTableGroup) || baseScenario?.id) {
      tableProperty?.map((data: any) => {
        if (data?.data_format) {
          dispatch(GetDimensionGroup({ data, parameters: { scenario_id: baseScenario?.id } }));
        }
        return null;
      });
    }
  }, [tableProperty, baseScenario?.id]);

  useEffect(() => {
    return () => {
      dispatch(resetTableOrgChart());
    };
  }, []);

  const handleAddRow = () => {
    let object: any = {};
    for (const number of tableProperty) {
      object[number?.id] = '';
    }
    object = { ...object, name: '', id: '', position: '' };
    setTableData([...tableData, object]);
    setGridProperties({ focusLocation: { rowId: rows?.length - 2, columnId: 'name' } });
  };

  const updateCell = async (changes: any) => {
    const change = changes[0];
    const existingRow = rows?.filter((data: any) => {
      return change?.rowId === data?.rowId;
    });

    if (change?.newCell?.property && !change?.previousCell?.text && !existingRow[0]?.itemId) {
      await dispatch(
        AddItemProperty({
          dimension_id: Number(currentDriver?.dim_id),
          request_body: {
            name: change?.newCell?.text,
            scenario_id: baseScenario?.id,
          },
          toaster: true,
        }),
      );
      await dispatch(resetFilters());
      await refetchRequest();
      await dispatch(
        GetTableItemsProperties({
          selectedId: currentDriver?.dim_id,
          parameters: { scenario_id: baseScenario?.id },
        }),
      );
    } else {
      if (change?.newCell?.property) {
        await dispatch(
          UpdatePropertyName({
            item_id: tableItemsProperty[change?.rowId]?.id,
            name: change?.newCell?.text,
            toaster: true,
          }),
        );
        await dispatch(
          GetTableItemsProperties({
            selectedId: currentDriver?.dim_id,
            parameters: { scenario_id: baseScenario?.id },
          }),
        );
      } else {
        await dispatch(
          UpdateProperty({
            item_id: tableItemsProperty[change?.rowId]?.id,
            request_body: {
              property_id: change?.columnId,
              value: change?.newCell?.text,
              scenario_id: baseScenario?.id,
            },
            toaster: true,
          }),
        );
      }
    }
  };

  const updateCellBulk = async (changes: any) => {
    const newChanges: any[] = [];
    for (const change of changes) {
      if (change?.newCell?.property) {
        toastMessage({
          duration: 2500,
          render: () => <CustomToast title={`Can't duplicate item name`} status='error' />,
        });
        break;
      }
      const object = {
        item_id: `${tableItemsProperty[change?.rowId]?.id}`,
        property_id: `${change?.columnId}`,
        value: change?.newCell?.text,
      };
      newChanges.push(object);
    }
    if (newChanges?.length > 0)
      await dispatch(
        UpdatePropertyBulk({
          request_body: {
            items: newChanges,
            dimension_id: currentDriver?.dim_id,
            scenario_id: baseScenario?.id,
          },
          toaster: true,
        }),
      );
  };

  const updateCells = async (changes: any) => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (changes?.length > 1) {
      await updateCellBulk(changes);
    } else {
      await updateCell(changes);
    }
    await dispatch(
      FetchBlockItemDimensions({
        blockId,
      }),
    );
    // await dispatch(handleFilters());
  };

  const handleDeleteRow = async (data: any) => {
    if (data?.id === '') {
      await dispatch(
        GetTableItemsProperties({
          selectedId: currentDriver?.dim_id,
          parameters: { scenario_id: baseScenario?.id },
        }),
      );
    } else {
      setDeleteModal(data);
      setIsDeleteModal(true);
    }
  };

  const onDeleteModel = async () => {
    if (deleteModal?.id) {
      await dispatch(
        DeleteProperty({
          item_id: deleteModal?.id,
          parameters: { scenario_id: baseScenario?.id },
        }),
      );
    }
    await dispatch(
      GetTableItemsProperties({
        selectedId: currentDriver?.dim_id,
        parameters: { scenario_id: baseScenario?.id },
      }),
    );
    await refetchRequest();
    setIsDeleteModal(false);
  };

  const handleContextMenu = (
    selectedRowIds: any,
    selectedColIds: any,
    selectionMode: any,
    menuOptions: any,
    selectedRanges: any,
  ) => {
    if (
      selectedRanges[0] &&
      selectedRanges[0][0]?.rowId !== 'header' &&
      selectedRanges[0][0]?.rowId !== 'footer'
    ) {
      const deleteRow = {
        id: 'deleteRow',
        label: 'Delete row',
        handler: () => {
          const myRow = rows?.filter((data2: any) => {
            return data2?.rowId === selectedRanges[0][0]?.rowId && data2;
          });
          handleDeleteRow({ id: myRow[0]?.itemId, itemName: myRow[0]?.itemName });
        },
      };
      let selectedRows = false;
      for (let index = 0; index < selectedRanges[0]?.length; index += 1) {
        selectedRows = selectedRanges[0][0]?.rowId === selectedRanges[0][index]?.rowId;
      }
      if (selectedRanges[0][0]?.columnId !== 'delete' && selectedRows) {
        menuOptions = menuOptions.filter((object: any) => object.id !== 'cut');
        return [...menuOptions, deleteRow];
      }
      return selectedRanges[0][0]?.columnId === 'delete' && selectedRanges[0]?.length === 1
        ? [deleteRow]
        : menuOptions.filter((object: any) => object.id !== 'cut');
    }
    return [];
  };

  const handleColumnResize = (ci: any, width: number) => {
    setColumns((previousColumns: any) => {
      const columnIndex = previousColumns.findIndex((element: any) => element.columnId === ci);
      const resizedColumn = previousColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      previousColumns[columnIndex] = updatedColumn;
      return [...previousColumns];
    });
  };

  const handleLocationChanged = () => {
    if (Object.keys(gridProperties).length > 0) {
      setGridProperties({});
    }
    return true;
  };

  const handleFilterChange = () => dispatch(handleDimItemsChange());

  if (orgTableViewStatus === 'IDLE') {
    return <FullScreenLoader bgColor='#796CD6' height={`calc(100vh - 64px - 98px) !important`} />;
  }

  return (
    <Box height={`calc(100vh - 64px - 170px)`} ref={tableReference} margin={'auto 2rem'}>
      {columns?.length && rows?.length ? (
        <Box
          className='md-tableview-container sm-scroll'
          cursor={planPermission ? 'not-allowed' : 'pointer'}
          width={'100%'}
          margin={'initial'}
          maxH={'100%'}
          overflow={rows.length > 2 ? 'auto' : 'initial'}
        >
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            verticalStickyBreakpoint={100}
            horizontalStickyBreakpoint={100}
            stickyRightColumns={columns?.length <= 2 ? 0 : 1}
            stickyLeftColumns={columns?.length <= 2 ? 0 : 1}
            stickyBottomRows={rows?.length <= 3 ? 0 : 1}
            stickyTopRows={rows?.length <= 3 ? 0 : 1}
            onContextMenu={planPermission ? undefined : handleContextMenu}
            onCellsChanged={planPermission ? undefined : updateCells}
            onColumnResized={planPermission ? undefined : handleColumnResize}
            {...gridProperties}
            onFocusLocationChanging={planPermission ? undefined : handleLocationChanged}
            customCellTemplates={{
              header: new HeaderRowTemplate(),
              delete: new DeleteRowCellTemplate(),
              addRow: new AddRowCellTemplate(handleAddRow, planPermission),
              text: new TextCellTemplate(),
              dropdown: new DropdownCellTemplate(handleDeleteRow, planPermission),
              date: new DateCellTemplate(),
              filter: new FiltersCellTemplate(handleFilterChange),
            }}
          />
        </Box>
      ) : (
        <Box
          height={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          transform={`translate(0, -34px)`}
        >
          No Data found
        </Box>
      )}
      {isDeleteModal && (
        <DeleteGroupModal
          isOpen={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          itemName={deleteModal?.itemName || 'some name'}
          onDelete={onDeleteModel}
          orgChart={true}
        />
      )}
    </Box>
  );
};

export default PlanPermission(TableViewComponent);

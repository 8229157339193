import { Flex, Text, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import CloseIconComponent from 'components/Icons/CloseIcon';
import ChartIconComponent from 'components/Icons/ChartIcon';
import KPIIconComponent from 'components/Icons/KpiIcon';
import TableIcomComponent from 'components/Icons/TableIcon';
import TextBoxIconComponent from 'components/Icons/TextBoxIcon';
import { getEditInstrumentData } from 'redux/DashboardSliceV2';

export interface Properties {
  handleCancel: () => void;
}

const DashboardModalHeading = ({ handleCancel }: Properties) => {
  const widget = useSelector(getEditInstrumentData);

  return (
    <Flex alignItems={'center'} height={'fit-content'} justifyContent='space-between' p={'0'}>
      <Box>
        {widget?.instrument_type === 'kpi' ? (
          <>
            <Flex alignItems={'center'}>
              <KPIIconComponent
                width={'36px'}
                height={'36px'}
                color={'#787878'}
                marginRight='.5rem'
              />
              <Text mt={'2px'} size={'Comfortaa-bold-20'}>
                KPI
              </Text>
            </Flex>
          </>
        ) : widget?.instrument_type === 'line' || widget?.instrument_type === 'bar' ? (
          <Flex alignItems={'center'}>
            <ChartIconComponent
              width={'36px'}
              height={'36px'}
              color={'#787878'}
              marginRight='.5rem'
            />
            <Text size={'Comfortaa-bold-20'}>Chart</Text>
          </Flex>
        ) : widget?.instrument_type === 'table' ? (
          <>
            <Flex alignItems={'center'}>
              <TableIcomComponent
                width={'36px'}
                height={'36px'}
                color={'#787878'}
                marginRight='.5rem'
              />
              <Text size={'Comfortaa-bold-20'}>Table</Text>
            </Flex>
          </>
        ) : widget?.instrument_type === 'variance' ? (
          <>
            {' '}
            <Flex alignItems={'center'}>
              <TableIcomComponent
                width={'36px'}
                height={'36px'}
                color={'#787878'}
                marginRight='.5rem'
              />
              <Text size={'Comfortaa-bold-20'}>Variance</Text>
            </Flex>
          </>
        ) : (
          <Flex alignItems={'center'}>
            <TextBoxIconComponent
              width={'36px'}
              height={'36px'}
              color={'#787878'}
              marginRight='.5rem'
            />
            <TableIcomComponent
              width={'36px'}
              height={'36px'}
              color={'#787878'}
              marginRight='.5rem'
            />
            <Text size={'Comfortaa-bold-20'}>Variance</Text>
          </Flex>
        )}
      </Box>
      <CloseIconComponent height={'15px'} width={'15px'} color={'black'} onClick={handleCancel} />
    </Flex>
  );
};

export default DashboardModalHeading;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { STATUS } from 'projectConstants';
import apiService from 'services';
import bloxBiService from 'services/bloxBiService';

interface DashboardProperties {
  dashboard: any;
  fetchDashboardStatus: keyof typeof STATUS;
  dashboardBlocks: Array<any>;
  dashboardBlockStatus: keyof typeof STATUS;
  selectedDashboardScenario: any;
  dashboardInstruments: Array<any>;
  dashboardInstrumentData: any;
  dashboardInstrumentStatus: keyof typeof STATUS;
  editInstrumentStatus: keyof typeof STATUS;
  editInstrumentData: any;
  editInstrumentOutput: any;
  allInstruments: Array<any>;
  timeFilter: { start: string; end: string } | null;
  allInstrumentOutputs: any;
  instrumentExpandKey: any;
  allInstrumentOutputsStatus: keyof typeof STATUS;
  calculationLoadingStatus: keyof typeof STATUS;
  selectedRow: any;
}

const initialState: DashboardProperties = {
  dashboard: null,
  fetchDashboardStatus: STATUS.IDLE,
  dashboardBlocks: [],
  dashboardBlockStatus: STATUS.IDLE,
  selectedDashboardScenario: null,
  dashboardInstruments: [],
  dashboardInstrumentData: {},
  dashboardInstrumentStatus: STATUS.IDLE,
  editInstrumentData: {},
  editInstrumentOutput: {},
  editInstrumentStatus: STATUS.IDLE,
  allInstruments: [], // with edit data
  timeFilter: null,
  allInstrumentOutputs: [], // key(insturmentID): value(insturment Data) output data
  instrumentExpandKey: {},
  allInstrumentOutputsStatus: STATUS.IDLE,
  calculationLoadingStatus: STATUS.IDLE,
  selectedRow: null,
};

const updateDashboardblockData = (blocksData: Array<any>, dimItemMap: any) => {
  const returnData = blocksData.map((block: any) => {
    const dims = block.dimensions.map((dimension: any) => {
      const items = dimItemMap[dimension.id] || [];
      return {
        dimension_id: dimension.id,
        dimension_name: dimension.name,
        dimension_items: items,
      };
    });
    return {
      block_id: block.id,
      block_name: block.name,
      indicators: block.indicators.map((indicator: any) => {
        const indDims = (indicator?.indicator_dimension || []).map((dimObject: any) => {
          const newDimension = dims.find((dimId: any) => {
            return dimObject.indicator_dimension_id === dimId.dimension_id;
          });
          return {
            indicator_dimension_id: dimObject.indicator_dimension_id,
            indicator_dimension_name: dimObject.indicator_dimension_name,
            indicator_dimension_items: newDimension?.dimension_items
              ? newDimension?.dimension_items.map((item: any) => {
                  return {
                    indicator_dimension_item_id: item.dimension_item_id,
                    indicator_dimension_item_name: item.dimension_item_name,
                  };
                })
              : [],
          };
        });

        return {
          indicator_id: indicator.id,
          id: indicator.id,
          indicator_name: indicator.name,
          data_type: indicator.data_type,
          data_format: indicator.data_format,
          indicator_dimensions: indDims,
          indicator_dimension_items: [],
          data_format_properties: indicator.data_format_properties,
        };
      }),
      dimensions: dims,
    };
  });

  return returnData;
};
export const FetchModelBlocks = createAsyncThunk(
  'dashboardModal/get',
  async ({ modelId }: any, thunkAPI) => {
    try {
      console.time('TaskExecution');
      const blocksData = await apiService.getDashboardBlock(modelId);
      const modelDimensions = await apiService.getBuilderDimension(modelId);
      const dimensions = modelDimensions?.data?.dimensions;
      const dimensionItemsPromises =
        dimensions?.map((dimension: any) => apiService.get_dimension_items(dimension.id)) || [];

      const resolvedDimensionItems = await Promise.all(dimensionItemsPromises);

      const dimItemMap: any = {};
      dimensions.map((dim: any, index: number) => {
        const itemsData = resolvedDimensionItems[index]?.data?.items;
        dimItemMap[dim.id] = itemsData.map((item: any) => {
          return {
            dimension_item_id: item.id,
            dimension_item_name: item.name,
          };
        });
        return dimItemMap;
      });
      const returnData = updateDashboardblockData(blocksData.data.blocks, dimItemMap);
      console.timeEnd('TaskExecution');
      return returnData;
    } catch (error: any) {
      console.log('Error:', error);
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const fetchDashboardID = createAsyncThunk(
  'fetchDashboardID/create',
  async ({ model_id }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.fetch_dashboardid(model_id);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const createDashboardID = createAsyncThunk(
  'createDashboardID/create',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.create_dashboard({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const setDashboardTimeFilter = createAsyncThunk(
  'dashboatdTimeFilter/create',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.dashboard_time_filter({ request_body });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchDashboardMetadata = createAsyncThunk(
  'dashboardMetadata/get',
  async ({ dashboardId }: any, thunkAPI) => {
    try {
      const metadata = await bloxBiService.dashboard_metadata(dashboardId);
      return metadata.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const createInstrument = createAsyncThunk(
  'createInstrument/post',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.createDashboardChart({ request_body });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateInstrument = createAsyncThunk(
  'updateInstrument/put',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.updateDashboardChart({ request_body });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const deleteInstrument = createAsyncThunk(
  'delete/deleteInstrument',
  async ({ chartId }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.deleteChart(chartId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updatelayout = createAsyncThunk(
  'updateLayout/put',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.updateChartLayout({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchDashboardGraph = createAsyncThunk(
  'fetchChartInstrument/get',
  async ({ chartId, scenarioId, requestBody }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.getDashboardGraph(chartId, scenarioId, requestBody);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchAllDataValues = createAsyncThunk(
  'fetchChartInstrument/get',
  async ({ chartId, scenarioId }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.dashboard_dataValues(chartId, scenarioId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const dashboardSliceV2 = createSlice({
  initialState,
  name: 'dashboardSliceV2',
  reducers: {
    resetDashboardSliceV2: (state) => {
      state.dashboard = null;
      state.fetchDashboardStatus = STATUS.IDLE;
      state.selectedDashboardScenario = null;
      state.dashboardBlocks = [];
      state.dashboardBlockStatus = STATUS.IDLE;
      state.dashboardInstruments = [];
      state.editInstrumentStatus = STATUS.IDLE;
      state.editInstrumentData = {};
      state.editInstrumentOutput = {};
      state.allInstruments = [];
      state.timeFilter = null;
      state.allInstrumentOutputs = [];
      state.instrumentExpandKey = {};
      state.allInstrumentOutputsStatus = STATUS.IDLE;
      state.calculationLoadingStatus = STATUS.IDLE;
      state.selectedRow = null;
    },
    setDashboard: (state, action) => {
      state.dashboard = action.payload;
    },
    setEditInstrumentOutput: (state, action) => {
      state.editInstrumentOutput = action.payload;
    },
    setAllInstrumentOutputs: (state, action) => {
      state.allInstrumentOutputs = action.payload;
    },
    setTimeFilter: (state, action) => {
      const object: any = { start: action.payload.start, end: action.payload.end };
      state.timeFilter = object;
    },
    selectDashboardScenario: (state, action) => {
      state.selectedDashboardScenario = action.payload;
    },
    setDashboardInstrumentStatus: (state, action) => {
      state.dashboardInstrumentStatus = action.payload;
    },
    setEditInstrumentStatus: (state, action) => {
      state.editInstrumentStatus = action.payload;
    },
    setEditInstrumentData: (state, action) => {
      state.editInstrumentData = action.payload;
    },
    setAllInstruments: (state, action) => {
      state.allInstruments = action.payload;
    },
    setInstrumentExpandKey: (state, action) => {
      state.instrumentExpandKey = action.payload;
    },
    setCalculationLocadingStatus: (state, action) => {
      state.calculationLoadingStatus = action.payload;
    },
    prepareCreatePayload: (state, action) => {
      state.editInstrumentData = {
        instrument_type: action.payload,
        instrument_indicators: [],
        instrument_filters: [
          {
            instrument_id: null,
            name: 'Time',
            operator: 'between',
            value: state.timeFilter?.start || 'Jan-24',
            second_value: state.timeFilter?.end || 'Dec-26',
            filter_type: 'primary',
          },
          // TODO:  Global Time  Filter
        ],
        time_granularity: 'month', // "Input should be 'all', 'month', 'quarter' or 'year'"
        id: null,
        scenario_id: state.selectedDashboardScenario.id.toString(),
        name:
          action.payload === 'line' || action.payload === 'bar'
            ? 'Chart'
            : `${action.payload}`.charAt(0).toUpperCase() + `${action.payload}`.slice(1),
        dashboard_id: state.dashboard?.id,

        // TODO: unused should be removed
        formatting: {
          units: 'auto',
          sparkline: false,
          decimalPlaces: 0,
          negativeDisplay: 'Minus Sign',
        },
        instrument_sorts: [],
        instrument_dimensions: [],
        conditional_formatting: [],
        position: 0,
        width: 4,
        height: 2,
      };
    },
    handleSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    handleEditCancel: (state) => {
      state.editInstrumentData = {};
      state.editInstrumentOutput = {};
      state.selectedRow = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchModelBlocks.pending, (state) => {
        state.dashboardBlockStatus = STATUS.LOADING;
      })
      .addCase(FetchModelBlocks.fulfilled, (state, action) => {
        state.dashboardBlocks = action.payload;
        state.dashboardBlockStatus = STATUS.SUCCESS;
      })
      .addCase(fetchDashboardID.fulfilled, (state, action) => {
        state.dashboard = action.payload;
        state.fetchDashboardStatus = STATUS.SUCCESS;
      })
      .addCase(createDashboardID.fulfilled, (state, action) => {
        state.dashboard = action.payload;
        state.fetchDashboardStatus = STATUS.SUCCESS;
      })
      .addCase(fetchDashboardMetadata.fulfilled, (state, action) => {
        state.dashboard = action.payload;
        state.allInstruments = action.payload?.instruments;
      })
      .addCase(fetchAllDataValues.pending, (state) => {
        state.allInstrumentOutputsStatus = STATUS.LOADING;
      })
      .addCase(fetchAllDataValues.rejected, (state) => {
        state.allInstrumentOutputsStatus = STATUS.FAILED;
      })
      .addCase(fetchAllDataValues.fulfilled, (state, action) => {
        state.allInstrumentOutputs = action.payload;
        state.allInstrumentOutputsStatus = STATUS.SUCCESS;
      })
      .addCase(createInstrument.pending, (state) => {
        state.calculationLoadingStatus = STATUS.LOADING;
      })
      .addCase(createInstrument.fulfilled, (state) => {
        state.calculationLoadingStatus = STATUS.SUCCESS;
      })
      .addCase(createInstrument.rejected, (state) => {
        state.calculationLoadingStatus = STATUS.FAILED;
      })
      .addCase(updateInstrument.pending, (state) => {
        state.calculationLoadingStatus = STATUS.LOADING;
      })
      .addCase(updateInstrument.fulfilled, (state) => {
        state.calculationLoadingStatus = STATUS.SUCCESS;
      })
      .addCase(updateInstrument.rejected, (state) => {
        state.calculationLoadingStatus = STATUS.FAILED;
      });
  },
});
export const {
  setTimeFilter,
  resetDashboardSliceV2,
  selectDashboardScenario,
  setDashboardInstrumentStatus,
  setEditInstrumentStatus,
  setEditInstrumentData,
  setAllInstruments,
  prepareCreatePayload,
  setEditInstrumentOutput,
  setAllInstrumentOutputs,
  setInstrumentExpandKey,
  setDashboard,
  handleSelectedRow,
  handleEditCancel,
  setCalculationLocadingStatus,
} = dashboardSliceV2.actions;
export const getTimeFilter = (state: any) => state.dashboardSliceV2.timeFilter;
export const getDashboard = (state: any) => state.dashboardSliceV2.dashboard;
export const getDashboardBlocks = (state: any) => state.dashboardSliceV2.dashboardBlocks;
export const getDashboardBlockStatus = (state: any) => state.dashboardSliceV2.dashboardBlockStatus;
export const getAllInstruments = (state: any) => state.dashboardSliceV2.allInstruments;
export const getSelectedDashboardScenario = (state: any) =>
  state.dashboardSliceV2.selectedDashboardScenario;
export const getFetchDashboardStatus = (state: any) => state.dashboardSliceV2.fetchDashboardStatus;
export const getDashboardInstruments = (state: any) => state.dashboardSliceV2.dashboardInstruments;
export const getDashboardInstrumentStatus = (state: any) =>
  state.dashboardSliceV2.dashboardInstrumentStatus;
export const getEditInstrumentStatus = (state: any) => state.dashboardSliceV2.editInstrumentStatus;
export const getEditInstrumentData = (state: any) => state.dashboardSliceV2.editInstrumentData;
export const getEditInstrumentOutput = (state: any) => state.dashboardSliceV2.editInstrumentOutput;
export const getAllInstrumentOutputs = (state: any) => state.dashboardSliceV2.allInstrumentOutputs;
export const getInstrumentExpandKey = (state: any) => state.dashboardSliceV2.instrumentExpandKey;
export const getCalculationLoadingStatus = (state: any) =>
  state.dashboardSliceV2.calculationLoadingStatus;
export const getAllInstrumentOutputsStatus = (state: any) =>
  state.dashboardSliceV2.allInstrumentOutputsStatus;
export const getSelectedRow = (state: any) => state.dashboardSliceV2.selectedRow;

export default dashboardSliceV2.reducer;

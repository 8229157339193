import * as React from 'react';
import { Box, Flex, Image, Stack, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import visitLibrary from 'assets/model_not_found_icon.svg';
import Container from 'components/Container';
import { Model } from 'models/response/model.interface';
import { getDuplicateModels, getModels, GetUserModels } from 'redux/ModelsSlice';
import FullScreenLoader from 'components/FullScreenLoader';
import useDocumentTitle from 'hooks/useDocumentTitle';

import PageTitles from './PageTitle';
import SearchInModels from './ModelsSearch';
import ModelsViewMode from './ModelsViewMode';
import DesktopModel from './DesktopModel';
import CreateNewModel from './CreateNewModel';
import BlockNotFound from './BlockNotFound';

const DesktopModelsLayout = () => {
  useDocumentTitle('');

  const dispatch: any = useDispatch();
  const carouselReference = useRef<HTMLDivElement>(null);
  const [isMobile] = useMediaQuery('(max-width: 850px)');
  const [loader, setLoader] = useState(true);
  const [viewMode, setViewMode] = useState('grid');
  const [searchValue, setSearchValue] = useState('');

  const models = useSelector(getModels);
  const duplicateModels = useSelector(getDuplicateModels);

  useEffect(() => {
    setLoader(true);
    const getUserModel = async () => {
      await dispatch(GetUserModels({ loader: true }));
    };
    getUserModel();
  }, []);

  const duplicateModel = useMemo(() => {
    const filteredModels = models?.filter((model: any) =>
      model?.name?.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setLoader(false);
    return duplicateModels.concat(filteredModels);
  }, [models, searchValue, duplicateModels]);

  const onSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onToggleViewMode = (newMode: string) => setViewMode(newMode);

  if (loader) return <FullScreenLoader />;

  return (
    <Container
      wrapperProperties={{
        px: '2.5vw',
        borderTopWidth: '1',
        borderColor: 'grey.900',
        bg: 'white.900',
        overflowX: 'hidden',
      }}
    >
      <Flex direction='column' py='7.5' h='100%' overflow={'hidden'}>
        <PageTitles />
        <Flex position='relative' mb='auto' w='100%' mt='10'>
          <VStack w={'100%'} spacing='4' align='flex-start'>
            <Flex
              width={'100%'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Flex w='inherit' whiteSpace={isMobile ? 'nowrap' : 'initial'}>
                <Text
                  size='home_section_title'
                  variant='secondary'
                  alignSelf={'center'}
                  whiteSpace={'nowrap'}
                >
                  Your saved Models :
                </Text>
                <SearchInModels searchValue={searchValue} onChange={onSearchValue} />
              </Flex>
              <Flex>
                {duplicateModel?.length > 0 && (
                  <ModelsViewMode viewMode={viewMode} onChange={onToggleViewMode} />
                )}
                <CreateNewModel />
              </Flex>
            </Flex>
            <Box
              className='scroll'
              height={'calc(100vh - 250px)'}
              overflow={'auto'}
              ref={carouselReference}
              w={'100%'}
            >
              {duplicateModel?.length > 0 ? (
                <Stack
                  flexDirection={'row'}
                  gap={'4'}
                  flexWrap={'wrap'}
                  alignItems={'flex-start'}
                  position='relative'
                >
                  {duplicateModel?.map((model: Model, index: number) => (
                    <React.Fragment key={model.id}>
                      <DesktopModel key={index} model={model} isListMode={viewMode === 'list'} />
                    </React.Fragment>
                  ))}
                </Stack>
              ) : models?.length === 0 ? (
                <Flex justifyContent={'end'}>
                  <Image height={'5rem'} mt={8} mr={'8rem'} src={visitLibrary} />
                </Flex>
              ) : (
                <BlockNotFound />
              )}
            </Box>
          </VStack>
        </Flex>
      </Flex>
    </Container>
  );
};

export default DesktopModelsLayout;

import {
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Box,
  Image,
  Button,
  Progress,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import warningIcon from 'assets/warning_icon.png';
import VisibleIconComponent from 'components/Icons/VisibleIcon';
import {
  createInstrument,
  getCalculationLoadingStatus,
  getEditInstrumentData,
  getEditInstrumentOutput,
  getSelectedDashboardScenario,
  setEditInstrumentData,
  setEditInstrumentOutput,
  updateInstrument,
} from 'redux/DashboardSliceV2';
import { AppDispatch, getRandomColor } from 'utils/GlobalHelpers';
import { STATUS } from 'projectConstants';
import TabsFilter from 'pages/PlanPageOutputOptimization/Plan/Filters/TabsFilter';

import KpiCard from '../kpi/KpiCard';
import DashboardTableWrapper from '../table/DashboardTableWrapper';
import BarChart from '../chart/BarChart';
import LineChart from '../chart/LineChart';
import PriviewFilterByComponent from '../PreviewFilterBy';

import DashboardModalHeading from './ModalHeading';
import Setup from './Setup';
import DashboardModalButtons from './ModalButtons';

export interface Properties {
  handleCancel: () => void;
  handleCreate: () => void;
}

const DashboardModal = ({ handleCancel, handleCreate }: Properties) => {
  const calculationLoadingStatus = useSelector(getCalculationLoadingStatus);
  const instrumentOutput = useSelector(getEditInstrumentOutput);
  const editData = useSelector(getEditInstrumentData);
  const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);
  const dispatch: AppDispatch = useDispatch();
  const output = {
    ...instrumentOutput,
    ...editData,
    timeGranularity: instrumentOutput?.time_granularity,
  };

  const timeGranularity =
    editData?.time_granularity === 'month'
      ? 'M'
      : editData?.time_granularity === 'quarter'
      ? 'Q'
      : 'Y';

  const refresh = () => {
    dispatch(
      updateInstrument({
        request_body: {
          ...editData,
          save: false,
          data_values: true,
        },
      }),
    ).then((result) => {
      dispatch(setEditInstrumentOutput(result?.payload?.data));
    });
  };
  const isDimensionFilter = useMemo(() => {
    const filterData = editData?.instrument_indicators?.filter((indicator: any) => {
      return (
        indicator?.formatting &&
        Array.isArray(indicator?.formatting?.dimensionItems) &&
        indicator?.formatting?.dimensionItems?.length > 1 &&
        indicator?.dimension_id !== null &&
        indicator?.dimension_id !== ''
      );
    });
    return filterData || false;
  }, [editData?.instrument_indicators]);

  const handleApplyFilter = (filterAppliedData: any, filterItem: any) => {
    const widget = JSON.parse(JSON.stringify(editData));

    const matchingIndicatorIndex = widget?.instrument_indicators?.findIndex(
      (indicator: any) => indicator?.dimension_id === filterItem[0]?.dimensionId,
    );
    if (matchingIndicatorIndex > -1 && selectedDashboardScenario && filterAppliedData?.length) {
      const matchingIndicator = widget?.instrument_indicators[matchingIndicatorIndex];
      // Create a new object with filtered dimensionItems
      const updatedIndicator = {
        ...matchingIndicator,
        formatting: {
          ...matchingIndicator.formatting,
          dimensionItems: filterAppliedData
            .filter((item: any) => item.status)
            .map((item: any) => {
              return {
                dimension_item_id: item.dimension_item_id,
                dimension_item_name: item.name,
                color: getRandomColor(),
              };
            }),
        },
      };
      if (!editData?.id) {
        const newData = JSON.parse(JSON.stringify(editData));
        newData.instrument_indicators[matchingIndicatorIndex] = updatedIndicator;
        dispatch(
          createInstrument({
            request_body: {
              ...newData,
              scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
              save: false,
              data_values: true,
            },
          }),
        ).then((result: any) => {
          dispatch(setEditInstrumentOutput(result?.payload?.data));
          dispatch(
            setEditInstrumentData({
              ...widget,
              instrument_indicators: result?.payload?.instrument_indicators,
            }),
          );
        });
      } else {
        const newData = JSON.parse(JSON.stringify(editData));
        newData.instrument_indicators[matchingIndicatorIndex] = updatedIndicator;
        dispatch(
          updateInstrument({
            request_body: {
              ...newData,
              scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
              save: false,
              data_values: true,
            },
          }),
        ).then((result: any) => {
          dispatch(setEditInstrumentOutput(result?.payload?.data));
          dispatch(
            setEditInstrumentData({
              ...widget,
              instrument_indicators: result?.payload?.instrument_indicators,
            }),
          );
        });
      }
    }
  };

  const handleTimeGranulirity = (value: any) => {
    const newTimeGranularity =
      value === 'M' || value === 'm'
        ? 'month'
        : value === 'Q' || value === 'q'
        ? 'quarter'
        : 'year';
    if (editData?.instrument_type) {
      const newData = JSON.parse(JSON.stringify(editData));
      newData.time_granularity = newTimeGranularity;
      dispatch(setEditInstrumentData(newData));
    }
  };

  return (
    <Modal
      isOpen
      isCentered
      onClose={() => {
        handleCancel();
      }}
      motionPreset='slideInBottom'
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        height={'80%'}
        maxH={'80%'}
        maxWidth={'80%'}
        overflow={'hidden'}
        color={'black.600'}
      >
        <ModalBody padding={0} height={'inherit'}>
          {calculationLoadingStatus === STATUS.LOADING && (
            <Progress size='sm' isIndeterminate colorScheme='teal' />
          )}

          <Flex height={'100%'}>
            {calculationLoadingStatus === STATUS.FAILED ? (
              <Flex
                width={{ base: '65%', xl: '70%' }}
                height='100%'
                justifyContent={'center'}
                alignItems={'center'}
                bg={'linear-gradient(to right, #f5f5f5, #d9d9d9)'}
                p={'1rem 1.5rem'}
                borderLeftRadius={'md'}
                direction={'column'}
              >
                <Flex justify='space-between' gap={5}>
                  <Image src={warningIcon} alt='warning' />
                  <Flex direction='column' justify='space-between'>
                    <Text
                      mb='2.5'
                      fontFamily='bold_roboto'
                      fontSize={{ base: 'sm', sm: 'md' }}
                      lineHeight='9'
                    >
                      Oops, something went wrong
                    </Text>
                    <Text
                      width='200px'
                      fontFamily='regular_roboto'
                      fontSize={{ base: '2xs', sm: 'xs' }}
                      lineHeight='5'
                    >
                      There is some error fetching data, Please try again
                    </Text>
                  </Flex>
                </Flex>
                <Button
                  colorScheme='black'
                  variant='outline'
                  mt={{
                    base: 10,
                    sm: 14,
                    md: 17,
                  }}
                  onClick={refresh}
                >
                  Refresh
                </Button>
              </Flex>
            ) : (
              <Flex
                width={{ base: '65%', xl: '70%' }}
                bg={'linear-gradient(to right, #f5f5f5, #d9d9d9)'}
                p={'1rem 1.5rem'}
                borderLeftRadius={'md'}
                flexDir={'column'}
              >
                <Flex alignItems={'center'} mb={5}>
                  <VisibleIconComponent />
                  <Text size={'Comfortaa-bold-20'} ml={3}>
                    Preview
                  </Text>
                </Flex>

                {(editData?.instrument_type === 'line' || editData?.instrument_type === 'bar') && (
                  <Flex
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    m={1}
                  >
                    <Flex>
                      {isDimensionFilter?.length > 0 && (
                        <PriviewFilterByComponent
                          widget={editData}
                          handleApplyFilter={handleApplyFilter}
                        />
                      )}
                    </Flex>
                    <Flex>
                      {timeGranularity && (
                        <TabsFilter
                          timeGranularity={timeGranularity}
                          onChangeSummeryMode={handleTimeGranulirity}
                          fontColor={'#787878'}
                          bg={'#F7F7F815'}
                          color='white'
                          selectedYear='all'
                        />
                      )}
                    </Flex>
                  </Flex>
                )}

                {output?.instrument_type === 'kpi' && (
                  <KpiCard widgetData={output} isDashboardLayout={false} />
                )}
                {output?.instrument_type === 'line' && (
                  <LineChart
                    timeGranularityFilter={
                      output?.time_granularity === 'month'
                        ? 'M'
                        : output?.time_granularity === 'quarter'
                        ? 'Q'
                        : 'Y'
                    }
                    chartFormatting={output?.formatting}
                    graphData={output}
                    isDashboardLayout={false}
                  />
                )}

                {output?.instrument_type === 'bar' && (
                  <BarChart
                    timeGranularityFilter={
                      output?.time_granularity === 'month'
                        ? 'M'
                        : output?.time_granularity === 'quarter'
                        ? 'Q'
                        : 'Y'
                    }
                    chartFormatting={output?.formatting}
                    graphData={output}
                    isDashboardLayout={false}
                  />
                )}

                {output?.instrument_type === 'table' && (
                  <DashboardTableWrapper widget={output} isDashboardLayout={false} />
                )}
              </Flex>
            )}

            <Flex
              height={'100%'}
              width={{ base: '35%', xl: '30%' }}
              pt={'1rem'}
              overflow={'hidden'}
              justifyContent={'space-between'}
              flexDir={'column'}
              px={3}
            >
              <DashboardModalHeading handleCancel={handleCancel} />
              <Box height={'80%'} maxH={'80% !important'} className='scroll' overflow='scroll'>
                <Setup />
              </Box>
              <Flex justifyContent={'flex-end'} alignItems={'end'} bg='white'>
                <DashboardModalButtons handleCreate={handleCreate} handleCancel={handleCancel} />
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default DashboardModal;

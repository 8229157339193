import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import inputPageSlice from '../InputPageSlice';
import planPageSlice from '../PlanPageSlice';
import indicatorPageSlice from '../IndicatorPageSlice';
import itemPageSlice from '../ItemPageSlice';
import dimensionPageSlice from '../DimensionPageSlice';
import driverAdjustmentSlice from '../driverAdjustmentSlice';
import sharedSlice from '../SharedSlice';
import ToasterMiddleware from '../helpers/ToastMiddleware';
import AuthSlice from '../AuthSlice';
import scenarioSlice from '../ScenarioSlice';
import shareModalSlice from '../shareModalSlice';
import modelsSlice from '../ModelsSlice';
import headerSlice from '../HeaderSlice';
import userSlice from '../UserSlice';
import builderModeSlice from '../BuilderModeSlice';
import LoaderMiddleware from '../helpers/LoaderMiddleware';
import dataImportSlice from '../DataImportSlice';
import PermissionMiddleware from '../helpers/PermissionMiddleware';
import plannerModeSlice from '../PlannerModeSlice';
import dashboardSlice from '../DashboardSlice';
import dashboardSliceV2 from '../DashboardSliceV2';

export const rootReducer = combineReducers({
  planPageSlice,
  inputPageSlice,
  indicatorPageSlice,
  itemPageSlice,
  dimensionPageSlice,
  driverAdjustmentSlice,
  auth: AuthSlice,
  sharedSlice,
  dashboardSlice,
  dashboardSliceV2,
  scenario: scenarioSlice,
  shareModalSlice,
  modelsSlice,
  headerSlice,
  userSlice,
  dataImportSlice,
  builderModeSlice,
  plannerModeSlice,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ToasterMiddleware, LoaderMiddleware, PermissionMiddleware),
});
export default store;

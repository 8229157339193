import React from 'react';
import { Text } from '@chakra-ui/react';

const PageTitles = () => (
  <React.Fragment>
    <Text mb='2' size='primary' variant='violet'>
      Your Blox plans
    </Text>
    <Text size='home_page_subtitle' variant='grey'>
      View and edit your existing models, or create a new model. Check out our Template Library for
      inspiration.
    </Text>
  </React.Fragment>
);

export default PageTitles;

import { MenuItem } from '@chakra-ui/react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import ChartIconComponent from 'components/Icons/ChartIcon';
import KPIIconComponent from 'components/Icons/KpiIcon';
import TableIcomComponent from 'components/Icons/TableIcon';

export interface Properties {
  onSelectInstrument: (instrument: string) => void;
  flags?: any;
}

const InstrumentMenuList = ({ flags, onSelectInstrument }: Properties) => {
  return (
    <>
      <MenuItem onClick={() => onSelectInstrument('kpi')}>
        <KPIIconComponent
          width={'21.05px'}
          height={'21.05px'}
          color={'#787878'}
          marginRight='.5rem'
        />
        KPI
      </MenuItem>
      <MenuItem onClick={() => onSelectInstrument('line')}>
        <ChartIconComponent
          marginRight='.5rem'
          width={'21.05px'}
          height={'21.05px'}
          color={'#787878'}
        />
        Chart
      </MenuItem>
      <MenuItem onClick={() => onSelectInstrument('table')}>
        <TableIcomComponent
          marginRight='.5rem'
          width={'21.05px'}
          height={'21.05px'}
          color={'#787878'}
        />{' '}
        Table
      </MenuItem>
      {flags?.dashboardVariance && (
        <MenuItem onClick={() => onSelectInstrument('variance')}>
          <TableIcomComponent
            marginRight='.5rem'
            width={'21.05px'}
            height={'21.05px'}
            color={'#787878'}
          />
          Variance
        </MenuItem>
      )}
    </>
  );
};

export default withLDConsumer()(InstrumentMenuList);

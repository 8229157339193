import { Flex, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';

export interface EditTitleProperties {
  title: string;
  setTitle: (title: string) => void;
  placeholder?: string;
  showLabel?: boolean;
}
const EditTitle = ({
  title,
  setTitle,
  placeholder = '',
  showLabel = true,
}: EditTitleProperties) => {
  const [newTitle, setNewTitle] = useState(title);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setTitle(newTitle);
    }
  };

  const handleBlur = () => {
    setTitle(newTitle);
  };

  return (
    <Flex flexDir={'column'}>
      {showLabel && (
        <Text color={'black.800'} fontWeight={'600'} mb={1}>
          Title
        </Text>
      )}

      <Input
        placeholder={placeholder || 'Title...'}
        color={'black.600'}
        px={'4px'}
        borderRadius='6px'
        fontWeight={500}
        outline={'2px solid transparent'}
        fontSize={'medium'}
        fontFamily={'regular_roboto'}
        autoFocus
        border={'2px solid #E2E8F0'}
        background={'#F7FAFC'}
        outlineOffset={'2px'}
        value={newTitle}
        onChange={(event) => setNewTitle(event.target.value)}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
      />
    </Flex>
  );
};

export default EditTitle;

import { Flex, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'utils/GlobalHelpers';
import AddToDashboard from 'components/Icons/AddToDashboard';
import { prepareCreatePayload } from 'redux/DashboardSliceV2';

import InstrumentMenuList from './InstrumentMenuList';

const EmptyDashboard = () => {
  const dispatch: AppDispatch = useDispatch();

  const onSelectInstrument = (widgetType: string) => dispatch(prepareCreatePayload(widgetType));

  return (
    <Flex
      flexDirection={'column'}
      width={'100%'}
      maxW={'400px'}
      height={'250px'}
      bg={'white'}
      color={'#787878'}
      borderRadius={'15px'}
      justifyContent={'space-around'}
      alignItems={'center'}
      p={6}
    >
      <Menu variant={'dashboarding'}>
        {({ isOpen }) => {
          return (
            <>
              <MenuButton>
                <AddToDashboard width={100} height={100} />
              </MenuButton>
              {isOpen && (
                <MenuList>
                  <InstrumentMenuList onSelectInstrument={onSelectInstrument} />
                </MenuList>
              )}
            </>
          );
        }}
      </Menu>
      <Text size='addToDashboard'>Add to dashboard</Text>
    </Flex>
  );
};

export default EmptyDashboard;

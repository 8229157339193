import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Spinner from 'components/Spinner';
import { getAllInstrumentOutputs, getDashboardBlocks } from 'redux/DashboardSliceV2';
import formatValue from 'utils/FormatValues';

interface Properties {
  widgetData: any;
  isDashboardLayout?: boolean;
}

const KpiCard = ({ widgetData, isDashboardLayout }: Properties) => {
  const modalBlocks = useSelector(getDashboardBlocks);
  const [isLoading, setIsLoading] = useState(false);
  const [totalValues, setTotalValues] = useState<number | null>(null);
  const allInstrumentsOutputs = useSelector(getAllInstrumentOutputs);

  const dataType = useMemo(() => {
    try {
      const foundBlock: any = modalBlocks.find((block: any) => {
        return block.block_id === widgetData.instrument_indicators[0].block_id;
      });

      if (foundBlock?.indicators) {
        const indicator = foundBlock?.indicators.find((ind: any) => {
          return widgetData?.instrument_indicators[0]?.indicator_id === ind.indicator_id;
        });
        return indicator?.data_format_properties || {};
      }
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
    return null;
  }, [widgetData?.instrument_indicators?.length, modalBlocks]);

  useEffect(() => {
    if (widgetData?.id && allInstrumentsOutputs && isDashboardLayout) {
      setIsLoading(false);
      const KPIdata = allInstrumentsOutputs?.[widgetData?.id];
      const value = KPIdata?.data?.map((item: any) => item[Object.keys(item)[0]]);
      if (value) {
        setTotalValues(value[0]);
      }
    } else {
      setIsLoading(false);
      const value = widgetData?.data?.map((item: any) => item[Object.keys(item)[0]]);
      if (value) {
        setTotalValues(value[0]);
      }
    }
  }, [widgetData, allInstrumentsOutputs]);

  return (
    <Box
      height={'100%'}
      borderRadius='15px'
      // border={isDashboardLayout ? '' : '1px solid #787878'}
      // boxShadow={isDashboardLayout ? '' : '0px 4px 4px rgba(0, 0, 0, 0.25)'}
    >
      {isLoading && (
        <Flex
          height={'100%'}
          direction='column'
          alignItems='center'
          justifyContent='center'
          justify='center'
          align='center'
          w={'full'}
        >
          <Spinner styleProperties={{ my: '4vh' }} />
        </Flex>
      )}
      {!isLoading && (
        <>
          <Flex
            flexDirection='column'
            justifyContent='space-between'
            alignItems='space-between'
            className='dashboard-drag-header'
            cursor='grab'
          >
            <Box marginY={3}>
              <Flex marginInline={4} justifyContent='space-between' alignItems='center' gap={5}>
                <Tooltip
                  label={
                    totalValues === null || totalValues === undefined || totalValues === 0
                      ? 0
                      : `${formatValue(totalValues, dataType)}`
                  }
                >
                  <Text
                    flex={1}
                    minWidth={0}
                    w={'100%'}
                    fontSize={{ base: '22px', sm: '22px', md: '22px', lg: '28px', xl: '34px' }}
                    fontWeight='400'
                    color={'#57519C'}
                    className='text-overflow'
                  >
                    {totalValues === null || totalValues === undefined || totalValues === 0
                      ? 0
                      : `${formatValue(totalValues, dataType)}`}
                  </Text>
                </Tooltip>
              </Flex>
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
};
export default KpiCard;

import { Box, Flex, Text } from '@chakra-ui/react';

export interface FormatProperties {
  handleTextDecoration: (style: string) => void;
  selectedStyle: string;
}

const RowFormatting = ({ handleTextDecoration, selectedStyle }: FormatProperties) => {
  const handleChange = (style: string) => {
    if (selectedStyle === style) {
      handleTextDecoration('');
    } else {
      handleTextDecoration(style);
    }
  };
  return (
    <Flex mb={2} justifyContent={'space-between'}>
      <Box>
        <Text color={'black.800'} fontWeight={'600'}>
          Cell formatting
        </Text>
      </Box>
      <Flex alignItems={'center'} border={'1px solid #D9D9D9'} borderRadius={'7.62px'}>
        <Text
          width={8}
          textAlign={'center'}
          cursor={'pointer'}
          fontSize={'1.2rem'}
          borderRight={'2px solid #D9D9D9'}
          color={selectedStyle === 'bold' ? 'black.900' : '#787878'}
          fontWeight={'700'}
          onClick={() => handleChange('bold')}
        >
          B
        </Text>
        <Text
          textAlign={'center'}
          width={8}
          cursor={'pointer'}
          fontSize={'1.2rem'}
          borderRight={'2px solid #D9D9D9'}
          color={selectedStyle === 'italic' ? 'black.900' : '#787878'}
          fontStyle={'italic'}
          onClick={() => handleChange('italic')}
        >
          I
        </Text>
        <Text
          width={8}
          textAlign={'center'}
          cursor={'pointer'}
          fontSize={'1.2rem'}
          color={selectedStyle === 'underline' ? 'black.900' : '#787878'}
          textDecoration={'underline'}
          onClick={() => handleChange('underline')}
        >
          U
        </Text>
      </Flex>
    </Flex>
  );
};
export default RowFormatting;

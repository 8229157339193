/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

export type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isNullOrUndefinedOrEmpty = (value: any): boolean => {
  return value === null || value === undefined || value === '';
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let index = 0; index < 6; index += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const indicatorfiltersName = (filtersData: any, blockDimensions: any) => {
  const selectedCheckboxes = filtersData?.map((item: any) => {
    const key = Object.keys(item)[0];
    const index = blockDimensions.findIndex((block: any) => block?.name === key);
    if (item[key]?.length < 1) return null;
    if (item[key]?.length === blockDimensions[index]?.items?.length) return `All ${key}`;
    if (item[key]?.length === 1) return item[key][0];
    if (item[key]?.length < 3) return item[key]?.join(', ');
    if (item[key]?.length >= 3)
      return `${item[key]?.slice(0, 2).join(', ')}...(${item[key].length - 2})`;
  });

  return selectedCheckboxes;
};

export const getTableFilterPayload = (selectedDimensions: any[], blockDimensions: any) => {
  const selectedDimItems = Object.values(selectedDimensions)[0];
  const filterItems: any = selectedDimItems.map((item: any) => {
    return { name: item, status: true, search: true };
  });

  const dimId = blockDimensions?.id;
  const dimensionItems = blockDimensions?.items.map((item: any) => {
    if (selectedDimItems.includes(item)) return { name: item, status: true, search: true };
    return { name: item, status: false, search: true };
  });

  return {
    tableItems: { dimId, filterItems },
    dimensionItems: { id: dimId, resultedItems: dimensionItems },
  };
};

export const hasStartDate = (text: string) =>
  text === 'Start Date' ||
  text === 'start date' ||
  text === 'Start date' ||
  text === 'start Date' ||
  text === 'start' ||
  text === 'Start';

export const hasEndDate = (text: string) =>
  text === 'End Date' ||
  text === 'end date' ||
  text === 'End date' ||
  text === 'end Date' ||
  text === 'End' ||
  text === 'end';

export const hasStartAndEndDate = (text: string) => hasStartDate(text) && hasEndDate(text);

import { SearchIcon } from '@chakra-ui/icons';
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useState, useRef, useEffect, useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import { GoChevronDown } from 'react-icons/go';
import { useSelector } from 'react-redux';

import { STATUS } from 'projectConstants';
import { getDashboardBlocks, getDashboardBlockStatus } from 'redux/DashboardSliceV2';

export interface Properties {
  blockId: number;
  handleBlockChange: (block: any) => void;
}
const ModalBlock = ({ blockId, handleBlockChange }: Properties) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const blockDetails = useSelector(getDashboardBlocks);
  const getBlockStatus = useSelector(getDashboardBlockStatus);
  const [searchValue, setSearchValue] = useState('');
  const inputReference: any = useRef(null);
  const itemHeight = 30;
  const [listData, setListData] = useState(blockDetails);
  const calculatedHeight = listData.length * itemHeight;
  const height = Math.min(calculatedHeight, 150);
  const width = 260;

  const selectedBlock = useMemo(() => {
    if (blockId) {
      const blockData = blockDetails?.find((item: any) => item.block_id === blockId);
      return blockData.block_name;
    }
    return null;
  }, [blockId, blockDetails]);

  useEffect(() => {
    if (blockDetails) {
      setListData(blockDetails);
    }
  }, [blockDetails]);

  const handleSearch = (value: string) => {
    setListData(
      blockDetails.filter((item: any) =>
        item.blockName?.toLowerCase().includes(searchValue?.toLowerCase()),
      ),
    );
    setSearchValue(value);
  };

  const blockChange = (block: any) => {
    handleBlockChange(block);
    onClose();
  };

  const Row = (properties: any) => {
    const { index, style, data } = properties;
    return (
      <Flex
        alignItems={'center'}
        borderRadius={'6px'}
        paddingInline={2}
        _hover={{ backgroundColor: '#EFF2F6' }}
        onClick={() => {
          blockChange(data[index]);
        }}
        style={style}
        cursor={'pointer'}
      >
        {data[index].block_name}
      </Flex>
    );
  };

  return (
    <Flex flexDir={'column'}>
      <Text color={'black.800'} fontWeight={'600'} mb={1}>
        Block
      </Text>
      <Menu isOpen={isOpen} onClose={onClose} strategy='fixed' isLazy variant={'primary'}>
        <MenuButton width={'100%'} onClick={isOpen ? onClose : onOpen}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            backgroundColor={'#FAFAFA'}
            border={'1px #D9D9D9 solid'}
            padding={'.5rem .8rem'}
            borderRadius={'6px'}
            color={'black.600'}
            fontSize={'2xs'}
          >
            <Text>{selectedBlock || 'Click to select block'}</Text>
            <GoChevronDown size={18} />
          </Flex>
        </MenuButton>
        <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
          {getBlockStatus === STATUS.LOADING ? (
            <MenuItem>
              <Spinner size={'md'} />
            </MenuItem>
          ) : (
            <>
              <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    h='100%'
                    children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                  />
                  <Input
                    width={'93%'}
                    color={'black.900'}
                    ref={inputReference}
                    placeholder='Search'
                    _placeholder={{ color: '#D9D9D9' }}
                    onChange={(event: any) => {
                      handleSearch(event.target.value);
                    }}
                  />
                </InputGroup>
              </Flex>
              {blockDetails.length > 0 ? (
                <FixedSizeList
                  className='scroll'
                  height={height}
                  itemData={listData}
                  itemCount={listData.length}
                  itemSize={itemHeight}
                  width={width}
                >
                  {Row}
                </FixedSizeList>
              ) : (
                <Text>No Block Found</Text>
              )}
            </>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};
export default ModalBlock;

import { DragHandleIcon } from '@chakra-ui/icons';
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfoIconComponent from 'components/Icons/InfoIcon';
import {
  getAllInstrumentOutputs,
  getAllInstrumentOutputsStatus,
  getAllInstruments,
  setAllInstruments,
  setCalculationLocadingStatus,
  setEditInstrumentData,
  setEditInstrumentOutput,
  updateInstrument,
} from 'redux/DashboardSliceV2';
import { AppDispatch } from 'utils/GlobalHelpers';
import Spinner from 'components/Spinner';
import { STATUS } from 'projectConstants';

import EditableTitle from './EditableTitle';
import KpiCard from './kpi/KpiCard';
import ChartCard from './chart/ChartCard';
import DashboardTableWrapper from './table/DashboardTableWrapper';

export interface InstrumentProperties {
  instrumentData: any;
  deleteInstrument: (instrumentData: any) => void;
}
const Instrument = ({ instrumentData, deleteInstrument }: InstrumentProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const allInstrumentOutputsStatus = useSelector(getAllInstrumentOutputsStatus);
  const parentReference = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [hover, setHover] = useState<boolean>(false);
  const allInstruments: Array<any> = useSelector(getAllInstruments);
  const allInstrumentsData = useSelector(getAllInstrumentOutputs);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (parentReference.current) {
        setParentWidth(parentReference.current.offsetWidth);
      }
    };

    // Initialize width on mount
    updateWidth();

    // Use ResizeObserver to detect changes in element size
    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    if (parentReference.current) {
      resizeObserver.observe(parentReference.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const handleEditClick = () => {
    dispatch(setCalculationLocadingStatus(STATUS.IDLE));
    dispatch(setEditInstrumentData(instrumentData));
    dispatch(setEditInstrumentOutput(allInstrumentsData?.[instrumentData?.id]));
  };

  const handleTitleChange = (title: any) => {
    const editData = JSON.parse(JSON.stringify(instrumentData));
    editData.name = title;
    if (editData.id) {
      const foundIndex = allInstruments.findIndex(
        (instrument: any) => instrument.id === editData.id,
      );
      if (foundIndex > -1) {
        const list = JSON.parse(JSON.stringify(allInstruments));
        list[foundIndex] = editData;
        dispatch(setAllInstruments([...list]));
      }
      dispatch(updateInstrument({ request_body: { ...editData, save: true, data_values: false } }));
    }
  };

  return (
    <>
      <Box
        height={'100%'}
        padding={'0.6rem'}
        zIndex={'9999'}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        width='100%'
      >
        <Flex justifyContent={'space-between'} ref={parentReference}>
          <Flex width='calc(100% - 40px)' height='40px' alignItems={'center'}>
            <EditableTitle
              value={instrumentData?.name}
              handleChange={handleTitleChange}
              isEditable={true}
              width={parentWidth - 40 > 0 ? `${parentWidth - 40}px` : '0px'}
            />
          </Flex>
          <Flex gap={1} visibility={hover ? 'visible' : 'hidden'} alignItems={'start'}>
            <Menu
              closeOnSelect={true}
              strategy='fixed'
              variant={'primary'}
              direction={'ltr'}
              placement='left-start'
            >
              <MenuButton>
                <InfoIconComponent />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                <MenuItem onClick={deleteInstrument}>Delete</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        {allInstrumentOutputsStatus !== STATUS.SUCCESS && (
          <Flex
            height='calc(100% - 30px)'
            direction='column'
            alignItems='center'
            justifyContent='center'
            justify='center'
            align='center'
            w={'full'}
          >
            <Spinner styleProperties={{ my: '4vh' }} />
          </Flex>
        )}
        {allInstrumentOutputsStatus === STATUS.SUCCESS && (
          <Box
            height='calc(100% - 30px)'
            position={'relative'}
            className='scroll'
            overflow={'auto'}
          >
            {/* height='300px' */}
            {instrumentData?.instrument_type === 'kpi' && (
              <KpiCard widgetData={instrumentData} isDashboardLayout={true} />
            )}
            {(instrumentData.instrument_type === 'line' ||
              instrumentData.instrument_type === 'bar') && (
              <ChartCard chartData={instrumentData} isDashboardLayout={true} />
            )}

            {instrumentData?.instrument_type === 'table' && (
              <DashboardTableWrapper widget={instrumentData} isDashboardLayout={true} />
            )}
          </Box>
        )}

        {hover && (
          <Box position='absolute' bottom='3' right='3' zIndex='dropdown'>
            <button className='dashboard-drag-header'>
              <DragHandleIcon />
            </button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Instrument;

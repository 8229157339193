import { Box, Flex, Text } from '@chakra-ui/react';

import LeftIndent from 'components/Icons/LeftIndent';
import RightIndent from 'components/Icons/RightIndent';

export interface FormatProperties {
  selectedAlignemnt: string;
  handleAlignemntChange: (event: any) => void;
}

const RowIndentation = ({ handleAlignemntChange, selectedAlignemnt }: FormatProperties) => {
  const handleChange = (style: string) => {
    if (selectedAlignemnt === style) {
      handleAlignemntChange('');
    } else {
      handleAlignemntChange(style);
    }
  };
  return (
    <Flex alignItems={'center'} mb={2} justifyContent={'space-between'}>
      <Text color={'black.800'} fontWeight={'600'}>
        Indent
      </Text>
      <Flex gap={3}>
        <Box cursor={'pointer'} onClick={() => handleChange('left')}>
          <LeftIndent color={selectedAlignemnt === 'left' ? '#000' : '#787878'} />
        </Box>
        <Box cursor={'pointer'} onClick={() => handleChange('right')}>
          <RightIndent color={selectedAlignemnt === 'right' ? '#000' : '#787878'} />
        </Box>
      </Flex>
    </Flex>
  );
};
export default RowIndentation;

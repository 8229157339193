/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */
const headerRow = (tableItemsProperty_: any, tableProperty_: any) => {
  return {
    rowId: 'header',
    height: 50,
    nonEditable: true,
    cells: [
      {
        type: 'filter',
        id: 'name',
        text: 'Name',
        hasDelete: false,
        hasEdit: false,
        hasFiltersApplied: false,
        nonEditable: true,
      },
      ...(tableProperty_?.length
        ? tableProperty_?.map((data: any, index: any) => {
            const row = {
              type: 'filter',
              id: data?.id,
              hasFiltersApplied: false,
              hasDelete: true,
              hasEdit: true,
              colType: 'header',
              text: data?.name,
              nonEditable: true,
              className: `${index === tableProperty_?.length - 1 ? ' last-index ' : ''}`,
            };
            return row;
          })
        : []),
      { type: 'header', text: 'Del', nonEditable: true },
    ],
  };
};

const footerRow = (tableProperty_: any) => {
  return {
    rowId: 'footer',
    height: 50,
    nonEditable: true,
    cells: [
      { type: 'addRow', text: '', className: 'footer', nonEditable: true },
      ...(tableProperty_?.length
        ? tableProperty_?.map((data: any, index: number) => {
            const row = {
              type: 'text',
              text: '',
              className: `${index === tableProperty_?.length - 1 ? ' last-index ' : ''} footer`,
              nonEditable: true,
              hasFormulaError: false,
            };
            return row;
          })
        : []),
      {
        type: 'text',
        text: '',
        className: 'footer last-footer',
        nonEditable: true,
        hasFormulaError: false,
      },
    ],
  };
};

const getRows = (tableItemsProperty_: any, tableProperty_: any) => {
  return [
    headerRow(tableItemsProperty_, tableProperty_),
    ...(tableItemsProperty_?.length
      ? tableItemsProperty_?.map((item: any, index: any) => {
          return {
            rowId: index,
            height: 50,
            itemId: item?.id,
            itemName: item?.name,
            cells: [
              {
                type: 'text',
                placeholder: 'Enter value',
                text: `${item?.name}` ?? '',
                property: 'name',
                rows: tableItemsProperty_?.length,
                className: 'name',
                hasFormulaError: false,
                index,
              },
              ...(tableProperty_?.length
                ? tableProperty_?.map((data: any, index_: any) => {
                    if (
                      data?.name.trim().toLowerCase() === 'start' ||
                      data?.name.trim().toLowerCase() === 'end' ||
                      data?.name.trim().toLowerCase() === 'start date' ||
                      data?.name.trim().toLowerCase() === 'end date'
                    ) {
                      const row = {
                        type: 'date',
                        text: item[`${data?.id}`] || '',
                        date: new Date(),
                        nonEditable: !item?.id,
                        className: `${index_ === tableProperty_?.length - 1 ? ' last-index ' : ''}`,
                      };
                      return row;
                    } else if (data?.items) {
                      const row = {
                        className: index_ === tableProperty_?.length - 1 && ' last-index ',
                        type: 'dropdown',
                        nonEditable: !item?.id,
                        selectOption: true,
                        selectedValue: item[`${data?.id}`],
                        values: [
                          ...(data?.items?.length
                            ? data?.items?.map((value: any) => ({
                                label: value?.name,
                                value: value?.name,
                              }))
                            : []),
                        ],
                        index,
                        operation: '',
                        data,
                      };
                      return row;
                    } else {
                      const row = {
                        type: 'text',
                        rows: tableItemsProperty_?.length,
                        text: item[`${data?.id}`] ?? '',
                        index,
                        data,
                        nonEditable: !item?.id,
                        className: `${index_ === tableProperty_?.length - 1 ? ' last-index ' : ''}`,
                        hasFormulaError: false,
                      };
                      return row;
                    }
                  })
                : []),
              {
                type: 'dropdown',
                selectOption: true,
                buttonType: 1,
                id: item?.id,
                itemName: item?.name,
                nonEditable: !item?.id,
                values: [{ label: 'Delete row', value: '' }],
              },
            ],
          };
        })
      : []),
    footerRow(tableProperty_),
  ];
};

const calculateWidth = (totalElements: number, totalWidth: number) => {
  const nameField: number = 150;
  const editField: number = 60;
  const columnsWidth = totalElements * 150 + nameField + editField;
  return totalWidth > columnsWidth ? (totalWidth - editField) / (totalElements + 1) : 150;
};
const calculateColumnWidth = (name: string) => {
  const baseWidth = 150; // Minimum column width
  const multiplier = 12; // Adjust this for better spacing
  return Math.max(baseWidth, name.length * multiplier);
};
const getColumns = (tableProperty_: any, tableReference: any) => {
  return tableProperty_?.length
    ? [
        {
          columnId: 'name',
          width: tableReference ? calculateWidth(tableProperty_?.length, tableReference) : 200,
          resizable: true,
        },
        ...tableProperty_?.map((data: any) => {
          const row = {
            columnId: `${data?.id}`,
            width: tableReference ? calculateColumnWidth(data?.name) : 200,
            resizable: true,
          };
          return row;
        }),
        { columnId: 'delete', width: 60 },
      ]
    : [];
};
export { headerRow, footerRow, getRows, getColumns };

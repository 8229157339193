import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
  arrayMove,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import Plus from 'components/Icons/Plus';
import {
  createInstrument,
  getEditInstrumentData,
  getSelectedDashboardScenario,
  setEditInstrumentData,
  setEditInstrumentOutput,
  updateInstrument,
} from 'redux/DashboardSliceV2';
import { AppDispatch } from 'utils/GlobalHelpers';
import { getBlocks } from 'redux/ModelsSlice';
import CustomToast from 'components/CustomToast';

import IndicatorCard from './IndicatorCard';

const TableIndicator = () => {
  const dispatch: AppDispatch = useDispatch();
  const widget = useSelector(getEditInstrumentData);
  const modelData = useSelector(getBlocks);
  const [indicators, setIndicators] = useState<any>([]);
  const [displayPopup, setDisplayPopup] = useState(false);
  const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const indicatorIds = indicators.map((indicator: any) => indicator.indicator_id);
    const oldIndex = indicatorIds.indexOf(active.id);
    const newIndex = indicatorIds.indexOf(over.id);

    const activeIndicator = indicators.find((ind: any) => ind.indicator_id === active.id);
    const overIndicator = indicators.find((ind: any) => ind.indicator_id === over.id);
    setIndicators([...arrayMove(indicators, oldIndex, newIndex)]);
    console.log('=>', oldIndex, newIndex, activeIndicator, overIndicator);
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (widget?.instrument_indicators?.length) {
      setIndicators(widget?.instrument_indicators);
    }
  }, [widget]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let index = 0; index < 6; index += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleAddIndicator = () => {
    setIndicators((list: any) => {
      return [
        ...list,
        {
          indicator_id: null,
          block_id: null,
          dimension_id: null,
          aggregate_function: 'sum',
          indicator_name: '', // TODO: unused should be removed
          dimension_name: '', // TODO: unused should be removed
          formatting: {
            color: getRandomColor(),
          },
        },
      ];
    });
  };

  const selectIndicatorBlock = (block_id: any, index: number) => {
    const indicatorList = JSON.parse(JSON.stringify(indicators));
    indicatorList[index].block_id = block_id;
    setIndicators([...indicatorList]);
  };

  const selectIndicator = (item: any, index: number) => {
    const indicatorList = JSON.parse(JSON.stringify(indicators));
    indicatorList[index].indicator_id = item.indicator_id;
    indicatorList[index].indicator_name = item.indicator_name; // TODO: unused should be removed
    indicatorList[index].position = index;
    indicatorList[index].dimension_id = null;
    indicatorList[index].dimension_name = '';
    setIndicators([...indicatorList]);
    if (!widget.id && item.indicator_id) {
      dispatch(
        createInstrument({
          request_body: {
            ...widget,
            instrument_indicators: [...indicatorList],
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            save: false,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
      });
    } else {
      dispatch(
        updateInstrument({
          request_body: {
            ...widget,
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            instrument_indicators: [...indicatorList],
            save: false,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
      });
    }
  };

  const selectDimension = (item: any, index: number) => {
    const indicatorList = JSON.parse(JSON.stringify(indicators));
    indicatorList[index].dimension_id = item.dimension_id;
    indicatorList[index].dimension_name = item.dimension_name; // TODO: unused should be removed
    setIndicators([...indicatorList]);
    if (!widget.id && item.dimension_id) {
      dispatch(
        createInstrument({
          request_body: {
            ...widget,
            instrument_indicators: [...indicatorList],
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            save: false,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
      });
    } else {
      dispatch(
        updateInstrument({
          request_body: {
            ...widget,
            instrument_indicators: [...indicatorList],
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            save: false,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
      });
    }
  };

  const removeIndicator = (indicator_id: any) => {
    const indicatorList = JSON.parse(JSON.stringify(indicators));
    const indexToRemove = indicatorList.findIndex((ind: any) => ind.indicator_id === indicator_id);

    const filtered =
      indexToRemove === -1
        ? indicatorList.map((ind: any, index: number) => ({ ...ind, position: index }))
        : [...indicatorList.slice(0, indexToRemove), ...indicatorList.slice(indexToRemove + 1)].map(
            (ind: any, index: number) => ({ ...ind, position: index }),
          );
    if (!indicator_id) {
      setIndicators([...filtered]);
    } else if (filtered?.length > 0 && filtered?.length < indicatorList?.length) {
      setIndicators([...filtered]);
      if (!widget.id && indicator_id) {
        dispatch(
          createInstrument({
            request_body: {
              ...widget,
              instrument_indicators: [...filtered],
              scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
              save: false,
              data_values: true,
            },
          }),
        ).then((result) => {
          dispatch(setEditInstrumentOutput(result?.payload?.data));
        });
      } else {
        dispatch(
          updateInstrument({
            request_body: {
              ...widget,
              instrument_indicators: [...filtered],
              scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
              save: false,
              data_values: true,
            },
          }),
        ).then((result) => {
          dispatch(setEditInstrumentOutput(result?.payload?.data));
        });
      }
    } else {
      setDisplayPopup(true);
      setTimeout(() => {
        setDisplayPopup(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (indicators?.length) {
      dispatch(
        setEditInstrumentData({
          ...widget,
          instrument_indicators: indicators,
        }),
      );
    }
  }, [indicators]);

  const disableDimension = () => {
    if (modelData?.dimensions?.length <= 1) {
      return { disable: true, message: 'No dimensions available for this indicator' };
    }
    if (
      (widget?.instrument_type === 'line' || widget?.instrument_type === 'bar') &&
      widget?.instrument_indicators?.length > 1
    ) {
      return {
        disable: true,
        message: 'Dimensions not available when displaying multiple indicators',
      };
    }
    return { disable: false, message: '' };
  };

  return (
    <Box>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Text color={'black.800'} fontWeight={'600'}>
          {widget?.instrument_type === 'variance' ? 'Rows' : 'Indicators'}
        </Text>
        <Box>
          <Button
            width={'14.583px'}
            disabled={indicators?.length > widget?.instrument_indicators?.length}
            onClick={handleAddIndicator}
          >
            <Plus color='#787878' />
          </Button>
        </Box>
      </Flex>
      <Flex gap={1} alignItems={'center'} justifyContent={'space-between'} mb={1} display={'none'}>
        {widget?.instrument_indicators?.length > 0 ? (
          <>
            {!disableDimension().disable ? (
              <></>
            ) : (
              <Flex justifyContent={'center'} p={2} borderRadius={5} backgroundColor={'#F4F3FF'}>
                <Text alignItems={'center'} fontSize={'smaller'} color={'black'}>
                  {disableDimension().message}
                </Text>
              </Flex>
            )}
          </>
        ) : (
          <></>
        )}
      </Flex>
      {indicators.length > 0 && (
        <Box maxH={'100%'} overflow={'scroll'} maxW={'100%'} className='scroll'>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={indicators} strategy={verticalListSortingStrategy}>
              {indicators?.map((indicator: any, index: number) => (
                <Box key={indicator?.id} marginY='1'>
                  <IndicatorCard
                    key={indicator?.id}
                    removeIndicator={removeIndicator}
                    selectDimension={(item: any) => {
                      selectDimension(item, index);
                    }}
                    selectIndicator={(item: any) => {
                      selectIndicator(item, index);
                    }}
                    selectBlock={(block_id: any) => {
                      selectIndicatorBlock(block_id, index);
                    }}
                    indicator={indicator}
                    showPopup={
                      disableDimension().disable && widget?.instrument_indicators?.length > 0
                    }
                  />
                </Box>
              ))}
            </SortableContext>
          </DndContext>
        </Box>
      )}
      {displayPopup && <CustomToast title='Atleast one indicator is required' status='error' />}
    </Box>
  );
};
export default TableIndicator;

import { useSelector, useDispatch } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

import {
  createInstrument,
  getEditInstrumentData,
  getSelectedDashboardScenario,
  getSelectedRow,
  setEditInstrumentData,
  setEditInstrumentOutput,
} from 'redux/DashboardSliceV2';
import { AppDispatch } from 'utils/GlobalHelpers';

import DashboardModalFilters from '../../Filters';

import ChartType from './ChartType';
import EditTitle from './EditTitle';
import ModalBlock from './ModalBlock';
import ModalIndicator from './ModalIndicator';
import TableIndicator from './TableIndicator';
import RowFormatting from './Formatting/RowFormatting';
import RowIndentation from './Formatting/RowIndentation';
import RowCellColor from './Formatting/RowCellColor';
import RowTextColor from './Formatting/RowTextColor';
import ColumnSettingsPopover from './ColumnSettingPopover';

const Setup = () => {
  const dispatch: AppDispatch = useDispatch();
  const widget = useSelector(getEditInstrumentData);
  const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);
  const currentSelectedRow = useSelector(getSelectedRow);
  const [selectedRow, setSelectedRow] = useState<any>(currentSelectedRow);
  const [columnSettings, setColumnSettings] = useState({
    columnName: '',
    showPY: true,
    varianceToPY: true,
    variancePercentPY: true,
    showPL: true,
    varianceToPL: true,
    variancePercentPL: true,
    scenario: 'budget-2024',
  });

  const handleSettingsChange = (updatedSettings: any) => {
    setColumnSettings((previous) => ({ ...previous, ...updatedSettings }));
  };

  const findIndicatorIndex = (indicators: any, indicatorId: string) => {
    return widget?.instrument_indicators.findIndex(
      (indicator: any) => indicator.indicator_id === indicatorId,
    );
  };

  useEffect(() => {
    if (currentSelectedRow) {
      const index = findIndicatorIndex(
        widget.instrument_indicators,
        currentSelectedRow?.indicator_id,
      );
      setSelectedRow(widget.instrument_indicators[index]);
    }
  }, [widget, currentSelectedRow]);

  const setChartType = (type: string) => {
    dispatch(setEditInstrumentData({ ...widget, instrument_type: type }));
  };

  const setTitle = (title: string) => {
    dispatch(setEditInstrumentData({ ...widget, name: title }));
  };

  const handleKPIBlockChange = (block: any) => {
    dispatch(
      setEditInstrumentData({
        ...widget,
        instrument_indicators: [
          {
            block_id: block.block_id,
            indicator_id: null,
            aggregate_function: 'sum',
            indicator_name: '', // TODO: unused should be removed
            formatting: {}, // TODO: unused should be removed
          },
        ],
      }),
    );
  };

  const handleKPIIndicatorChange = (indicator: any) => {
    dispatch(
      setEditInstrumentData({
        ...widget,
        instrument_indicators: [
          {
            ...widget.instrument_indicators[0],
            indicator_id: indicator.indicator_id,
            indicator_name: indicator.indicator_name, // TODO: unused should be removed
            formatting: {}, // TODO: unused should be removed
          },
        ],
      }),
    );
    dispatch(
      createInstrument({
        request_body: {
          ...widget,
          scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
          instrument_indicators: [
            {
              ...widget.instrument_indicators[0],
              indicator_id: indicator.indicator_id,
              indicator_name: indicator.indicator_name, // TODO: unused should be removed
              formatting: {}, // TODO: unused should be removed
            },
          ],
          save: false,
          data_values: true,
        },
      }),
    ).then((result) => {
      dispatch(setEditInstrumentOutput(result?.payload?.data));
    });
  };

  const fetchOutput = (newWidget: any) => {
    dispatch(setEditInstrumentData(newWidget));
    dispatch(
      createInstrument({
        request_body: {
          ...newWidget,
          scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
          save: false,
          data_values: true,
        },
      }),
    ).then((result) => {
      dispatch(setEditInstrumentOutput(result?.payload?.data));
    });
  };

  const handleTextDecoration = debounce((content: any) => {
    if (widget && selectedRow) {
      const index = findIndicatorIndex(widget.instrument_indicators, selectedRow.indicator_id);
      const newWidget = JSON.parse(JSON.stringify({ ...widget }));
      newWidget.instrument_indicators[index].formatting = {
        ...newWidget.instrument_indicators[index].formatting,
        textDecoration: content,
      };
      fetchOutput(newWidget);
    }
  }, 1000);

  const handleColorCellChange = debounce((content: any) => {
    if (widget && selectedRow) {
      const index = findIndicatorIndex(widget.instrument_indicators, selectedRow.indicator_id);
      const newWidget = JSON.parse(JSON.stringify({ ...widget }));
      newWidget.instrument_indicators[index].formatting = {
        ...newWidget.instrument_indicators[index].formatting,
        cellColor: content,
      };
      fetchOutput(newWidget);
    }
  }, 1000);

  const handleColorChange = debounce((content: any) => {
    if (widget && selectedRow) {
      const index = findIndicatorIndex(widget.instrument_indicators, selectedRow.indicator_id);
      const newWidget = JSON.parse(JSON.stringify({ ...widget }));
      newWidget.instrument_indicators[index].formatting = {
        ...newWidget.instrument_indicators[index].formatting,
        textColor: content,
      };
      fetchOutput(newWidget);
    }
  }, 1000);

  const handleAlignemntChange = debounce((content: any) => {
    if (widget && selectedRow) {
      const index = findIndicatorIndex(widget.instrument_indicators, selectedRow.indicator_id);
      const newWidget = JSON.parse(JSON.stringify({ ...widget }));
      newWidget.instrument_indicators[index].formatting = {
        ...newWidget.instrument_indicators[index].formatting,
        textAlign: content,
      };
      fetchOutput(newWidget);
    }
  }, 1000);

  const blockId =
    widget?.instrument_indicators && widget?.instrument_indicators?.length > 0
      ? widget?.instrument_indicators[0].block_id
      : null;

  const indicatorId =
    widget?.instrument_indicators && widget?.instrument_indicators?.length > 0
      ? widget?.instrument_indicators[0].indicator_id
      : null;

  return (
    <Flex py={1} px={0} gap={3} flexDirection={'column'}>
      <EditTitle title={widget?.name} setTitle={setTitle} />
      {(widget?.instrument_type === 'line' || widget?.instrument_type === 'bar') && (
        <ChartType chartType={widget?.instrument_type || 'line'} setChartType={setChartType} />
      )}
      {widget?.instrument_type === 'kpi' && (
        <>
          <ModalBlock blockId={blockId} handleBlockChange={handleKPIBlockChange} />
          <ModalIndicator
            blockId={blockId}
            indicatorId={indicatorId}
            handleIndicatorChange={handleKPIIndicatorChange}
          />
        </>
      )}

      {(widget?.instrument_type === 'line' ||
        widget?.instrument_type === 'bar' ||
        widget?.instrument_type === 'table' ||
        widget?.instrument_type === 'variance') && <TableIndicator />}
      {widget?.instrument_type === 'variance' && (
        <ColumnSettingsPopover settings={columnSettings} onSettingsChange={handleSettingsChange} />
      )}
      {(widget?.instrument_type === 'line' ||
        widget?.instrument_type === 'bar' ||
        widget?.instrument_type === 'table' ||
        widget?.instrument_type === 'variance') && <DashboardModalFilters widget={widget} />}
      {((widget?.instrument_type === 'table' && selectedRow) ||
        widget?.instrument_type === 'variance') && (
        <>
          <RowFormatting
            handleTextDecoration={handleTextDecoration}
            selectedStyle={selectedRow?.formatting?.textDecoration || ''}
          />
          <RowCellColor
            selectedCellColor={selectedRow?.formatting?.cellColor || ''}
            handleCellColorChange={handleColorCellChange}
          />
          <RowTextColor
            selectedColor={selectedRow?.formatting?.textColor || ''}
            handleColorChange={handleColorChange}
          />
          <RowIndentation
            handleAlignemntChange={handleAlignemntChange}
            selectedAlignemnt={selectedRow?.formatting?.textAlign || ''}
          />
        </>
      )}
    </Flex>
  );
};

export default Setup;

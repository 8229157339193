import React from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getPlannerMode } from 'redux/PlannerModeSlice';
import { HEADER_HEIGHT } from 'projectConstants';
import BuilderMode from 'pages/BuilderMode';

import PlanPageScenario from './PlanPageScenario';

const PlanPageDetailsOPZ = () => {
  const [isLaptop]: any = useMediaQuery('(max-width: 1024px)');

  const plannerMode = useSelector(getPlannerMode);

  return (
    <Box
      height={`calc(100vh - ${HEADER_HEIGHT})`}
      bg={'gradient.body'}
      padding={isLaptop ? 4 : ''}
      overflow={'hidden'}
    >
      {plannerMode && !isLaptop ? <BuilderMode /> : <PlanPageScenario />}
    </Box>
  );
};

export default PlanPageDetailsOPZ;

import { SearchIcon } from '@chakra-ui/icons';
import {
  useDisclosure,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
} from '@chakra-ui/react';
import { FixedSizeList } from 'react-window';
import { GoChevronDown } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

import { STATUS } from 'projectConstants';
import { getDashboardBlocks, getDashboardBlockStatus } from 'redux/DashboardSliceV2';

export interface Properties {
  blockId: number;
  indicatorId: number;
  handleIndicatorChange: (indicator: any) => void;
}
const ModalIndicator = ({ blockId, indicatorId, handleIndicatorChange }: Properties) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const blockDetails = useSelector(getDashboardBlocks);
  const indicatorStatus = useSelector(getDashboardBlockStatus);
  const inputReference: any = useRef(null);
  const [listData, setListData] = useState([]);
  const [indicatorDetails, setIndicatorDetails] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const itemHeight = 30;
  const calculatedHeight = listData.length * itemHeight;
  const height = Math.min(calculatedHeight, 150);
  const width = 260;

  const selectedIndicator = useMemo(() => {
    if (indicatorId && indicatorDetails?.length) {
      const blockData: any = indicatorDetails?.find(
        (item: any) => item.indicator_id === indicatorId,
      );
      return blockData?.indicator_name;
    }
    return null;
  }, [indicatorId, indicatorDetails]);

  useEffect(() => {
    if (blockId) {
      const selectedBlock = blockDetails?.find((block: any) => block.block_id === blockId);
      setIndicatorDetails(selectedBlock?.indicators?.length ? selectedBlock?.indicators : []);
      setListData(selectedBlock?.indicators?.length ? selectedBlock?.indicators : []);
    } else {
      setListData([]);
    }
  }, [blockId, blockDetails]);

  const handleSearch = (value: string) => {
    setListData(
      indicatorDetails?.filter((item: any) =>
        item.indicator_name?.toLowerCase().includes(searchValue?.toLowerCase()),
      ),
    );
    setSearchValue(value);
  };

  const Row = ({ index, style, data }: any) => {
    const handleItemClick = () => {
      handleIndicatorChange(data[index]);
      onClose();
    };

    return (
      <Flex
        alignItems={'center'}
        borderRadius={'6px'}
        paddingInline={2}
        _hover={{ backgroundColor: '#EFF2F6' }}
        onClick={handleItemClick}
        style={style}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        cursor={'pointer'}
      >
        {data[index].indicator_name}
      </Flex>
    );
  };
  return (
    <Flex flexDir={'column'}>
      <Text color={'black.800'} fontWeight={'600'} mb={1}>
        Indicator
      </Text>
      <Menu isOpen={isOpen} onClose={onClose} strategy='fixed' isLazy variant={'primary'}>
        <MenuButton width={'100%'} onClick={isOpen ? onClose : onOpen}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            backgroundColor={'#FAFAFA'}
            border={'1px #D9D9D9 solid'}
            padding={'.5rem .8rem'}
            borderRadius={'6px'}
            color={'black.600'}
            fontSize={'2xs'}
          >
            <Text>{selectedIndicator || 'Click to select indicator'}</Text>
            <GoChevronDown size={18} />
          </Flex>
        </MenuButton>
        <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
          {blockDetails ? (
            <>
              {indicatorStatus === STATUS.LOADING ? (
                <MenuItem>
                  <Spinner size={'md'} />
                </MenuItem>
              ) : (
                <>
                  <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents='none'
                        h='100%'
                        children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                      />
                      <Input
                        width={'93%'}
                        color={'black.900'}
                        _placeholder={{ color: '#D9D9D9' }}
                        ref={inputReference}
                        placeholder='Search'
                        onChange={(event: any) => {
                          handleSearch(event.target.value);
                        }}
                      />
                    </InputGroup>
                  </Flex>
                  {indicatorDetails?.length > 0 ? (
                    <FixedSizeList
                      className='scroll'
                      height={height}
                      itemData={listData}
                      itemCount={listData?.length}
                      itemSize={itemHeight}
                      width={width}
                    >
                      {Row}
                    </FixedSizeList>
                  ) : (
                    <Text>No Indicator found</Text>
                  )}
                </>
              )}
            </>
          ) : (
            <Text>Select Block</Text>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};
export default ModalIndicator;

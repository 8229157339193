const DownloadIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.25 16.25V20.5833C26.25 21.158 25.9866 21.7091 25.5178 22.1154C25.0489 22.5217 24.413 22.75 23.75 22.75H6.25C5.58696 22.75 4.95107 22.5217 4.48223 22.1154C4.01339 21.7091 3.75 21.158 3.75 20.5833V16.25M8.75 10.8333L15 16.25M15 16.25L21.25 10.8333M15 16.25V3.25'
        stroke='#918FCA'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DownloadIcon;

import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const ResetPasswordSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('This field is required'),
});

export const SetPasswordSchema = yup.object().shape({
  password: yup.string().required(MESSAGES.REQUIRE_MESSAGE).min(6).max(20),
});

export const SignInSchema = yup.object().shape({
  username: yup.string().email(MESSAGES.EMAIL_MESSAGE).required(MESSAGES.REQUIRE_MESSAGE),
  password: yup.string().required(MESSAGES.REQUIRE_MESSAGE).min(6).max(20),
});

export const ChangePasswordSchema = yup.object().shape({
  password: yup.string().min(6).max(20).required('Password is a required field'),
  confirmPassword: yup
    .string()
    .required('Confirm password is a required field')
    .oneOf([yup.ref('password'), undefined], 'Passwords must match'),
});

export const UserDetailsFormSchema = yup.object().shape({
  firstname: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  lastname: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  job_role: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  company: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  industry: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  terms_accepted: yup.boolean().oneOf([true], 'Please select'),
});

export const SingupSchema = yup.object().shape({
  firstName: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  lastName: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  organizationName: yup
    .string()
    .matches(/\S/, MESSAGES.WHITE_SPACE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  signupEmail: yup.string().email(MESSAGES.EMAIL_MESSAGE).required(MESSAGES.REQUIRE_MESSAGE),
  isChecked: yup.boolean().oneOf([true], 'Please select'),
});

export const InvitedSingupSchema = yup.object().shape({
  firstName: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  lastName: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  organizationName: yup
    .string()
    .matches(/\S/, MESSAGES.WHITE_SPACE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  job_role: yup.string().matches(/\S/, MESSAGES.WHITE_SPACE).required(MESSAGES.REQUIRE_MESSAGE),
  signupEmail: yup.string().email(MESSAGES.EMAIL_MESSAGE).required(MESSAGES.REQUIRE_MESSAGE),
  setpassword: yup.string().required(MESSAGES.REQUIRE_MESSAGE).min(6).max(20),
  isChecked: yup.boolean().oneOf([true], 'Please select'),
});

import * as React from 'react';
import { Flex, Stack, Box, Text, Input, Checkbox, Button } from '@chakra-ui/react';
import { useEffect, useState, useMemo } from 'react';

import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';

interface Properties {
  handleClose: any;
  handleApply: any;
  clearButton?: any;
  checkboxData?: any;
  isMenuOpen?: any;
  filterItem?: any;
}

const PreviewSelectCheckBox = ({
  handleClose,
  handleApply,
  clearButton,
  checkboxData,
  isMenuOpen,
  filterItem,
}: Properties) => {
  const [searchValue, setSearchValue]: any = useState('');
  const [ischeckedItemsChecked, setCheckedItemsChecked] = useState<any>();
  const getFilteredResult = (mainData: any, filterData: any) => {
    const mainDimensionItems = mainData?.dimension[0]?.indicator_dimension_items || [];
    const filterDimensionItems = filterData[0]?.dimensionItems;
    return (
      mainDimensionItems.length > 0 &&
      mainDimensionItems &&
      mainDimensionItems?.map((item: any) => {
        const isInFilter = filterDimensionItems?.some(
          (filterItemData: any) =>
            filterItemData?.dimensionItemId === item?.indicator_dimension_item_id,
        );
        return {
          dimension_item_id: item?.indicator_dimension_item_id,
          name: item?.indicator_dimension_item_name,
          status: isInFilter,
          search: true,
        };
      })
    );
  };
  const result = useMemo(
    () => getFilteredResult(checkboxData, filterItem),
    [checkboxData, filterItem, isMenuOpen],
  );
  useEffect(() => {
    if (result) {
      setCheckedItemsChecked(result);
    }
  }, [result, isMenuOpen]);
  const allChecked =
    ischeckedItemsChecked && ischeckedItemsChecked?.every((item: any) => item.status);
  const isIndeterminate =
    ischeckedItemsChecked && ischeckedItemsChecked?.some((item: any) => item.status) && !allChecked;
  useEffect(() => {
    if (!isMenuOpen && searchValue) {
      setSearchValue('');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (clearButton) {
      setCheckedItemsChecked(
        checkboxData?.dimension_items?.map((item: any) => {
          return { name: item?.dimension_item_name, status: false, search: true };
        }),
      );
    }
  }, [clearButton]);

  useEffect(() => {
    if (searchValue) {
      const searchTerm = searchValue.toLowerCase();
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: item.name.toLowerCase().includes(searchTerm),
      }));
      setCheckedItemsChecked(updatedItems);
    } else {
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: true,
      }));
      setCheckedItemsChecked(updatedItems);
    }
  }, [searchValue]);

  const handleCheckBoxChange = (user: any, event: any) => {
    const find = ischeckedItemsChecked.findIndex((item: any) => item.name === user);
    setCheckedItemsChecked((previousState: any) => {
      const newState = [...previousState];
      newState[find].status = event.target.checked;
      return newState;
    });
  };
  const handleSelectAllChekbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItemsChecked((previousState: any) => {
      const newState = previousState?.map((item: any) => {
        return {
          ...item,
          status: event.target.checked,
        };
      });
      return newState;
    });
  };

  const handleApplyFilter = () => {
    handleApply(ischeckedItemsChecked);
  };
  const handleCloseMenu = () => {
    handleClose();
  };
  return (
    <Stack minW={'200px'} maxW={'200px'} position={'relative'}>
      <Box position={'sticky'} top={'0'} backgroundColor={'white'} zIndex={1}>
        <Flex>
          <DimensionTabIcon width={7} height={6} color={'#4BB6B9'} />
          <Text variant={'primary_sub_heading'}>{checkboxData?.dimension_name}</Text>
        </Flex>
        {ischeckedItemsChecked?.length > 0 && (
          <React.Fragment>
            <Input
              my={3}
              placeholder='Search'
              height={7}
              fontSize={'14px'}
              value={searchValue}
              onChange={(event: any) => setSearchValue(event.target.value)}
            />
          </React.Fragment>
        )}
        <Flex ms={2}>
          <Checkbox
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={handleSelectAllChekbox}
            variant={'filters'}
          >
            Select All
          </Checkbox>
        </Flex>
      </Box>
      {ischeckedItemsChecked?.length > 0 ? (
        <>
          <Stack maxH={'150px'} overflowX={'auto'} className={'filters_scroll'}>
            {ischeckedItemsChecked?.map((user: any, index: number) => (
              <React.Fragment key={index}>
                {user?.search && (
                  <Checkbox
                    pl={6}
                    variant={'filters'}
                    isChecked={ischeckedItemsChecked[index]?.status}
                    onChange={(event) => {
                      handleCheckBoxChange(user?.name, event);
                    }}
                  >
                    <Text
                      maxW={'100px'}
                      className='text-overflow'
                      size={'Roboto-14'}
                      title={user?.name}
                    >
                      {user?.name}
                    </Text>
                  </Checkbox>
                )}
              </React.Fragment>
            ))}
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Button onClick={handleCloseMenu} variant={'cancel_button'} mr={3}>
              Cancel
            </Button>
            <Button
              onClick={handleApplyFilter}
              disabled={!isIndeterminate && !allChecked}
              variant={'save_button'}
            >
              Apply
            </Button>
          </Flex>
        </>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Text>No Dimension items</Text>
        </Box>
      )}
    </Stack>
  );
};

export default PreviewSelectCheckBox;

import { Box } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo, useState, useRef } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';

import FullScreenLoader from 'components/FullScreenLoader';
import { getBlocks } from 'redux/ModelsSlice';
import {
  createDashboardID,
  fetchDashboardID,
  FetchModelBlocks,
  getDashboardBlockStatus,
  getEditInstrumentData,
  getFetchDashboardStatus,
  getSelectedDashboardScenario,
  resetDashboardSliceV2,
  selectDashboardScenario,
  fetchDashboardMetadata,
  getAllInstruments,
  createInstrument,
  updateInstrument,
  setAllInstruments,
  fetchAllDataValues,
  getAllInstrumentOutputs,
  getEditInstrumentOutput,
  setAllInstrumentOutputs,
  getDashboard,
  handleEditCancel,
  getAllInstrumentOutputsStatus,
} from 'redux/DashboardSliceV2';
import { AppDispatch } from 'utils/GlobalHelpers';
import { STATUS } from 'projectConstants';
import { getAllScenarios } from 'redux/ScenarioSlice';

import EmptyDashboard from './EmptyDashboard';
import Header from './header';
import Grid from './Grid';
import DashboardModal from './DashboardModal';
import ErrorFull from './ErrorFull';

const Dashboard = () => {
  const dispatch: AppDispatch = useDispatch();
  const allInstruments: Array<any> = useSelector(getAllInstruments);
  const allInstrumentsOutputs: Array<any> = useSelector(getAllInstrumentOutputs);
  const allInstrumentOutputsStatus = useSelector(getAllInstrumentOutputsStatus);
  const editInstrumentOutput = useSelector(getEditInstrumentOutput);
  const targetReference = useRef<any>();
  const [pdfExport, setPDFExport] = useState(false);
  const model = useSelector(getBlocks);
  const dashboardBlockStatus = useSelector(getDashboardBlockStatus);
  const dashboardStatus = useSelector(getFetchDashboardStatus);
  const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);
  const widget = useSelector(getEditInstrumentData);
  const scenarios = useSelector(getAllScenarios);
  const editData = useSelector(getEditInstrumentData);
  const dashboard = useSelector(getDashboard);

  useEffect(() => {
    return () => {
      dispatch(resetDashboardSliceV2());
    };
  }, []);

  useEffect(() => {
    if (model) {
      dispatch(FetchModelBlocks({ modelId: Number(model.id) }));
    }
  }, [model]);

  useEffect(() => {
    if (!selectedDashboardScenario && scenarios && scenarios?.length) {
      const baseScenario = scenarios.find((item: any) => item.is_base);
      dispatch(selectDashboardScenario(baseScenario));
    }
  }, [scenarios]);

  const isLoading = useMemo(
    () =>
      !model ||
      !selectedDashboardScenario ||
      dashboardBlockStatus !== STATUS.SUCCESS ||
      dashboardStatus !== STATUS.SUCCESS,
    [model, dashboardBlockStatus, dashboardStatus, selectedDashboardScenario],
  );

  const handleCancel = () => {
    dispatch(handleEditCancel());
  };

  const handleCreate = () => {
    if (editData.id) {
      dispatch(
        updateInstrument({ request_body: { ...editData, save: true, data_values: false } }),
      ).then((result) => {
        const foundIndex = allInstruments.findIndex(
          (instrument: any) => instrument.id === editData.id,
        );
        if (foundIndex > -1) {
          const list = JSON.parse(JSON.stringify(allInstruments));
          list[foundIndex] = result.payload;
          dispatch(setAllInstruments([...list]));
          dispatch(
            setAllInstrumentOutputs({
              ...allInstrumentsOutputs,
              [result.payload.id]: { ...editInstrumentOutput },
            }),
          );
        }
        handleCancel();
      });
    } else {
      dispatch(
        createInstrument({ request_body: { ...editData, save: true, data_values: false } }),
      ).then((result: any) => {
        dispatch(setAllInstruments([...allInstruments, { ...result.payload }]));
        dispatch(
          setAllInstrumentOutputs({
            ...allInstrumentsOutputs,
            [result.payload.id]: { ...editInstrumentOutput, id: result.payload.id },
          }),
        );
        handleCancel();
      });
    }
    dispatch(setAllInstruments([...allInstruments]));
  };

  const dashboardMetadata = (dashboardId: string) => {
    dispatch(fetchDashboardMetadata({ dashboardId }));
  };

  const fetchDashboardData = (chartId: any, scenarioId: any) => {
    dashboardMetadata(chartId);
    dispatch(
      fetchAllDataValues({
        chartId,
        scenarioId,
      }),
    );
  };

  useEffect(() => {
    if (model && selectedDashboardScenario && !dashboard?.id) {
      const yr = new Date(model?.time_properties?.starting_period).getUTCFullYear().toString();
      const fiscalStart = `${model?.time_properties?.fy_start_month}-${yr[2]}${yr[3]}`;
      dispatch(fetchDashboardID({ model_id: model.id })).then((response) => {
        if (!response.payload?.id) {
          dispatch(
            createDashboardID({
              request_body: {
                name: 'string',
                model_id: Number(model.id),
                fiscal_start_month:
                  model?.time_properties?.time_granularity === 'M'
                    ? fiscalStart
                    : `Jan-${yr[2]}${yr[3]}`,
                description: 'string',
              },
            }),
          ).then((result: any) => {
            fetchDashboardData(result.payload?.id, selectedDashboardScenario?.id);
          });
        } else {
          fetchDashboardData(response.payload?.id, selectedDashboardScenario?.id);
        }
      });
    }
  }, [model, selectedDashboardScenario, dashboard]);

  const onExport = () => {
    setPDFExport(true);
    try {
      if (targetReference.current) {
        targetReference.current.save();
        setPDFExport(false);
      }
    } catch {
      setPDFExport(false);
      alert('Export Failed  !!!');
    }
  };
  const refetchAllInstruments = () => {
    if (dashboard?.id && selectedDashboardScenario?.id) {
      dashboardMetadata(dashboard?.id);
      dispatch(
        fetchAllDataValues({
          chartId: dashboard?.id,
          scenarioId: selectedDashboardScenario?.id,
        }),
      );
    }
  };

  if (isLoading) {
    return (
      <Box height='100%' width='100%' p={1}>
        <FullScreenLoader
          style={{ position: 'absolute' }}
          height='100%'
          width='calc(100vw - 4.5rem)'
        />
      </Box>
    );
  }

  return (
    <Box height={'100%'}>
      <Header
        onExport={onExport}
        pdfExport={pdfExport}
        refetchAllInstruments={refetchAllInstruments}
      />
      {allInstrumentOutputsStatus === STATUS.FAILED ? (
        <ErrorFull onRetry={refetchAllInstruments} />
      ) : (
        <Box className='scroll' mt={2} mx={'-20px'}>
          <PDFExport
            paperSize='auto'
            margin='0.5cm'
            ref={targetReference}
            fileName={`${model?.name}_DashboardExport.pdf`}
          >
            {allInstruments?.length ? <Grid allInstruments={allInstruments} /> : <EmptyDashboard />}
          </PDFExport>
        </Box>
      )}

      {widget?.instrument_type && (
        <DashboardModal handleCancel={handleCancel} handleCreate={handleCreate} />
      )}
    </Box>
  );
};

export default Dashboard;

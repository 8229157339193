import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NewTotalValues } from 'models/response/blockOutput.interface';
import { getBlocks } from 'redux/ModelsSlice';
import {
  createInstrument,
  getAllInstrumentOutputs,
  getAllInstruments,
  getEditInstrumentData,
  getInstrumentExpandKey,
  getSelectedDashboardScenario,
  setAllInstrumentOutputs,
  setAllInstruments,
  setEditInstrumentData,
  setEditInstrumentOutput,
  setInstrumentExpandKey,
  updateInstrument,
} from 'redux/DashboardSliceV2';
import { AppDispatch, getRandomColor } from 'utils/GlobalHelpers';

import DashboardTable from './DashboardTable';

interface DashboardTableWrapperProperties {
  widget: any;
  isDashboardLayout?: boolean;
}

const DashboardTableWrapper = ({ widget, isDashboardLayout }: DashboardTableWrapperProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const model = useSelector(getBlocks);
  const allInstruments = useSelector(getAllInstruments);
  const [expandKey, setExpandKey] = useState('');
  const [widgetData, setWidgetData] = useState<any>(null);
  const [tableData, setTableData] = useState<Array<any> | null>(null);
  const [tableHeaderData, setTableHeaderData] = useState<Array<any> | null>(null);
  const allInstrumentsOutputs = useSelector(getAllInstrumentOutputs);
  const allInstrumentExpandKey = useSelector(getInstrumentExpandKey);
  const [fiscalYearMapping, setFiscalYearMapping] = useState<any>({});
  const editInstrumentData = useSelector(getEditInstrumentData);
  const timeGranularity =
    widget?.time_granularity === 'month' ? 'M' : widget?.time_granularity === 'quarter' ? 'Q' : 'Y';
  const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);

  useEffect(() => {
    if (allInstrumentExpandKey[widget?.id]) {
      setExpandKey(allInstrumentExpandKey[widget?.id]);
    }
  }, [allInstrumentExpandKey, widget]);

  useEffect(() => {
    if (widget?.id && allInstrumentsOutputs && isDashboardLayout) {
      const value = allInstrumentsOutputs?.[widget?.id]?.data;
      setFiscalYearMapping(allInstrumentsOutputs?.[widget?.id]?.time_granularity);
      if (value) {
        setWidgetData(value);
      }
    } else {
      const value = widget?.data;
      setFiscalYearMapping(widget?.timeGranularity);
      if (value) {
        setWidgetData(value);
      }
    }
  }, [widget, allInstrumentsOutputs]);

  const handleTimeGranulirity = (value: any) => {
    const editData = JSON.parse(JSON.stringify(widget));
    const newTimeGranularity =
      value === 'M' || value === 'm'
        ? 'month'
        : value === 'Q' || value === 'q'
        ? 'quarter'
        : 'year';
    if (editInstrumentData?.instrument_type) {
      const newData = JSON.parse(JSON.stringify(editInstrumentData));
      newData.time_granularity = newTimeGranularity;
      dispatch(setEditInstrumentData(newData));
    }
    if (editData.id && !editInstrumentData?.instrument_type) {
      const foundIndex = allInstruments.findIndex(
        (instrument: any) => instrument.id === editData.id,
      );
      if (foundIndex > -1) {
        const list = JSON.parse(JSON.stringify(allInstruments));
        list[foundIndex].time_granularity = newTimeGranularity;
        dispatch(setAllInstruments([...list]));
        dispatch(
          updateInstrument({
            request_body: { ...list[foundIndex], save: true, data_values: false },
          }),
        );
      }
    }
  };

  const formatTableData = () => {
    if (widgetData) {
      const indicatorTableOutput = JSON.parse(JSON.stringify(widgetData));

      let keysToAdd: string[] = [];
      if (indicatorTableOutput && indicatorTableOutput.length > 0) {
        if (expandKey) {
          if (timeGranularity === 'Y') {
            const year = expandKey;
            for (let index = 0; index < Object.values(fiscalYearMapping[year]).length; index += 1) {
              const quarter: any = Object.values(fiscalYearMapping[year])[index];
              keysToAdd = keysToAdd.concat(quarter);
            }
          } else if (timeGranularity === 'Q') {
            const year = expandKey.split('-')[1];
            const quarter: string = expandKey;

            keysToAdd = fiscalYearMapping[year][quarter].map((month: string) => {
              return `${month}`;
            });
          }
        }
        const tableDataArray = [];
        for (let index = 0; index < indicatorTableOutput?.length; index += 1) {
          const data = indicatorTableOutput[index];

          let filteredKeys: any = [];
          if (data?.dim_df && data?.dim_df.length > 0) {
            const items = (data.dim_df as NewTotalValues[]).map((item: any) => {
              if (data?.dim_df) {
                const itemKeys = (item.values as NewTotalValues[])?.map((subItem: any) => {
                  let isShow = false;
                  let isExpandedColumn = false;
                  if (timeGranularity === 'Q' && subItem.Time[0] === 'Q') {
                    isShow = true;
                  } else if (timeGranularity === 'Y' && subItem.Time.length === 4) {
                    isShow = true;
                  } else if (subItem.Time.length === 6 && timeGranularity === 'M') {
                    isShow = true;
                  } else {
                    const check = keysToAdd.includes(subItem.Time);
                    isShow = keysToAdd && keysToAdd.length > 0 && check;
                    if (isShow) isExpandedColumn = true;
                  }

                  Object.assign(subItem, { isShow, isExpandedColumn });

                  return subItem;
                });
                data.value = itemKeys;
              }
              return item;
            });

            data.dim_df = items;
          }
          const timeAggregation = model?.time_properties?.time_granularity
            ? (model?.time_properties?.time_granularity as string).toUpperCase()
            : 'M';
          if (data?.total && data?.total.length) {
            filteredKeys = (data.total as NewTotalValues[]).map((item: any) => {
              let isShow = false;
              let isExpandedColumn = false;
              let isShowExpandable = false;
              if (timeGranularity === 'Q' && item.Time[0] === 'Q') {
                isShow = true;
                isShowExpandable = true;
              } else if (timeGranularity === 'Y' && item.Time.length === 4) {
                isShow = true;
                isShowExpandable = true;
              } else if (item.Time.length === 6 && timeGranularity === 'M') {
                isShow = true;
              } else {
                const check = keysToAdd.includes(item.Time);
                isShow = keysToAdd && keysToAdd.length > 0 && check;
                if (isShow) isExpandedColumn = true;
              }

              if (timeAggregation === 'Y') isShowExpandable = false;

              Object.assign(item, { isShow, isShowExpandable, isExpandedColumn });
              return item;
            });
          }

          data.total = filteredKeys;
          if (index === 0) {
            setTableHeaderData(filteredKeys);
          }

          tableDataArray.push(data);
        }
        setTableData(tableDataArray);
      } else {
        setTableData(null);
        setTableHeaderData(null);
      }
    }
  };

  const handleExpandKey = (key: string) => {
    dispatch(setInstrumentExpandKey({ ...allInstrumentExpandKey, [widget?.id]: key }));
    setExpandKey(key);
  };

  const setTimeGranularity = (value: string) => {
    handleExpandKey('');
    handleTimeGranulirity(value);
  };

  useEffect(() => {
    if (widgetData && timeGranularity) {
      formatTableData();
    }
  }, [widgetData, timeGranularity, expandKey]);

  const handleApplyFilter = (filterAppliedData: any, filterItem: any) => {
    const editData = JSON.parse(JSON.stringify(widget));

    const matchingIndicatorIndex = editData?.instrument_indicators?.findIndex(
      (indicator: any) => indicator?.dimension_id === filterItem[0]?.dimensionId,
    );
    if (matchingIndicatorIndex > -1 && selectedDashboardScenario && filterAppliedData?.length) {
      const matchingIndicator = editData?.instrument_indicators[matchingIndicatorIndex];
      // Create a new object with filtered dimensionItems
      const updatedIndicator = {
        ...matchingIndicator,
        formatting: {
          ...matchingIndicator.formatting,
          dimensionItems: filterAppliedData
            .filter((item: any) => item.status)
            .map((item: any) => {
              return {
                dimension_item_id: item.dimension_item_id,
                dimension_item_name: item.name,
                color: getRandomColor(),
              };
            }),
        },
      };
      if (editInstrumentData?.instrument_type) {
        if (!editData?.id) {
          const newData = JSON.parse(JSON.stringify(editData));
          newData.instrument_indicators[matchingIndicatorIndex] = updatedIndicator;
          dispatch(
            createInstrument({
              request_body: {
                ...newData,
                scenario_id: selectedDashboardScenario?.id
                  ? `${selectedDashboardScenario?.id}`
                  : '',
                save: false,
                data_values: true,
              },
            }),
          ).then((result: any) => {
            dispatch(setEditInstrumentOutput(result?.payload?.data));
            dispatch(
              setEditInstrumentData({
                ...widget,
                instrument_indicators: result?.payload?.instrument_indicators,
              }),
            );
          });
        } else {
          const newData = JSON.parse(JSON.stringify(editData));
          newData.instrument_indicators[matchingIndicatorIndex] = updatedIndicator;
          dispatch(
            updateInstrument({
              request_body: {
                ...newData,
                scenario_id: selectedDashboardScenario?.id
                  ? `${selectedDashboardScenario?.id}`
                  : '',
                save: false,
                data_values: true,
              },
            }),
          ).then((result: any) => {
            dispatch(setEditInstrumentOutput(result?.payload?.data));
            dispatch(
              setEditInstrumentData({
                ...widget,
                instrument_indicators: result?.payload?.instrument_indicators,
              }),
            );
          });
        }
      } else {
        const foundIndex = allInstruments.findIndex(
          (instrument: any) => instrument.id === widget.id,
        );
        if (foundIndex > -1) {
          const list = JSON.parse(JSON.stringify(allInstruments));
          list[foundIndex].instrument_indicators[matchingIndicatorIndex] = updatedIndicator;
          dispatch(setAllInstruments([...list]));
          dispatch(
            updateInstrument({
              request_body: { ...list[foundIndex], save: true, data_values: true },
            }),
          ).then((result) => {
            dispatch(
              setAllInstrumentOutputs({
                ...allInstrumentsOutputs,
                [result.payload.id]: { ...result.payload },
              }),
            );
          });
        }
      }
    }
  };

  const isDimensionFilter = useMemo(() => {
    const filterData = widget?.instrument_indicators?.filter((indicator: any) => {
      return (
        indicator?.formatting &&
        Array.isArray(indicator?.formatting?.dimensionItems) &&
        indicator?.formatting?.dimensionItems?.length > 1 &&
        indicator?.dimension_id !== null &&
        indicator?.dimension_id !== ''
      );
    });
    return filterData || false;
  }, [widget?.instrument_indicators]);

  const tableFormattedData: any = {
    tableData,
    timeGranularity,
    expandkey: expandKey,
    tableHeaderData,
    isDashboardLayout,
    setExpandKey: handleExpandKey,
    setTimeGranularity,
    isDimensionFilter,
    output: widget,
    handleApplyFilter,
  };
  console.log('=>', editInstrumentData, expandKey, timeGranularity);
  return <DashboardTable {...tableFormattedData} />;
};
export default DashboardTableWrapper;

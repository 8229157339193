import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  Flex,
  Spinner,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useState } from 'react';

import Checkbox from 'components/Checkbox';
import Pencil from 'components/Icons/Pencil';

import EditTitle from './EditTitle';

interface ColumnSettings {
  columnName: string;
  showPY: boolean;
  varianceToPY: boolean;
  variancePercentPY: boolean;
  showPL: boolean;
  varianceToPL: boolean;
  variancePercentPL: boolean;
  scenario: string;
}

interface ColumnSettingsProperties {
  settings: ColumnSettings;
  loading?: boolean;
  onSettingsChange: (updatedSettings: Partial<ColumnSettings>) => void;
}

const ColumnSettingsPopover = ({
  settings,
  loading = false,
  onSettingsChange,
}: ColumnSettingsProperties) => {
  const [localSettings, setLocalSettings] = useState<ColumnSettings>({ ...settings });

  const handleChange = (key: keyof ColumnSettings, value: any) => {
    if (loading) return; // Prevent updates when loading is true
    const updatedSettings: ColumnSettings = { ...localSettings, [key]: value };
    setLocalSettings(updatedSettings);
    // onSettingsChange(updatedSettings);
  };

  return (
    <Popover placement='left' onClose={() => onSettingsChange(localSettings)}>
      <Flex
        w='fill-available'
        flexGrow={1}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Text color={'black.800'} fontWeight={'600'}>
          Columns
        </Text>
        <PopoverTrigger>
          <Box px='4' borderRadius='sm'>
            <Pencil color='#787878' />
          </Box>
        </PopoverTrigger>
      </Flex>

      <PopoverContent bg='white' p={2} borderRadius='sm' boxShadow='lg' maxW='md'>
        <PopoverArrow />
        <PopoverHeader fontWeight='bold' color='#6562CF' fontSize={'md'}>
          Column Settings
        </PopoverHeader>
        <PopoverBody>
          {loading ? (
            <Flex justify='center' align='center' height='100px'>
              <Spinner size='lg' color='purple.500' />
            </Flex>
          ) : (
            <Stack spacing={3}>
              {/* Primary Column Name */}
              <Box>
                <Text color={'black'} mb={3} fontSize='sm'>
                  Primary column name
                </Text>
                <EditTitle
                  showLabel={false}
                  title={localSettings.columnName}
                  setTitle={(event: any) => handleChange('columnName', event.target.value)}
                  placeholder='Leave empty to use AC / FC'
                />
              </Box>

              {/* Previous Year Section */}
              <Box>
                <Text color={'black'} mb={3} fontSize='sm'>
                  Show Previous Year (PY)
                </Text>
                <Stack spacing={3}>
                  <Checkbox
                    size='lg'
                    isChecked={localSettings.showPY}
                    onChange={(event: any) => handleChange('showPY', event.target.checked)}
                    label={
                      <Text
                        variant='secondary'
                        fontFamily='regular_roboto'
                        fontSize='xs'
                        color={'black'}
                      >
                        Previous Year (PY)
                      </Text>
                    }
                  />
                  <Checkbox
                    size='lg'
                    isChecked={localSettings.varianceToPY}
                    onChange={(event: any) => handleChange('varianceToPY', event.target.checked)}
                    label={
                      <Text
                        variant='secondary'
                        fontFamily='regular_roboto'
                        fontSize='xs'
                        color={'black'}
                      >
                        Variance to PY
                      </Text>
                    }
                  />
                  <Checkbox
                    size='lg'
                    isChecked={localSettings.variancePercentPY}
                    onChange={(event: any) =>
                      handleChange('variancePercentPY', event.target.checked)
                    }
                    label={
                      <Text
                        variant='secondary'
                        fontFamily='regular_roboto'
                        fontSize='xs'
                        color={'black'}
                      >
                        Variance % to PY
                      </Text>
                    }
                  />
                </Stack>
              </Box>

              <Box>
                <Text color={'black'} mb={3} fontSize='sm'>
                  Show Plan (PL) Comparison
                </Text>

                <Flex mb={3} alignContent={'center'} justifyContent={'space-between'}>
                  <Text color={'black'} fontSize='sm'>
                    Scenario
                  </Text>
                  <Menu>
                    <MenuButton as={Button} width='100%' isDisabled={loading}>
                      <Flex
                        justifyContent={'space-between'}
                        backgroundColor={'#FAFAFA'}
                        border={'1px #D9D9D9 solid'}
                        padding={'.5rem .8rem'}
                        borderRadius={'6px'}
                        color={'black.600'}
                        fontSize={'2xs'}
                      >
                        <Text>
                          {localSettings.scenario === 'budget-2024'
                            ? 'Budget 2024'
                            : 'Forecast 2024'}
                        </Text>
                        <GoChevronDown size={18} />
                      </Flex>
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => handleChange('scenario', 'budget-2024')}>
                        Budget 2024
                      </MenuItem>
                      <MenuItem onClick={() => handleChange('scenario', 'forecast-2024')}>
                        Forecast 2024
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>

                <Box mb={5} w='full'>
                  <EditTitle
                    showLabel={false}
                    title={localSettings.columnName}
                    setTitle={(event: any) => handleChange('columnName', event.target.value)}
                    placeholder='Custom column name'
                  />
                </Box>
                <Stack spacing={3} mt={3}>
                  <Checkbox
                    size='lg'
                    isChecked={localSettings.showPL}
                    onChange={(event: any) => handleChange('showPL', event.target.checked)}
                    label={
                      <Text
                        variant='secondary'
                        fontFamily='regular_roboto'
                        fontSize='xs'
                        color={'black'}
                      >
                        Plan (PL)
                      </Text>
                    }
                  />
                  <Checkbox
                    size='lg'
                    isChecked={localSettings.varianceToPL}
                    onChange={(event: any) => handleChange('varianceToPL', event.target.checked)}
                    label={
                      <Text
                        variant='secondary'
                        fontFamily='regular_roboto'
                        fontSize='xs'
                        color={'black'}
                      >
                        Variance to PL
                      </Text>
                    }
                  />
                  <Checkbox
                    size='lg'
                    isChecked={localSettings.variancePercentPL}
                    onChange={(event: any) =>
                      handleChange('variancePercentPL', event.target.checked)
                    }
                    label={
                      <Text
                        variant='secondary'
                        fontFamily='regular_roboto'
                        fontSize='xs'
                        color={'black'}
                      >
                        Variance % to PL
                      </Text>
                    }
                  />
                </Stack>
              </Box>
            </Stack>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ColumnSettingsPopover;

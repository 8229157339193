import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

export interface FormatProperties {
  selectedCellColor: string;
  handleCellColorChange: (event: any) => void;
}

const RowCellColor = ({ handleCellColorChange, selectedCellColor }: FormatProperties) => {
  return (
    <Flex alignItems={'center'} mb={2} justifyContent={'space-between'}>
      <Text color={'black.800'} fontWeight={'600'}>
        Cell colour
      </Text>
      <Flex alignItems='center' justifyContent={'center'}>
        <input
          type='color'
          value={selectedCellColor}
          onChange={(event) => handleCellColorChange(event.target.value)}
          style={{
            width: '24px',
            height: '24px',
            border: '1px solid #D9D9D9',
            borderRadius: '2px',
            backgroundColor: 'none',
          }}
        />
        <ChevronDownIcon />
      </Flex>
    </Flex>
  );
};
export default RowCellColor;

import {
  Box,
  Divider,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';

import ArrowLeftTable from 'components/Icons/ArrowLeftTable';
import ArrowRightTable from 'components/Icons/ArrowRightTable';
import ExportIcon from 'components/Icons/Export';
import LightCalender from 'components/Icons/LightCalender';
import Search from 'components/Icons/Search';
import { useAuthContext } from 'context/authContext';
import { ExportDataRequestBody } from 'models/requestBody/createExportData.interface';
import {
  analysisOutput,
  chartOutputStatus,
  getAppliedFilters,
  getBlockMetadata,
  getDimension,
  getExpandKey,
  getModelDetails,
  getTableData,
  getTableFilter,
  getTableHeaderData,
  handleSidePanel,
  overallIndicatorFilter,
  setExpandKey,
  setTableFilter,
} from 'redux/PlanPageSlice';
import { getBaseScenario, setCompareTableFilter } from 'redux/ScenarioSlice';
import apiService from 'services';
import sizes from 'styles/theme/sizes';
import saveFile from 'utils/file';
import { AppDispatch } from 'utils/GlobalHelpers';
import { STATUS } from 'projectConstants';
import MaximizeIcon from 'components/Icons/MaximizeIcon';

import TabsFilter from './Filters/TabsFilter';
import IndicatorRow from './IndicatorRow';

export interface Properties {
  onToggleAuthModal: () => void;
  setIsFullTable?: ((value: boolean) => void) | undefined;
  isFullTable?: boolean;
}

const IndicatorTable = ({ onToggleAuthModal, setIsFullTable, isFullTable }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 575px)');
  const [isTablet] = useMediaQuery('(max-width: 1023px)');
  const { blockId } = useParams();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const modelDetail = useSelector(getModelDetails);
  const tableFilter = useSelector(getTableFilter);
  const metaDataResponse = useSelector(getBlockMetadata);
  const dimension = useSelector(getDimension);
  const tableData = useSelector(getTableData);
  const expandkey = useSelector(getExpandKey);
  const filter = useSelector(overallIndicatorFilter);
  const baseScenario = useSelector(getBaseScenario);
  const appliedFilters = useSelector(getAppliedFilters);
  const isLoading = !tableData;
  const tableHeaderData = useSelector(getTableHeaderData);
  const [actualTableHeaders, setActualTableHeaders] = useState<Array<any>>([]);
  const [actualTableData, setActualTableData] = useState<Array<any>>([]);
  const [dataHadActualValue, setDataHadActualValue] = useState<boolean>(false);
  const chartStatus = useSelector(chartOutputStatus);
  const tabsData: any = useSelector(analysisOutput);

  const startDateObject = new Date(baseScenario?.start_date);

  startDateObject.setMonth(startDateObject.getMonth() - 1);
  const yearFormat = `FY${startDateObject.getFullYear().toString().slice(-2)}`;

  const quarterFormat = `Q${Math.ceil((startDateObject.getMonth() + 1) / 3)}-${String(
    yearFormat,
  ).slice(-2)}`;

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthFormat = `${monthNames[startDateObject.getMonth()]}-${String(yearFormat).slice(-2)}`;

  const targetDate = () => {
    if (tableFilter === 'M') {
      return monthFormat;
    }
    if (tableFilter === 'Q') {
      return quarterFormat;
    }
    return yearFormat;
  };

  useEffect(() => {
    if (tableHeaderData) {
      const actualDate = targetDate();
      const actualTableHeaderNew = [];
      let afterFlag = false;
      setDataHadActualValue(false);
      for (let index = tableHeaderData.length - 1; index >= 0; index -= 1) {
        const item = JSON.parse(JSON.stringify(tableHeaderData[index]));
        if (item.name === `${actualDate}`) {
          afterFlag = true;
          setDataHadActualValue(true);
        }
        item.isActualValue = afterFlag;
        actualTableHeaderNew.push(item);
      }
      setActualTableHeaders(actualTableHeaderNew.reverse());
    }
  }, [tableFilter, tableHeaderData]);

  const dimensionFilters = (payload: any) => {
    const dimensionTableData = payload || [];
    if (
      appliedFilters?.length > 0 &&
      dimensionTableData?.length > 0 &&
      dimensionTableData?.[0]?.dims?.[0]
    ) {
      const hasDims = dimensionTableData[0]?.dims[0]?.name;
      const dimensionIndex = appliedFilters?.findIndex((data: any) => {
        const object = Object.keys(data);
        return object[0] === hasDims;
      });
      const results = dimensionTableData?.map((data: any) => {
        if (data?.dims && data?.dims?.length && hasDims && appliedFilters[dimensionIndex]) {
          const filteredData = data?.dim_item_data?.filter((item: any) => {
            return appliedFilters[dimensionIndex][hasDims]?.includes(item?.name?.trim());
          });
          return { ...data, dim_item_data: filteredData };
        }
        return data;
      });
      setActualTableData(results);
    } else {
      setActualTableData(payload);
    }
  };
  const scrollReference = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (scrollReference.current) {
      const scrollContainer = scrollReference.current;
      const targetIndex = actualTableHeaders.findIndex((header) => !header.isActualValue) - 1;
      if (targetIndex !== -1) {
        const targetElement = scrollContainer.querySelector(
          `th[data-index="${targetIndex}"]`,
        ) as HTMLElement;
        if (targetElement) {
          const { offsetLeft, offsetTop } = targetElement;
          scrollContainer.scrollTo({
            top: offsetTop - 250,
            left: offsetLeft - 250,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [actualTableHeaders]);

  useEffect(() => {
    if (tableData && metaDataResponse) {
      const actualTableDataNew = [];
      const actualDate = targetDate();
      // eslint-disable-next-line unicorn/no-for-loop
      for (let index = 0; index < tableData.length; index += 1) {
        const item = JSON.parse(JSON.stringify(tableData[index]));
        const totalValues = [];
        let afterFlag = false;
        for (let indexOne = item.total_values.length - 1; indexOne >= 0; indexOne -= 1) {
          const itemValue = JSON.parse(JSON.stringify(item.total_values[indexOne]));
          if (itemValue.name === `${actualDate}`) {
            afterFlag = true;
          }
          itemValue.isActualValue = afterFlag;
          totalValues.push(itemValue);
        }
        item.total_values = totalValues.reverse();
        item.dim_item_data = item.dim_item_data?.map((dimValue: any) => {
          const dimValues = [];
          let flagNew = false;

          for (let indexTwo = dimValue.value.length - 1; indexTwo >= 0; indexTwo -= 1) {
            const itemValue = JSON.parse(JSON.stringify(dimValue.value[indexTwo]));
            if (itemValue.name === `${actualDate}`) {
              flagNew = true;
            }
            itemValue.isActualValue = flagNew;
            dimValues.push(itemValue);
          }

          dimValue.value = dimValues.reverse();
          return dimValue;
        });
        const data = metaDataResponse?.indicators
          ?.filter((values: any) => values?.id === item?.id)
          .map((values: any) => ({
            prop: values?.format_properties,
            name: values?.name,
            output_format: values?.output_format,
            data_format: values?.data_format,
          }));
        item.format_properties = data[0].prop;
        item.name = data[0].name;
        item.output_format = data[0].output_format;
        item.data_format = data[0]?.data_format;
        actualTableDataNew.push(item);
      }
      dimensionFilters(actualTableDataNew);
    }
  }, [tableFilter, tableData, metaDataResponse?.indicators]);

  useEffect(() => {
    dispatch(setCompareTableFilter(tableFilter));
  }, [tableFilter]);

  const timeAggregation = modelDetail?.time_properties?.time_granularity
    ? (modelDetail?.time_properties?.time_granularity as String).toUpperCase()
    : 'M';
  const onChangeSummeryMode = (value: string) => {
    dispatch(setTableFilter(value));
  };

  const expand = (value: any) => {
    if (value.isShowExpandable && timeAggregation !== 'Y') {
      if (expandkey !== value.name && (tableFilter === 'Y' || tableFilter === 'Q')) {
        dispatch(setExpandKey(value.name));
      } else {
        dispatch(setExpandKey(''));
      }
    }
  };

  const requestBody: ExportDataRequestBody = {
    orient: 'table',
    query_params: {
      rows: [{ name: 'Indicators', filter }, { name: dimension?.name || null }],
      columns: [{ name: 'Time', display_levels: [tableFilter] }],
      filters: [],
    },
    scenario_id: baseScenario?.id,
  };

  const { refetch: fetchExportData } = useQuery(
    ['export', blockId, baseScenario?.id],
    () => apiService.create_block_export({ block_id: Number(blockId), request_body: requestBody }),
    {
      enabled: false,
      onError: (response: any) => {
        if (response.response.data.message) {
          navigate('/error');
        }
      },
    },
  );

  const saveResponseToFile = async () => {
    if (user?.is_anonymous) {
      onToggleAuthModal();
    } else {
      const { data: exportData } = await fetchExportData();
      if (exportData) {
        const fileName = `Blox-${blockId}.xlsx`;
        const blob = [exportData.data];
        const contentType =
          exportData.headers?.['content-type'] ??
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        saveFile(fileName, blob, contentType);
      }
    }
  };

  if (isLoading) {
    return <div data-testid='loading-test'></div>;
  }
  if ((tabsData === null || tabsData?.length === 0) && chartStatus === STATUS.FAILED) {
    return <></>;
  }

  return (
    <>
      {actualTableHeaders && (
        <Box
          mt={{ base: 0, md: 0 }}
          display='flex'
          justifyContent='space-between'
          mb={{ base: sizes['1'] }}
          alignItems='center'
        >
          <Flex alignItems={'center'}>
            <Search />
            <Text size='modalContent' ml={2}>
              Expand the table to drill into the values for each of your items
            </Text>
          </Flex>
          <Flex display='flex' alignItems='center'>
            {!isFullTable && (
              <Box
                pe={2}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                onClick={() => {
                  setIsFullTable?.(true);
                  dispatch(handleSidePanel());
                }}
                cursor={'pointer'}
              >
                <MaximizeIcon />
              </Box>
            )}
            <Box pe={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <LightCalender />
            </Box>
            {timeAggregation !== 'Y' && (
              <TabsFilter
                fontColor={'#D0CFE7'}
                bg={'#F7F7F815'}
                color='white'
                timeGranularity={tableFilter}
                onChangeSummeryMode={onChangeSummeryMode}
              />
            )}
            <Divider
              orientation='vertical'
              colorScheme='#D0CFE7'
              variant='solid'
              borderWidth='0.1rem'
              borderColor='rgba(208, 207, 231, 1)'
              height={'0.8rem'}
              ms={'0.5rem'}
            />
            <IconButton
              aria-label='export'
              variant='unstyled'
              mr={0}
              width='fit-content'
              display='flex'
              justifyContent='center'
              alignItems={'center'}
              onClick={saveResponseToFile}
              data-testid='Download-icon'
            >
              <ExportIcon />
            </IconButton>
            {isFullTable && (
              <Box
                onClick={() => {
                  setIsFullTable?.(false);
                }}
                cursor={'pointer'}
              >
                <CloseIcon width={3} height={3} color={'#8B89B4'} />
              </Box>
            )}
          </Flex>
        </Box>
      )}
      {actualTableHeaders && (
        <Box borderRadius='10px' overflowY='auto' overflowX='auto'>
          <TableContainer
            maxH={isFullTable ? '100%' : '229px'}
            overflowY='auto'
            overflowX='auto'
            mb={{ base: 4.75, md: 0 }}
            mt={{ base: 0, md: 0 }}
            borderRadius='10px'
            className={`${isMobile || isTablet ? '' : 'table_scroll'} indicator-table`}
            ref={scrollReference}
          >
            <Table variant='base'>
              <Thead height={'9'} zIndex={0}>
                <Tr>
                  <Th background={dataHadActualValue ? '#4AB7BF !important' : ''} />
                  {actualTableHeaders?.map(
                    (date: any, index: number) =>
                      date.isShow && (
                        <Th
                          data-index={index}
                          background={date?.isActualValue ? '#4AB7BF' : ''}
                          fontFamily={'regular_roboto'}
                          fontSize={'xs'}
                          fontWeight={500}
                          textTransform='none'
                          style={{
                            cursor: date.isShowExpandable ? 'pointer' : 'default',
                            // paddingRight: '1rem',
                          }}
                          onClick={() => {
                            expand(date);
                          }}
                          key={index}
                          justifyContent='space-around'
                          textAlign={'center'}
                        >
                          {date.isShowExpandable}
                          {date.isShowExpandable &&
                            (expandkey === date.name ? <ArrowRightTable /> : <ArrowLeftTable />)}
                          {date.name}
                        </Th>
                      ),
                  )}
                </Tr>
              </Thead>

              {actualTableData && (
                <Tbody>
                  {actualTableData?.map((row: any, index: number) => (
                    <React.Fragment key={index}>
                      <IndicatorRow key={row.id} row={row} expandkey={expandkey} />
                    </React.Fragment>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};
export default IndicatorTable;

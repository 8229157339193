import { Button, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { STATUS } from 'projectConstants';
import { getCalculationLoadingStatus, getEditInstrumentData } from 'redux/DashboardSliceV2';

interface Properties {
  handleCreate: () => void;
  handleCancel: () => void;
}

const DashboardModalButtons = ({ handleCreate, handleCancel }: Properties) => {
  const isLoading = useSelector(getCalculationLoadingStatus);
  const editInstrumentData = useSelector(getEditInstrumentData);
  return (
    <Flex bgColor={'white'} justifyContent={'end'} minH={'45px'} p={'0 1rem 1rem 0'} gap={4}>
      <Button variant={'save_button'} onClick={handleCancel}>
        Cancel
      </Button>

      <Button
        variant={'save_button'}
        onClick={handleCreate}
        disabled={
          !(isLoading === STATUS.SUCCESS || isLoading === STATUS.IDLE) ||
          !editInstrumentData ||
          !editInstrumentData?.instrument_indicators?.length
        }
      >
        Save
      </Button>
    </Flex>
  );
};

export default DashboardModalButtons;

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginLeft?: string;
}

const DashboardIcon = ({ width = 24, height = 24, color = '#4BB6B9' }: Properties) => {
  return (
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width={width} height={height}>
      <path
        d='M0 0 C6.6 0 13.2 0 20 0 C20 4.62 20 9.24 20 14 C20.66 14 21.32 14 22 14 C22 14.66 22 15.32 22 16 C14.08 16 6.16 16 -2 16 C-2 15.34 -2 14.68 -2 14 C-1.34 14 -0.68 14 0 14 C0 9.38 0 4.76 0 0 Z M2 2 C2 5.3 2 8.6 2 12 C7.28 12 12.56 12 18 12 C18 8.7 18 5.4 18 2 C12.72 2 7.44 2 2 2 Z '
        fill={color}
        fillOpacity={color === 'white' ? '0.5' : '1'}
        transform='translate(2,4)'
      />
      <path
        d='M0 0 C0.66 0 1.32 0 2 0 C2 1.98 2 3.96 2 6 C1.34 6 0.68 6 0 6 C0 4.02 0 2.04 0 0 Z '
        fill={color}
        fillOpacity={color === 'white' ? '0.5' : '1'}
        transform='translate(14,8)'
      />
      <path
        d='M0 0 C0.66 0 1.32 0 2 0 C2 1.32 2 2.64 2 4 C1.34 4 0.68 4 0 4 C0 2.68 0 1.36 0 0 Z '
        fill={color}
        fillOpacity={color === 'white' ? '0.5' : '1'}
        transform='translate(11,10)'
      />
      <path
        d='M0 0 C0.66 0 1.32 0 2 0 C2 0.66 2 1.32 2 2 C1.34 2 0.68 2 0 2 C0 1.34 0 0.68 0 0 Z '
        fill={color}
        fillOpacity={color === 'white' ? '0.5' : '1'}
        transform='translate(8,12)'
      />
    </svg>
  );
};

export default DashboardIcon;

import {
  useDisclosure,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Box,
} from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';

import BarChartIcon from 'components/Icons/BarChartIcon';
import LineChartIconComponent from 'components/Icons/LineChartIconComponent';

export interface Properties {
  chartType: string;
  setChartType: (type: string) => void;
}

const ChartType = ({ chartType, setChartType }: Properties) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getChartNameIcon = (type: string) => {
    switch (type) {
      case 'line':
        return (
          <>
            <LineChartIconComponent marginRight='.5rem' />
            <Text>{'Line Chart'}</Text>
          </>
        );
      case 'bar':
        return (
          <>
            <BarChartIcon marginRight='.5rem' />
            <Text>{'Column Chart'}</Text>
          </>
        );
      default:
        return (
          <>
            <LineChartIconComponent marginRight='.5rem' />
            <Text>{'Click to select Chart'}</Text>
          </>
        );
    }
  };

  return (
    <Flex flexDir={'column'}>
      <Text color={'black.800'} fontWeight={'600'} mb={1}>
        Chart Type
      </Text>
      <Menu isOpen={isOpen} onClose={onClose} strategy='fixed' isLazy variant={'primary'}>
        <MenuButton width={'100%'} onClick={isOpen ? onClose : onOpen}>
          <Flex
            justifyContent={'space-between'}
            backgroundColor={'#FAFAFA'}
            border={'1px #D9D9D9 solid'}
            padding={'.5rem .8rem'}
            borderRadius={'6px'}
            color={'black.600'}
            fontSize={'2xs'}
            alignItems={'center'}
          >
            <Flex alignItems={'center'}>{getChartNameIcon(chartType)}</Flex>
            <GoChevronDown size={18} />
          </Flex>
        </MenuButton>
        <MenuList p={'1rem'}>
          <MenuOptionGroup type='radio' value={chartType}>
            <MenuItemOption onClick={() => setChartType('line')} value='line'>
              <Flex>
                <Box>
                  <LineChartIconComponent marginRight='.5rem' />
                </Box>{' '}
                <Box>Line Chart</Box>
              </Flex>
            </MenuItemOption>
            <MenuItemOption onClick={() => setChartType('bar')} value='bar'>
              <Flex>
                <Box>
                  <BarChartIcon marginRight='.5rem' />
                </Box>{' '}
                <Box>Column Chart</Box>
              </Flex>
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ChartType;

import {
  Flex,
  Modal,
  ModalBody,
  Box,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { connect } from 'react-redux';

import { SetTabs, SetUserModalVisible } from 'redux/UserSlice';
import FreePlanImage from 'components/Icons/FreePlan';
import RocketLaunch from 'components/Icons/RocketLaunch';

interface Properties {
  setUserTabs: (data: number) => Promise<void>;
  setUserModalVisible: (data: boolean) => Promise<void>;
  modelOwner?: any;
  user?: any;
  setUpgrade?: any;
}
const ShareModalPermission = ({
  // setUserTabs,
  // setUserModalVisible,
  modelOwner,
  user,
  setUpgrade,
}: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fontStyle = {
    color: '#FFF',
    fontFamily: 'Roboto Regular',
    fontSize: 'xs',
    fontWeight: 400,
  };
  return (
    <Flex
      bg={'#3BB8BC'}
      justify={'start'}
      align={'center'}
      gap={3}
      height={'35px'}
      borderRadius={'6px'}
      my={2}
      w={'100%'}
      ps={2}
    >
      {modelOwner && modelOwner?.role === 'owner' && modelOwner?.is_in_same_org === false ? (
        <Text {...fontStyle}>You cannot share this model further as you are on view access</Text>
      ) : user && user?.role.toLowerCase() === 'planner' ? (
        <Text {...fontStyle}>To upgrade your plan contact your owner/Admin</Text>
      ) : (
        <>
          <InfoOutlineIcon color={'white'} />
          <Flex justify={'center'} align={'center'} gap={1}>
            <Text
              {...fontStyle}
              fontWeight={500}
              textDecoration={'underline'}
              cursor={'pointer'}
              onClick={() => {
                onOpen();
                setUpgrade(true);
              }}
            >
              Upgrade
            </Text>
            <Text {...fontStyle}>to unlock permission controls for your model</Text>
          </Flex>
        </>
      )}

      <Modal
        onClose={() => {
          onClose();
          setUpgrade(false);
        }}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxWidth={'800px'} mx={'2rem'}>
          <ModalBody>
            <Flex width={'100%'} height={'100%'} flexDir='column'>
              <Flex>
                <Box mt='8' mr='2'>
                  <Text fontSize='20px' color='black' fontWeight='600'>
                    Upgrade your Blox subscription to unlock teams...
                  </Text>
                  <Text fontSize='md' color='black' fontWeight='400' mt='4'>
                    Invite your team members to share models,
                  </Text>
                  <Text fontSize='md' color='black' fontWeight='400' mt='2'>
                    engage with insights and collaborate
                  </Text>
                  <Flex>
                    <Button
                      bgColor='green.700'
                      borderRadius='10px'
                      leftIcon={<RocketLaunch height={8} width={8} />}
                      paddingInline='0'
                      padding='.5rem 1rem'
                      width='fit-content'
                      mt={'8'}
                      // onClick={() => {
                      //   setUserModalVisible(true).then(() => setUserTabs(5));
                      // }}
                      onClick={() => {
                        window.open('https://www.blox.so/pricing', '_blank');
                      }}
                    >
                      <Text fontSize='md' fontWeight='500'>
                        VIEW PAID PLANS
                      </Text>
                    </Button>
                  </Flex>
                </Box>
                {!isMobile && (
                  <Box ml='8'>
                    <FreePlanImage height='250px' width='250px' />
                  </Box>
                )}
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
const mapDispatchToProperties = (dispatch: any) => {
  return {
    setUserModalVisible: (data: any) => dispatch(SetUserModalVisible(data)),
    setUserTabs: (data: any) => dispatch(SetTabs(data)),
  };
};
export default connect(null, mapDispatchToProperties)(ShareModalPermission);

import 'react-grid-layout/css/styles.css';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { Box } from '@chakra-ui/react';
import { useState, useEffect, useMemo } from 'react';
import './grid.css';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteInstrument,
  getDashboard,
  setAllInstruments,
  updatelayout,
} from 'redux/DashboardSliceV2';
import { AppDispatch } from 'utils/GlobalHelpers';
import DeleteModal from 'components/Modals/DeleteModal';

import Instrument from './Instrument';

export interface GridProperties {
  allInstruments: Array<any>;
}

const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };
const rowHeight = 80;
// const rowHeightMap = { lg: 55, md: 40, sm: 60, xs: 50, xxs: 45 };

const Grid = ({ allInstruments }: GridProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const dashboard = useSelector(getDashboard);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<keyof typeof cols>('lg');
  const [instrumentDeleteData, setInstrumentDeleteData] = useState<any>(null);
  const [savedLayout, setSavedLayout] = useState<Layouts>({});

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const getNextAvailablePosition = (
    layout: Layout[],
    columns: number,
    defaultSize = { w: 2, h: 3 },
  ) => {
    for (let y = 0; y < 20; y += 1) {
      for (let x = 0; x <= columns - defaultSize.w; x += 1) {
        const isOccupied = layout.some(
          (item) =>
            x < item.x + item.w &&
            x + defaultSize.w > item.x &&
            y < item.y + item.h &&
            y + defaultSize.h > item.y,
        );
        if (!isOccupied) {
          return { x, y, ...defaultSize };
        }
      }
    }
    return { x: 0, y: 0, ...defaultSize };
  };

  console.log(currentBreakpoint, savedLayout);

  useEffect(() => {
    if (dashboard?.instrument_layout) {
      setSavedLayout(dashboard?.instrument_layout);
    }
  }, [dashboard]);

  useEffect(() => {
    setSavedLayout((previousLayout: any) => {
      const updatedLayouts = { ...previousLayout };

      Object.keys(cols).forEach((breakpoint) => {
        const existingLayout = updatedLayouts[breakpoint] || [];
        const newLayout = [...existingLayout];

        allInstruments.forEach((item) => {
          if (!existingLayout.some((layoutItem: any) => layoutItem.i === String(item.id))) {
            const position = getNextAvailablePosition(
              newLayout,
              cols[breakpoint as keyof typeof cols],
              {
                h: item.instrument_type === 'kpi' ? 2 : 3,
                w: item.instrument_type === 'table' ? 3 : 2,
              },
            );
            newLayout.push({
              i: String(item.id),
              ...position,
              minH: item.instrument_type === 'kpi' ? 2 : 3,
              minW: item.instrument_type === 'table' ? 3 : 2,
            });
          }
        });

        updatedLayouts[breakpoint] = newLayout;
      });

      return updatedLayouts;
    });
  }, [allInstruments]);

  const onResizeStop = (layout: Layout[]) => {
    setSavedLayout((previousLayouts: Layouts) => ({
      ...previousLayouts,
      [currentBreakpoint]: layout,
    }));
    dispatch(
      updatelayout({
        instrument_layout: { ...savedLayout, [currentBreakpoint]: layout },
        id: dashboard.id,
        description: dashboard?.description,
        name: dashboard?.name,
        user_id: dashboard?.user_id,
        model_id: dashboard?.model_id,
        created_at: dashboard?.created_at,
        fiscal_start_month: dashboard?.fiscal_start_month,
        updated_at: dashboard?.updated_at,
      }),
    );
  };

  const onDragStop = (layout: Layout[]) => {
    setSavedLayout((previousLayouts: Layouts) => ({
      ...previousLayouts,
      [currentBreakpoint]: layout,
    }));
    dispatch(
      updatelayout({
        instrument_layout: { ...savedLayout, [currentBreakpoint]: layout },
        id: dashboard.id,
        description: dashboard?.description,
        name: dashboard?.name,
        user_id: dashboard?.user_id,
        model_id: dashboard?.model_id,
        created_at: dashboard?.created_at,
        fiscal_start_month: dashboard?.fiscal_start_month,
        updated_at: dashboard?.updated_at,
      }),
    );
  };

  const handleDelete = (instrumentData: any) => {
    dispatch(deleteInstrument({ chartId: instrumentData.id })).then(() => {
      dispatch(
        setAllInstruments(
          allInstruments.filter((instrument: any) => instrument.id !== instrumentData.id),
        ),
      );

      let currentLayout = {};
      Object.keys(savedLayout).map((breakpoint: any) => {
        currentLayout = {
          ...currentLayout,
          [breakpoint]: savedLayout[breakpoint].filter((item: any) => {
            return item.i !== String(instrumentData.id);
          }),
        };
        return breakpoint;
      });

      setSavedLayout(currentLayout);
      dispatch(
        updatelayout({
          instrument_layout: currentLayout,
          id: dashboard.id,
          description: dashboard?.description,
          name: dashboard?.name,
          user_id: dashboard?.user_id,
          model_id: dashboard?.model_id,
          created_at: dashboard?.created_at,
          fiscal_start_month: dashboard?.fiscal_start_month,
          updated_at: dashboard?.updated_at,
        }),
      );
      setInstrumentDeleteData(null);
    });
  };

  return (
    <>
      <ResponsiveReactGridLayout
        resizeHandles={['ne', 'nw', 'se', 'sw']}
        useCSSTransforms
        className='dashboard-grid-container'
        draggableHandle='.dashboard-drag-header'
        allowOverlap={false}
        layouts={savedLayout}
        preventCollision={false}
        // rowHeight={rowHeightMap[currentBreakpoint] || rowHeight}
        rowHeight={rowHeight}
        autoSize={false}
        onBreakpointChange={(newBreakpoint) =>
          setCurrentBreakpoint(newBreakpoint as keyof typeof cols)
        }
        margin={[12, 12]}
        onResizeStop={onResizeStop}
        onDragStop={onDragStop}
        breakpoints={breakpoints}
        cols={cols}
      >
        {savedLayout[currentBreakpoint]?.map((layoutItem) => {
          const item = allInstruments.find((data) => String(data.id) === layoutItem.i);
          if (!item) return null;

          return (
            <Box
              className='dashboard-item grid-item page-break-before'
              key={item.id}
              data-grid={{ ...layoutItem, isResizable: true, isDraggable: true }}
            >
              <Instrument
                instrumentData={item}
                deleteInstrument={() => setInstrumentDeleteData(item)}
              />
            </Box>
          );
        })}
      </ResponsiveReactGridLayout>
      <DeleteModal
        isOpen={instrumentDeleteData}
        onClose={() => setInstrumentDeleteData(null)}
        itemName={instrumentDeleteData?.name}
        onDelete={() => handleDelete(instrumentDeleteData)}
      />
    </>
  );
};

export default Grid;

import { Box, Flex, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomToast from 'components/CustomToast';
import {
  AddDimnesionGroupItem,
  fetchDimensionItemsGroup,
  fetchDimensionPropeties,
  getDimensionDataGroupStatus,
  getItemId,
  setDisplayInfo,
} from 'redux/ItemPageSlice';
import { getSelectedDriver, handleFilters } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import PlanPermission from 'container/PlanPermission';
import { AppDispatch } from 'utils/GlobalHelpers';

import { UpdatePropertyValues } from '../../../../models/requestBody/updatePropertyValues.interface';
import apiService from '../../../../services';

import NewGroupInput from './PanelSteps/NewGroupInput';
import StepOne from './PanelSteps/StepOne';
import StepThree from './PanelSteps/StepThree';
import StepTwo from './PanelSteps/StepTwo';

interface Properties {
  longClickCheck: any;
  isLongClicked: boolean;
  planPermission: boolean;
  refetchRequest: () => void;
}

const MobilePanel = ({
  longClickCheck,
  isLongClicked,
  planPermission,
  refetchRequest,
}: Properties) => {
  const queryClient = useQueryClient();
  const dispatch: AppDispatch = useDispatch();
  const toast = useToast();

  const itemId = useSelector(getItemId);
  const loadingDriver = useSelector(getDimensionDataGroupStatus);
  const [itemIndicator, setItemIndicator] = useState<any>();
  const [addGroupName, setAddGroupName] = useState<string | null>(null);
  const currentDriver = useSelector(getSelectedDriver);
  const baseScenario = useSelector(getBaseScenario);

  useEffect(() => {
    dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
    dispatch(fetchDimensionPropeties(currentDriver));
  }, [currentDriver, baseScenario?.id]);

  const { mutate: updateValue } = useMutation(
    ({ item_id, request_body }: UpdatePropertyValues) =>
      apiService.update_property_values_group({ item_id, request_body }),
    {
      onSuccess: () => {
        refetchRequest();
        queryClient.invalidateQueries(['propertyValues', currentDriver?.block_id]);
        dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      },
      onError: (error: any) => {
        toast({
          duration: 2500,
          render: () => (
            <CustomToast
              title={error?.response?.data?.message || 'Something went wrong..'}
              status='error'
            />
          ),
        });
      },
    },
  );

  const onSubmitValue = (value: string, id: number, propertyValue: string) => {
    const propertyId = itemId?.properties?.find(
      (element: any) => element.name === propertyValue,
    )?.id;
    if (propertyId) {
      updateValue({
        item_id: id,
        request_body: {
          property_id: propertyId,
          value,
          scenario_id: baseScenario?.id,
        },
      });
    }
  };

  const addGroup = async (event: any) => {
    event.preventDefault();
    if (addGroupName && addGroupName?.length !== 0) {
      await dispatch(
        AddDimnesionGroupItem({
          dimension_id: currentDriver?.connected_dimension,
          request_body: {
            name: addGroupName,
            properties: [
              {
                property_id: currentDriver?.group_property_id,
                value: addGroupName,
              },
            ],
            scenario_id: baseScenario?.id,
          },
          toaster: {
            successMessage: 'Successfully Added',
            errorMessage: 'An item with this name already exists',
          },
        }),
      );
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      await dispatch(handleFilters());
      refetchRequest();
    }
  };

  return (
    <Box height='100%'>
      <Flex
        maxH={`calc(100vh - 96px)`}
        width='100%'
        justifyContent='space-between'
        alignItems='center'
        flexDirection='column'
        overflow='scroll'
        className='scroll'
        py={4}
        onClick={() => dispatch(setDisplayInfo(null))}
      >
        <StepOne
          {...{
            longClickCheck,
            isLongClicked,
            planPermission,
          }}
        />
      </Flex>
      {currentDriver?.group_property_id && (
        <NewGroupInput
          {...{
            addGroupName,
            setAddGroupName,
            addGroup,
            planPermission,
          }}
        />
      )}

      <StepTwo
        {...{
          setItemIndicator,
          loadingDriver,
          longClickCheck,
          isLongClicked,
          refetchRequest,
          planPermission,
        }}
      />
      <StepThree
        {...{
          itemIndicator,
          onSubmitValue,
        }}
      />
    </Box>
  );
};

export default PlanPermission(MobilePanel);

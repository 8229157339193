import { Flex, Text } from '@chakra-ui/react';

export interface FormatProperties {
  selectedColor: string;
  handleColorChange: (event: any) => void;
}

const RowTextColor = ({ selectedColor, handleColorChange }: FormatProperties) => {
  return (
    <Flex alignItems={'center'} mb={2} justifyContent={'space-between'}>
      <Text color={'black.800'} fontWeight={'600'}>
        Text colour
      </Text>
      <Flex flexDirection={'column'} alignItems='center' justifyContent={'center'}>
        <Text>A</Text>
        <input
          value={selectedColor}
          onChange={(event) => handleColorChange(event.target.value)}
          type='color'
          style={{ width: '24px', height: '15px' }}
        />
      </Flex>
    </Flex>
  );
};
export default RowTextColor;

import { BiFilter } from 'react-icons/bi';
import { Flex, Text, Box, Menu, MenuButton, MenuList, Divider } from '@chakra-ui/react';
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '@chakra-ui/icons';

import {
  getDashboardBlocks,
  //   getSelectedDashboardScenario,
  //   createInstrument,
  //   updateInstrument,
  //   setEditInstrumentOutput,
  //   setEditInstrumentData,
} from 'redux/DashboardSliceV2';

// import PreviewSelectCheckBox from '../../../components/PreviewFilterCheckBox';
// import PreviewSelectCheckBox from 'components/PreviewFilterCheckBox/MultiSelectCheckBox';

import { findDimensionItems } from './Filters';
import PreviewSelectCheckBox from './MultiSelectCheckBox';

interface Properties {
  widget: any;
  handleApplyFilter: any;
}

const PriviewFilterByComponent = ({ widget, handleApplyFilter }: Properties) => {
  //   const dispatch: any = useDispatch();
  //   const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);
  const blockDetails = useSelector(getDashboardBlocks);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filterItem, setFilterItem] = useState<any>([]);

  useEffect(() => {
    const data = findDimensionItems(widget);
    setFilterItem(data);
  }, [widget?.instrument_indicators]);
  //   const processedDimensionsData = useMemo(
  //     () =>
  //       widget?.instrument_indicators.map(({ block_id, indicator_id }: any) => {
  //         const foundBlock = blockDetails.find((block: any) => block.block_id === block_id);
  //         const foundIndicator = foundBlock?.indicators?.find(
  //           (indicator: any) => indicator.id === indicator_id,
  //         );
  //         return {
  //           blockid: block_id,
  //           indicatorid: indicator_id,
  //           dimension: foundIndicator?.indicator_dimensions || [],
  //         };
  //       }),
  //     [widget?.instrument_indicators, blockDetails],
  //   );
  const processedDimensionsData = useMemo(() => {
    return widget?.instrument_indicators
      .map(({ block_id, indicator_id }: any) => {
        const foundBlock = blockDetails.find((block: any) => block.block_id === block_id);
        const foundIndicator = foundBlock?.indicators?.find(
          (indicator: any) => indicator.id === indicator_id,
        );
        return {
          blockid: block_id,
          indicatorid: indicator_id,
          dimension: foundIndicator?.indicator_dimensions || [],
        };
      })
      .filter((item: any) => item.dimension.length > 0); // Filter out objects with empty dimension arrays
  }, [widget?.instrument_indicators, blockDetails]);

  const handleApplyFilterFunction = (filterAppliedData: any) => {
    handleApplyFilter(filterAppliedData, filterItem);
    setIsMenuOpen(false);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  //   const handleOpenMenu = () => {
  //     setIsMenuOpen(true);
  //   };

  return (
    <Menu
      variant={'filter'}
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      onOpen={() => setIsMenuOpen(true)}
      strategy='fixed'
      direction={'ltr'}
    >
      <MenuButton>
        <Flex
          alignItems={'center'}
          bgColor='#f5f5f5'
          borderRadius='8px'
          p='0.1rem .1rem'
          maxWidth='100%'
        >
          <BiFilter size={35} color={'#4BB6B9'} />
          <Text size={'Roboto-12'} mr={2}>
            Filter by:
          </Text>
          {/* widget?.instrument_type === 'line' || widget?.instrument_type === 'bar' */}
          {widget?.instrument_type === 'kpi' ? (
            <>
              <Divider
                mx={2}
                orientation='vertical'
                size='2xs'
                sx={{
                  borderColor: '#706B9A',
                }}
                height='12px'
                alignSelf='center'
              />
              <Text size='Roboto-12' whiteSpace='nowrap'>
                All items
              </Text>
            </>
          ) : (
            filterItem &&
            filterItem[0]?.dimensionItems?.map((item: any, index: number) => (
              <React.Fragment key={index}>
                {item && (
                  <>
                    <Divider
                      mx={2}
                      orientation='vertical'
                      size='2xs'
                      sx={{
                        borderColor: '#706B9A',
                      }}
                      height='12px'
                      alignSelf='center'
                    />
                    <Text size='Roboto-12' whiteSpace='nowrap'>
                      {item.dimensionItemName}
                    </Text>
                  </>
                )}
              </React.Fragment>
            ))
          )}
          <Box>
            <ChevronDownIcon fontSize='md' />
          </Box>
        </Flex>
      </MenuButton>
      {/* {isOpen && ( */}
      <MenuList>
        <Flex gap={8} maxW={'600px'} overflowX={'auto'} className='sm-scroll'>
          {processedDimensionsData &&
            processedDimensionsData.length > 0 &&
            processedDimensionsData?.map((checkBoxItem: any, index: number) => (
              <React.Fragment key={index}>
                <PreviewSelectCheckBox
                  handleApply={handleApplyFilterFunction}
                  handleClose={handleCloseMenu}
                  checkboxData={checkBoxItem}
                  isMenuOpen={isMenuOpen}
                  filterItem={filterItem}
                />
              </React.Fragment>
            ))}
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default PriviewFilterByComponent;

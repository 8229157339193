import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Tooltip,
  // Divider,
  // useToast,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
// import { GoChevronDown } from 'react-icons/go';
import { useEffect, useMemo, useState } from 'react';

// import CustomToast from 'components/CustomToast';
import Plus from 'components/Icons/Plus';
import RemoveIconComponent from 'components/Icons/RemoveIcon';
import { AppDispatch } from 'utils/GlobalHelpers';
import DimensionIconComponent from 'components/Icons/DimensionIcon';
import {
  getDashboardBlocks,
  createInstrument,
  updateInstrument,
  setEditInstrumentOutput,
  getSelectedDashboardScenario,
  setEditInstrumentData,
} from 'redux/DashboardSliceV2';

interface Properties {
  widget?: any;
  isEditKpi?: any;
}

export function findDimensionItems(insturmentData: any) {
  // Check if the insturmentData and instrument_indicators array exist
  if (!insturmentData || !Array.isArray(insturmentData?.instrument_indicators)) {
    return [];
  }

  // Filter the instrument_indicators array
  return insturmentData?.instrument_indicators
    ?.filter((indicator: any) => {
      return (
        indicator?.formatting &&
        Array.isArray(indicator?.formatting?.dimensionItems) &&
        indicator?.formatting?.dimensionItems?.length > 1 &&
        indicator?.dimension_id !== null &&
        indicator?.dimension_id !== ''
      );
    })
    ?.map((item: any) => ({
      dimensionId: item?.dimension_id,
      dimensionName: item?.dimension_name,
      dimensionItems:
        (item &&
          item?.formatting?.dimensionItems &&
          item?.formatting?.dimensionItems.length > 0 &&
          item?.formatting?.dimensionItems.map((dItem: any) => ({
            dimensionItemId: dItem?.dimension_item_id,
            dimensionItemName: dItem?.dimension_item_name,
          }))) ||
        [],
    }));
}

const DashboardModalFilters = ({ widget }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const selectedDashboardScenario = useSelector(getSelectedDashboardScenario);
  const blockDetails = useSelector(getDashboardBlocks);
  const [selectedDimFilter, setSelectedDimFilter] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState<any>([]);
  const processedDimensionsData = useMemo(() => {
    return widget
      ? widget?.instrument_indicators
          .filter(
            (indicator: any) => indicator?.dimension_id !== null && indicator?.dimension_id !== '',
          )
          .map(({ block_id, indicator_id }: any) => {
            const foundBlock = blockDetails.find((block: any) => block.block_id === block_id);
            const foundIndicator = foundBlock?.indicators?.find(
              (indicator: any) => indicator.id === indicator_id,
            );
            return {
              blockid: block_id,
              indicatorid: indicator_id,
              dimension: foundIndicator?.indicator_dimensions || [],
            };
          })
      : [];
  }, [widget?.instrument_indicators, blockDetails]);

  useEffect(() => {
    if (processedDimensionsData) {
      setDimensions([processedDimensionsData]);
    }
  }, [processedDimensionsData]);

  const matchedFilters = useMemo(() => {
    const responseWithFilter = findDimensionItems(widget);
    return responseWithFilter;
  }, [widget?.id, widget?.instrument_indicators]);

  useEffect(() => {
    if (matchedFilters) {
      setSelectedDimFilter(matchedFilters);
    }
  }, [matchedFilters]);

  const handleAddingFilter = () => {
    setSelectedDimFilter([
      ...selectedDimFilter,
      {
        dimensionId: 0,
        dimensionItems: [],
        dimensionName: 'Dimension',
      },
    ]);
  };
  const getRandomColor = () => `#${Math.floor(Math.random() * 16_777_215).toString(16)}`;
  const handleRemovingFilter = (data: any) => {
    const updatedFilters = selectedDimFilter.filter(
      (dim: any) => dim.dimensionId !== data.dimensionId,
    );
    const insturmentIndicators = JSON.parse(JSON.stringify(widget?.instrument_indicators));
    const instrumentIndicatorsWIthDimensionItems = insturmentIndicators.map((instrument: any) => {
      if (instrument.dimension_id === data[0]?.dimensionId) {
        const matchingData = data.find((dim: any) => dim.dimensionId);
        if (matchingData) {
          instrument.dimension_id = null;
          instrument.formatting = {
            color: getRandomColor(),
          };
        }
      }
      return instrument;
    });
    // const timeFilter = widget?.instrument_filters?.find((item: any) => {
    //   return item?.name === 'Time';
    // });
    if (!widget.id) {
      dispatch(
        createInstrument({
          request_body: {
            ...widget,
            // instrument_filters: [timeFilter],
            instrument_indicators: instrumentIndicatorsWIthDimensionItems,
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            save: false,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
        dispatch(
          setEditInstrumentData({
            ...widget,
            // instrument_filters: result?.payload?.instrument_filters,
            instrument_indicators: result?.payload?.instrument_indicators,
          }),
        );
      });
    } else {
      dispatch(
        updateInstrument({
          request_body: {
            ...widget,
            // instrument_filters: [timeFilter],
            instrument_indicators: instrumentIndicatorsWIthDimensionItems,
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            save: true,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
        dispatch(
          setEditInstrumentData({
            ...widget,
            // instrument_filters: result?.payload?.instrument_filters,
            instrument_indicators: result?.payload?.instrument_indicators,
          }),
        );
      });
    }
    setSelectedDimFilter(updatedFilters);

    // setSelectedDimFilter(newDimensionsFilters);
  };
  // Function to generate a random hex color
  const handleDimensionSelect = (data: any) => {
    const updatedFilters = selectedDimFilter
      .filter((f) => f.dimensionId !== 0) // Remove items with dimensionId 0
      .concat(
        data.map((dim: any) => ({
          dimensionId: dim.indicator_dimension_id,
          dimensionName: dim.indicator_dimension_name,
          dimensionItems: dim.indicator_dimension_items.map((item: any) => ({
            dimensionItemId: item.indicator_dimension_item_id,
            dimensionItemName: item.indicator_dimension_item_name,
          })),
        })),
      );
    const insturmentIndicators = JSON.parse(JSON.stringify(widget?.instrument_indicators));
    const instrumentIndicatorsWIthDimensionItems = insturmentIndicators.map((instrument: any) => {
      if (instrument.dimension_id === data[0]?.indicator_dimension_id) {
        const matchingData = data.find((dim: any) => dim.indicator_dimension_id);
        if (matchingData) {
          instrument.dimension_id = data[0]?.indicator_dimension_id;
          instrument.formatting = {
            dimensionItems: matchingData.indicator_dimension_items.map((item: any) => ({
              dimension_item_id: item.indicator_dimension_item_id,
              dimension_item_name: item.indicator_dimension_item_name,
              color: getRandomColor(),
            })),
          };
        }
      }
      return instrument;
    });
    // const timeFilter = widget?.instrument_filters?.find((item: any) => {
    //   return item?.name === 'Time';
    // });
    if (!widget.id) {
      dispatch(
        createInstrument({
          request_body: {
            ...widget,
            // instrument_filters: [
            //   timeFilter,
            //   {
            //     id: null,
            //     instrument_id: null,
            //     name: data[0]?.indicator_dimension_name,
            //     dimensionId: data[0]?.indicator_dimension_id,
            //     operator: 'eq',
            //     value: '',
            //     second_value: null,
            //     filter_type: 'dimension',
            //   },
            // ],
            instrument_indicators: instrumentIndicatorsWIthDimensionItems,
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            save: false,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
        dispatch(
          setEditInstrumentData({
            ...widget,
            // instrument_filters: result?.payload?.instrument_filters,
            instrument_indicators: result?.payload?.instrument_indicators,
          }),
        );
      });
    } else {
      dispatch(
        updateInstrument({
          request_body: {
            ...widget,
            // instrument_filters: [
            //   timeFilter,
            //   {
            //     id: null,
            //     instrument_id: null,
            //     name: data[0]?.indicator_dimension_name,
            //     dimensionId: data[0]?.indicator_dimension_id,
            //     operator: 'eq',
            //     value: '',
            //     second_value: null,
            //     filter_type: 'dimension',
            //   },
            // ],
            instrument_indicators: instrumentIndicatorsWIthDimensionItems,
            scenario_id: selectedDashboardScenario?.id ? `${selectedDashboardScenario?.id}` : '',
            save: true,
            data_values: true,
          },
        }),
      ).then((result) => {
        dispatch(setEditInstrumentOutput(result?.payload?.data));
        dispatch(
          setEditInstrumentData({
            ...widget,
            // instrument_filters: result?.payload?.instrument_filters,
            instrument_indicators: result?.payload?.instrument_indicators,
          }),
        );
      });
    }
    setSelectedDimFilter(updatedFilters);
  };
  console.log({ dimensions, widget });
  if (!matchedFilters) {
    return <></>;
  }
  if (widget?.instrument_indicators?.length > 1) {
    return <>Filters can't be Applied on multiple Indicators</>;
  }
  return (
    <Flex flexDir={'column'}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Text color={'black.800'} fontWeight={'600'}>
          Filters
        </Text>
        <Tooltip
          label={dimensions[0]?.length !== 1 ? 'Please Add Indicator Dimension' : 'Add filter'}
          aria-label='Add filter tooltip'
          placement='top'
          hasArrow
        >
          <Button width={'14.583px'} disabled={dimensions[0]?.length !== 1}>
            <Plus color='#787878' onClick={handleAddingFilter} />
          </Button>
        </Tooltip>
      </Flex>
      {/* <Flex
        cursor={'pointer'}
        onClick={handleAddingFilter}
        justifyContent={'space-between'}
        alignItems={'center'}
        backgroundColor={'#FAFAFA'}
        border={'1px #D9D9D9 solid'}
        padding={'.5rem .8rem'}
        borderRadius={'6px'}
        color={'black.600'}
        fontSize={'2xs'}
      >
        <Text>{'Click to add filter'}</Text>
      </Flex> */}
      <>
        <Box>
          <Flex mb={1}>
            <Text size={'Roboto-14'} color={'#B5B5B5'} width={'50%'}>
              Dimension
            </Text>
            <Text size={'Roboto-14'} color={'#B5B5B5'} width={'50%'}>
              Default filter
            </Text>
          </Flex>
          <Flex flexDir={'column'} gap={4}>
            {selectedDimFilter.map((item: any) => {
              return (
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={2}
                  // key={item.localId}
                >
                  <Flex
                    backgroundColor={'#FAFAFA'}
                    flex={1}
                    borderRadius={'6px'}
                    alignItems={'center'}
                    border={'1px solid'}
                    justifyContent={'space-between'}
                    borderColor={'grey.700'}
                  >
                    <Tooltip placement='top' label={item.dimensionName}>
                      <Box width={'50%'}>
                        <Menu variant={'dashboardingModal'}>
                          {() => (
                            <>
                              <MenuButton
                                as={Button}
                                padding={'.3rem .5rem'}
                                variant={'dashboardingModal'}
                                border={'none'}
                                color={'#212121'}
                                fontWeight={'600'}
                                width={'100%'}
                              >
                                <Flex alignItems={'center'} justifyContent={'space-between'}>
                                  <Flex gap={1.5} alignItems={'center'}>
                                    <DimensionIconComponent
                                      height={5}
                                      width={5}
                                      color={'#4BB6B9'}
                                    />
                                    {item.dimensionName?.length > 10 ? (
                                      <Text>{item.dimensionName.slice(0, 10)}...</Text>
                                    ) : (
                                      <Text>{item.dimensionName || 'Dimension'}</Text>
                                    )}
                                  </Flex>
                                </Flex>
                              </MenuButton>
                              <MenuList>
                                <>
                                  {dimensions &&
                                    dimensions?.map((data: any) => {
                                      return (
                                        <MenuItem
                                          onClick={() => handleDimensionSelect(data[0]?.dimension)}
                                          key={data[0]?.dimension[0]?.indicator_dimension_id}
                                        >
                                          {data[0]?.dimension[0]?.indicator_dimension_name}
                                        </MenuItem>
                                      );
                                    })}
                                </>
                              </MenuList>
                            </>
                          )}
                        </Menu>
                      </Box>
                    </Tooltip>
                  </Flex>
                  <RemoveIconComponent
                    color={'black.900'}
                    onClick={() => handleRemovingFilter(item)}
                  />
                </Flex>
              );
            })}
          </Flex>
        </Box>
      </>
    </Flex>
  );
};

export default DashboardModalFilters;

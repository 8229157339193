/* eslint-disable unicorn/prefer-array-some */
/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */
import { ChevronCell, Row } from '@silevis/reactgrid';

const dimensionalCalculateWidth = (totalElements: number, totalWidth: number) => {
  const editField: number = 260;
  const columnsWidth = totalElements * 150 + editField;
  return totalWidth > columnsWidth ? (totalWidth - editField) / totalElements : 150;
};

const calculateWidth = (totalElements: number, totalWidth: number) => {
  const nameField: number = 150;
  const editField: number = 60;
  const columnsWidth = totalElements * 150 + nameField + editField;
  return totalWidth > columnsWidth ? (totalWidth - editField) / totalElements : 150;
};

const dimensionalHeaderRow = (tableProperty_: any) => ({
  rowId: 'header',
  height: 40,
  cells: [
    {
      type: 'header',
      text: 'Name',
      colType: 'header',
      nonEditable: true,
      className: `header-cell header-first`,
    },
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any, index: any) => {
          const row = {
            type: 'header',
            colType: 'header',
            nonEditable: true,
            text: data?.name,
            className: `header-cell ${index === tableProperty_?.length - 1 ? ' last-index ' : ''}${
              data?.isActual ? 'actuals-cell' : ''
            }`,
          };
          return row;
        })
      : []),
    {
      type: 'header',
      text: 'Del',
      nonEditable: true,
      colType: 'header',
      className: 'header-cell header-last',
    },
  ],
});

const dimensionalFooterRow = (tableProperty_: any) => ({
  rowId: 'footer',
  height: 40,
  cells: [
    {
      type: 'addRow',
      text: '',
      className: 'footer footer-first',
      showText: true,
      nonEditable: true,
    },
    ...(tableProperty_?.length
      ? tableProperty_?.map((_: any, index_: number) => {
          const row = {
            type: 'text',
            text: '',
            nonEditable: true,
            className: `${index_ === tableProperty_?.length - 1 ? ' last-index ' : ''} footer`,
            hasFormulaError: false,
          };
          return row;
        })
      : []),
    {
      type: 'text',
      text: '',
      nonEditable: true,
      className: 'footer footer-last',
      hasFormulaError: false,
    },
  ],
});

const getDimensionalRows = (
  tableItemsProperty_: any,
  tableProperty_: any,
  modelCurrency?: string,
) => {
  return [
    dimensionalHeaderRow(tableProperty_),
    ...(tableItemsProperty_?.length
      ? tableItemsProperty_?.map((item: any, index: any) => {
          return {
            rowId: item?.id ?? `${`${item?.name}_${item?.id ?? item?.parentId}`}`,
            height: 40,
            itemId: item?.id ?? item?.indicatorId,
            itemName: item?.name,
            reorderable: !item?.parentId,
            isNewCell: !!item?.isNewCell,
            cells: [
              {
                type: item?.chevronType ? 'chevron' : 'text',
                isExpanded: false,
                hasChildren: item?.chevronType,
                parentId: item?.parentId,
                placeholder: 'Enter value',
                currencyType: modelCurrency,
                text: `${item?.name}` ?? '',
                property: 'name',
                rows: tableItemsProperty_?.length,
                hasFormulaError: item?.warning?.length > 0,
                hasFormulaErrorMessage: item?.warning,
                className: `name ${item?.parentId ? 'chevron-child' : ''} ${
                  item?.warning?.length > 0 ? 'formula-error ' : ''
                }`,
                isNewCell: !!item?.isNewCell,
                indicatorType: item?.indicator_type,
                nonEditable: !!item?.parentId,
                index,
                ...item?.cellData,
              },
              ...(tableProperty_?.length
                ? tableProperty_?.map((data: any, index_: number) => {
                    const value = item?.total_values?.find(
                      (data_: any) => data_?.name === data?.name,
                    );
                    const row = {
                      type: 'text',
                      isNewCell: !!item?.isNewCell,
                      rows: tableItemsProperty_?.length,
                      text: item?.isNewCell ? '0' : value ? `${value?.value}` : '0',
                      format: item?.type,
                      tableName: 'block',
                      currencyType: modelCurrency,
                      index,
                      data,
                      nonEditable: true,
                      className: `${item?.parentId ? ' chevron-child ' : ''} ${
                        index_ === tableProperty_?.length - 1 ? ' last-index ' : ''
                      } ${item?.warning?.length > 0 ? 'formula-error ' : ''} ${
                        data?.isActual ? 'actuals-cell' : ''
                      }`,
                      hasFormulaError: false,
                    };
                    return row;
                  })
                : []),
              {
                type: 'dropdown',
                buttonType: 1,
                currencyType: modelCurrency,
                selectOption: false,
                className: `${item?.warning?.length > 0 ? 'formula-error' : ''}`,
                id: item?.id,
                itemName: item?.name,
                nonEditable: !item?.id && !item?.indicatorId,
                values: [{ label: 'Delete row', value: '' }],
              },
            ],
          };
        })
      : []),
    dimensionalFooterRow(tableProperty_),
  ];
};
// Utility function to calculate text width
const getTextWidth = (text: any) => {
  const canvas = document.createElement('canvas');
  const context: any = canvas.getContext('2d');
  const metrics = context.measureText(text);
  return metrics.width;
};
const getDimensionalColumns = (tableProperty_: any, tableReference: any, plannerBlock?: any) => {
  // Find the maximum width of the content in the "Name" column
  const nameColumnContent = tableProperty_?.map((data: any) => data.name) || [];
  const maxNameWidth = Math.max(...nameColumnContent.map((name: any) => getTextWidth(name)), 250);
  const columnWidth =
    tableProperty_ || tableReference
      ? dimensionalCalculateWidth(tableProperty_?.length, tableReference)
      : 200;
  return [
    {
      columnId: 'name',
      width:
        plannerBlock?.indicator_column_size !== null
          ? plannerBlock?.indicator_column_size
          : maxNameWidth,
      resizable: true,
      reorderable: true,
    },
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any) => {
          const row = {
            columnId: `${data?.id}`,
            width: columnWidth,
            resizable: true,
            reorderable: true,
          };
          return row;
        })
      : []),
    { columnId: 'delete', width: 60 },
  ];
};

const headerRow = (tableProperty_: any) => ({
  rowId: 'header',
  height: 40,
  cells: [
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any, index: any) => {
          const row = {
            type: 'header',
            colType: 'header',
            nonEditable: true,
            text: data?.name,
            className: `header-cell ${index === tableProperty_?.length - 1 ? ' last-index ' : ''}`,
          };
          return row;
        })
      : []),
    {
      type: 'header',
      text: 'Del',
      nonEditable: true,
      colType: 'header',
      className: 'header-cell header-last',
    },
  ],
});

const footerRow = (tableProperty_: any) => ({
  rowId: 'footer',
  height: 40,
  cells: [
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any, index: number) => {
          if (index === 0) {
            const row = {
              type: 'addRow',
              text: '',
              showText: true,
              className: 'footer footer-first',
              nonEditable: true,
            };
            return row;
          }
          const row = {
            type: 'text',
            text: '',
            nonEditable: true,
            className: `${index === tableProperty_?.length - 1 ? ' last-index ' : ''} footer`,
            hasFormulaError: false,
          };
          return row;
        })
      : []),
    {
      type: 'text',
      text: '',
      nonEditable: true,
      className: 'footer footer-last',
      hasFormulaError: false,
    },
  ],
});

const getRows = (tableItemsProperty_: any, tableProperty_: any, indicators: any) => {
  return [
    headerRow(tableProperty_),
    ...(tableItemsProperty_?.length
      ? tableItemsProperty_?.map((item: any, index: any) => {
          return {
            rowId: item?.id,
            height: 40,
            itemId: item?.id,
            itemName: item?.['Block indicators'],
            reorderable: true,
            isNewCell: !!item?.isNewCell,
            cells: [
              ...(tableProperty_?.length
                ? tableProperty_?.map((data: any, index_: any) => {
                    if (data?.name === 'Type') {
                      const row = {
                        hasFormulaError: item?.warning?.length > 0,
                        hasFormulaErrorMessage: item?.warning,
                        className: `${
                          index_ === tableProperty_?.length - 1 ? ' last-index ' : ''
                        } ${item?.warning?.length > 0 ? 'formula-error ' : ''}`,
                        type: 'dropdown',
                        nonEditable: !item?.id,
                        selectOption: false,
                        key: 'type',
                        selectedValue: item[`${data?.name}`].toLowerCase(),
                        values: [
                          {
                            label: 'Input',
                            value: 'input',
                          },
                          {
                            label: 'Calculation',
                            value: 'calculation',
                          },
                        ],
                        index,
                        operation: '',
                      };
                      return row;
                    }
                    if (data?.name === 'Format') {
                      const row = {
                        className: `${index_ === tableProperty_?.length - 1 && ' last-index '} ${
                          item?.warning?.length > 0 && 'formula-error '
                        }`,
                        type: 'dropdown',
                        nonEditable: !item?.id,
                        key: 'data_format',
                        selectOption: false,
                        selectedValue: item[`${data?.name}`].toLowerCase(),
                        values: [
                          {
                            label: 'Number',
                            value: 'number',
                          },
                          {
                            label: 'Integer',
                            value: 'integer',
                          },
                          {
                            label: 'Percentage',
                            value: 'percentage',
                          },
                          {
                            label: 'Currency',
                            value: 'currency',
                          },
                        ],
                        index,
                        operation: '',
                      };
                      return row;
                    }
                    if (data?.name === 'Roll up type') {
                      const row = {
                        className: `${
                          index_ === tableProperty_?.length - 1 ? ' last-index ' : ''
                        } ${item?.warning?.length > 0 ? 'formula-error ' : ''}`,
                        type: 'dropdown',
                        nonEditable: !item?.id,
                        selectOption: false,
                        key: 'data_type',
                        selectedValue: item[`${data?.name}`].toLowerCase(),
                        values: [
                          {
                            label: 'Number',
                            value: 'number',
                          },
                          {
                            label: 'Statistic',
                            value: 'statistic',
                          },
                          {
                            label: 'Percentage',
                            value: 'percentage',
                          },
                          {
                            label: 'Ratio',
                            value: 'ratio',
                          },
                          {
                            label: 'Balance',
                            value: 'balance',
                          },
                          {
                            label: 'Opening Balance',
                            value: 'opening_Balance',
                          },
                        ],
                        index,
                        operation: '',
                      };
                      return row;
                    }
                    if (data?.name === 'Default chart type') {
                      const row = {
                        className: `${
                          index_ === tableProperty_?.length - 1 ? ' last-index ' : ''
                        } ${item?.warning?.length > 0 ? 'formula-error ' : ''}`,
                        type: 'dropdown',
                        nonEditable: !item?.id,
                        selectOption: false,
                        key: 'chart_type',
                        selectedValue: item[`${data?.name}`].toLowerCase(),
                        values: [
                          {
                            label: 'Line',
                            value: 'line',
                          },
                          {
                            label: 'Stack',
                            value: 'stack',
                          },
                          {
                            label: 'Bar',
                            value: 'bar',
                          },
                        ],
                        index,
                        operation: '',
                      };
                      return row;
                    }
                    if (data?.name === 'Display as output') {
                      const row = {
                        type: 'checkbox',
                        key: 'favourite',
                        className: `${
                          index_ === tableProperty_?.length - 1 ? ' last-index ' : ''
                        } ${item?.warning?.length > 0 ? 'formula-error ' : ''}`,
                        checked: item[`${data?.name}`],
                      };
                      return row;
                    }
                    if (data?.name === 'Display as sub-total') {
                      const row = {
                        type: 'checkbox',
                        key: 'output_format',
                        className: `${index_ === tableProperty_?.length - 1 ? ' last-index ' : ''}${
                          item?.warning?.length > 0 ? 'formula-error ' : ''
                        }`,
                        extraKey: item[`${data?.name}`],
                        checked: item[`${data?.name}`] === 'subtotal',
                      };
                      return row;
                    }
                    if (data?.name === 'Block indicators') {
                      const row = {
                        type: 'text',
                        rows: tableItemsProperty_?.length,
                        indicatorType: item?.indicator_type,
                        key:
                          data?.name === 'Block indicators'
                            ? 'name'
                            : data?.name === 'Formula'
                            ? 'formula'
                            : data?.name === 'Help text'
                            ? 'help'
                            : '',
                        text: `${item[data?.name]}` ?? '',
                        isDraggable: true,
                        property: 'name',
                        isNewCell: !!item?.isNewCell,
                        index,
                        nonEditable: !item?.id && !item?.indicatorId,
                        hasFormulaError: item?.warning?.length > 0,
                        hasFormulaErrorMessage: item?.warning,
                        className: `${
                          index_ === tableProperty_?.length - 1 ? ' last-index ' : ''
                        } ${item?.warning?.length > 0 ? 'formula-error ' : ''}`,
                      };
                      return row;
                    }
                    if (data?.name === 'Formula') {
                      const row = {
                        type: 'formula',
                        text: `${item[data?.name]}` ?? '',
                        className: `${
                          index_ === tableProperty_?.length - 1
                            ? ' last-index '
                            : 'disabled cell-disabled'
                        } ${item?.warning?.length > 0 ? 'formula-error ' : ''}`,
                        options: indicators,
                        nonEditable: true,
                      };
                      return row;
                    }
                    const row = {
                      type: 'text',
                      rows: tableItemsProperty_?.length,
                      key:
                        data?.name === 'Block indicators'
                          ? 'name'
                          : data?.name === 'Formula'
                          ? 'formula'
                          : data?.name === 'Help text'
                          ? 'help'
                          : '',
                      text: `${item[data?.name]}` ?? '',
                      index,
                      data,
                      nonEditable: !item?.id && !item?.indicatorId,
                      className: `${index_ === tableProperty_?.length - 1 ? ' last-index ' : ''} ${
                        item?.warning?.length > 0 ? 'formula-error ' : ''
                      }`,
                      hasFormulaError: false,
                    };
                    return row;
                  })
                : []),
              {
                type: 'dropdown',
                buttonType: 1,
                selectOption: false,
                id: item?.id,
                className: `${item?.warning?.length > 0 ? 'formula-error ' : ''}`,
                itemName: item?.['Block indicators'],
                nonEditable: !item?.id && !item?.indicatorId,
                values: [{ label: 'Delete row', value: '' }],
              },
            ],
          };
        })
      : []),
    footerRow(tableProperty_),
  ];
};

const getColumns = (tableProperty_: any, tableReference: any) => {
  const columnWidth =
    tableProperty_ || tableReference ? calculateWidth(tableProperty_?.length, tableReference) : 200;
  return [
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any) => {
          const row = {
            columnId: `${data?.id}`,
            width: columnWidth,
            resizable: true,
            reorderable: true,
          };
          return row;
        })
      : []),
    { columnId: 'delete', width: 60 },
  ];
};

const findChevronCell = (row: Row) =>
  row.cells.find((cell) => cell.type === 'chevron') as ChevronCell | undefined;

const findParentRow = (rows_: any, row: Row) =>
  rows_.find((r: any) => {
    const foundChevronCell = findChevronCell(row);
    return foundChevronCell ? r.rowId === foundChevronCell.parentId : false;
  });

const hasChildren = (rows__: any, row: Row): boolean =>
  rows__.some((r: any) => {
    const foundChevronCell = findChevronCell(r);
    return foundChevronCell ? foundChevronCell.parentId === row.rowId : false;
  });

const isRowFullyExpanded = (rows___: Row[], row: Row): boolean => {
  const parentRow = findParentRow(rows___, row);
  if (parentRow) {
    const foundChevronCell = findChevronCell(parentRow);
    if (foundChevronCell && !foundChevronCell.isExpanded) return false;
    return isRowFullyExpanded(rows___, parentRow);
  }
  return true;
};

const getExpandedRows = (rows____: Row[]): Row[] => {
  if (!rows____?.length) {
    return [];
  }
  return rows____.filter((row) => {
    const areAllParentsExpanded = isRowFullyExpanded(rows____, row);
    return areAllParentsExpanded !== undefined ? areAllParentsExpanded : true;
  });
};

const getDirectChildRows = (rows_____: Row[], parentRow: Row): Row[] =>
  rows_____.filter(
    (row) =>
      !!row.cells.find(
        (cell) => cell.type === 'chevron' && (cell as ChevronCell).parentId === parentRow.rowId,
      ),
  );

const assignIndentAndHasChildren = (rows______: Row[], parentRow: Row, indent: number = 0) => {
  ++indent;
  getDirectChildRows(rows______, parentRow).forEach((row) => {
    const foundChevronCell = findChevronCell(row);
    const hasRowChildrens = hasChildren(rows______, row);
    if (foundChevronCell) {
      foundChevronCell.indent = indent;
      foundChevronCell.hasChildren = hasRowChildrens;
    }
    if (hasRowChildrens) assignIndentAndHasChildren(rows______, row, indent);
  });
};

const buildTree = (rows_______: Row[]): Row[] =>
  rows_______.map((row) => {
    const foundChevronCell = findChevronCell(row);
    if (foundChevronCell && !foundChevronCell.parentId) {
      const hasRowChildrens = hasChildren(rows_______, row);
      foundChevronCell.hasChildren = hasRowChildrens;
      if (hasRowChildrens) assignIndentAndHasChildren(rows_______, row);
    }
    return row;
  });

const handleLocationChanged = (event: any, setFocus: any) => {
  setFocus(event);
  return true;
};

const reorderArray = <T extends {}>(array: T[], idxs: number[], to: number) => {
  const movedElements = array.filter((_, index) => idxs.includes(index));
  const targetIndex =
    Math.min(...idxs) < to ? (to += 1) : (to -= idxs.filter((index) => index < to).length);
  const leftSide = array.filter((_, index) => index < targetIndex && !idxs.includes(index));
  const rightSide = array.filter((_, index) => index >= targetIndex && !idxs.includes(index));
  const reorderedArray = [...leftSide, ...movedElements, ...rightSide];
  const idsOnlyArray = reorderedArray.map((item: any) => item.id);
  return idsOnlyArray;
};

const handleCanReorderRows = (targetRowId: any) => {
  return typeof targetRowId === 'number';
};

const handleCellChange = (changes: any, columns: any, setRows: any) => {
  setRows((rows_: any) => {
    changes.forEach((change: any) => {
      const changeRowIndex = rows_.findIndex((element: any) => element.rowId === change.rowId);
      const changeColumnIndex = columns.findIndex(
        (element: any) => element.columnId === change.columnId,
      );
      rows_[changeRowIndex].cells[changeColumnIndex] = change?.newCell;
    });
    return [...rows_];
  });
};

const handleColumnResize = (ci: any, width: number, setColumns: any) => {
  setColumns((column: any) => {
    const columnIndex = column.findIndex((element: any) => element.columnId === ci);
    const resizedColumn = column[columnIndex];
    const updatedColumn = { ...resizedColumn, width };
    column[columnIndex] = updatedColumn;
    return [...column];
  });
};

export {
  headerRow,
  dimensionalHeaderRow,
  footerRow,
  dimensionalFooterRow,
  getDimensionalRows,
  getRows,
  getDimensionalColumns,
  getColumns,
  buildTree,
  reorderArray,
  getExpandedRows,
  handleCellChange,
  handleColumnResize,
  handleLocationChanged,
  handleCanReorderRows,
};

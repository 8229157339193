const breakpoints = {
  // Authentication
  login: () => '/login',
  signup: () => '/signup',
  guest_login: () => '/guest/login',
  version: () => '/version?app_type=traction',
  setPassword: () => `user/set-new-password`,
  set_password: () => '/user/change-password',
  reset_password: () => '/user/reset-password',
  change_password: () => '/user/change-password',
  set_new_password: () => '/user/set-new-password',

  // User
  user: () => '/user',
  subscribe_user: () => '/subscribe',
  add_team_member: () => '/user/invite',
  get_user_team_list: () => '/user/team',
  user_invitation: () => `/user/invitation`,
  fetch_user_organization_details: () => '/user/organization',
  update_user_team_permission: () => '/user/team',
  update_user_organization_details: () => '/user/organization',
  invitation_user: () => '/user/invite/validate',
  fetch_user_plan_detail: () => '/subscription_list',
  fetch_user_payment_details: () => '/billing_details',
  cancel_user_subscription: () => '/subscription_cancel',
  delete_user: (userId: number) => `/user/team?user_id=${userId}`,
  fetch_subscription_features_limits: () => `/subscription_features_limit`,

  // Models
  models: () => 'models',
  user_models: () => '/user/models',
  model_access: () => `model/access`,
  model: (modelId: number) => `/model/${modelId}`,
  modelCopy: (modelId: number) => `/model/${modelId}/copy`,
  model_info: (modelId: number) => `/model/${modelId}/info`,
  model_copy: (modelId: number) => `/model/${modelId}/copy`,
  model_time: (modelId: number) => `/model/${modelId}/time`,
  model_link: (modelId: number) => `/model/${modelId}/link`,
  model_share: (modelId: number) => `/model/${modelId}/share`,
  model_outputs: (modelId: number) => `/model/${modelId}/outputs`,
  share_model_user_permission: (modelId: number) => `/model/${modelId}/user_access`,
  model_metadata: (modelId: number) => `/model/${modelId}/metadata`,
  model_formula_metadata: (modelId: number) => `/model/${modelId}/metadata?filter=all`,
  dimensionReordering: (modelId: any) => `model/${modelId}/dimensions/reorder`,
  dimTableReorder: (dimId: number) => `dimension/${dimId}/items-properties/reorder`,

  // Blocks
  block: (blockId: number) => `/block/${blockId}`,
  add_block: (modelId: number) => `/model/${modelId}/blocks`,
  duplicate_block: (blockId: number) => `/block/${blockId}/copy`,
  block_export: (blockId: number) => `/block/${blockId}/export`,
  blockMap: (blockId: number) => `/block/${blockId}/map`,
  block_outputs: (blockId: number) => `/block/${blockId}/outputs`,
  block_drivers: (blockId: number) => `/block/${blockId}/drivers`,
  block_values: (blockId: number) => `/block/${blockId}/data_values`,
  block_metadata: (blockId: number) => `/block/${blockId}/metadata`,
  block_dimensions: (blockId: number) => `/block/${blockId}/dimensions`,
  block_item_dimensions: (blockId: number) =>
    `/block/${blockId}/dimensions?ignore_time=true&include_items=true`,
  add_indicator: (blockId: number) => `/block/${blockId}/indicators`,
  reorder_blocks: (modelId: number) => `/model/${modelId}/blocks/reorder`,
  time_filter: (blockId: number) => `/block/${blockId}/outputs`,

  // Indicators
  indicator_data_input_display: (indicatorId: number) =>
    `/indicator/${indicatorId}/data_input/display`,
  bulk_update_indicator_data: () => `/indicator/item/bulk_update`,
  indicator: (indicatorId: number) => `/indicator/${indicatorId}`,
  duplicate_indicator: (indicatorId: number) => `/indicator/${indicatorId}/copy`,
  indicator_data: (indicatorId: number) => `/indicator/${indicatorId}/data`,
  indicator_output: (indicatorId: number) => `/indicator/${indicatorId}/output`,
  indicator_details: (indicatorId: number) => `/indicator/${indicatorId}/details`,
  indicator_data_input: (indicatorId: number) => `/indicator/${indicatorId}/data_input`,
  indicator_reorder: (blockId: number) => `/block/${blockId}/indicators/reorder`,
  indicator_data_reorder: (indicatorId: number) => `/indicator/${indicatorId}/data_input/reorder`,

  // Dimensions
  group_dim_name: (dimId: any) => `dimension/${dimId}`,
  dimensions: (modelId: string) => `/model/${modelId}/dimensions`,
  delete_property: (item_id: any) => `/dimension/item/${item_id}`,
  delete_multiple_property: () => `/dimension/item/bulk-delete`,
  dimensionReorder: (itemId: number | string) => `dimension/${itemId}/items/reorder`,
  dimension_item: (itemId: number) => `/dimension/item/${itemId}`,
  update_property_bulk: () => `/dimension/items_properties/update`,
  update_group_item_name: (itemId: any) => `/dimension/item/${itemId}`,
  delete_group_item_name: (itemId: any) => `/dimension/item/${itemId}`,
  editDriverInput: (blockId: number) => `/block/${blockId}/edit_driver`,
  update_property_name: (item_id: number) => `/dimension/item/${item_id}`,
  dimension_items: (dimensionId: number) => `/dimension/${dimensionId}/items`,
  dimension_properties: (itemId: number) => `/dimension/${itemId}/properties`,
  update_property: (item_id: number) => `/dimension/item/${item_id}/properties`,
  dimension_items_group: (itemId: number) => `/dimension/${itemId}/items/group`,
  additem_property: (dimension_id: number) => `/dimension/${dimension_id}/items`,
  dimensionProperty: (propertyId: number) => `/dimension/property/${propertyId}`,
  duplicatedimension: (dimId: number) => `/dimension/${dimId}/copy`,
  get_dimension_group: (dimension_id: any) => `/dimension/${dimension_id}/items`,
  dimensionProperties: (dimensionId: any) => `/dimension/${dimensionId}/properties`,
  dimension_item_properties: (itemId: number) => `/dimension/${itemId}/items/properties`,
  dimension_items_group_properties: (itemId: number) => `/dimension/item/${itemId}/properties`,
  dimension_property_values: (propertyId: number) => `/dimension/property/${propertyId}/values`,
  dimensionItemsWithProperties: (dimensionId: any) => `/dimension/${dimensionId}/items/properties`,

  // Scenarios
  get_scenarios: (modelId: number) => `/model/${modelId}/scenarios`,
  renameScenario: (scenariosId: number) => `/scenario/${scenariosId}`,
  singleScenario: (scenariosId: number) => `/scenario/${scenariosId}`,
  createNewScenario: (modelId: number) => `/model/${modelId}/scenarios`,
  deleteOneScenario: (scenariosId: number) => `/scenario/${scenariosId}`,
  compareScenario: (blockId: number) => `/block/${blockId}/scenario_compare`,

  // dataImport
  uploadExcelFile: (modelId: number) => `/model/${modelId}/data_source`,
  getSingleExcelFileDetails: (modelId: number, fileId: number) =>
    `/model/${modelId}/data_source/${fileId}`,
  getAllActionData: (dataSourceId: number) => `/data_source/${dataSourceId}/action`,
  duplicateScenario: (scenariosId: number) => `/scenario/${scenariosId}/copy`,
  getDataSourceVersion: (dataSourceId: number) => `/data_source/${dataSourceId}/version`,
  dataSourceVersion: (dataSourceId: number, versionId: number) =>
    `/data_source/${dataSourceId}/version/${versionId}`,
  dataSourceMappings: (dataSourceId: number, actionId: number) =>
    `/data_source/${dataSourceId}/action/${actionId}/mappings`,
  actionData: (dataSourceId: number, actionId: number) =>
    `/data_source/${dataSourceId}/action/${actionId}`,
  duplicateDataSource: (dataSourceId: number) => `/data_source/${dataSourceId}/copy`,
  duplicateActionCopy: (dataSourceId: number, actionId: number) =>
    `/data_source/${dataSourceId}/action/${actionId}/copy`,

  // Dashboard
  dashboard: (modelId: number) => `/dashboard/${modelId}`,
  dashboardDataValues: (chartId: any, scenarioId: number) =>
    `/dashboard/data_values/${chartId}/${scenarioId}`,
  createDashboard: () => `/dashboard/`,
  createchart: () => '/instrument/',
  // dashboardBlock: (modelId: number) => `/model/${modelId}/blocks-indicators`,
  dashboardBlock: (modelId: number) => `/model/${modelId}/blocks`,
  dashboardTimeFilter: () => `/dashboard/dashboard-time-filter`,
  getChart: (chartId: any) => `/instrument/${chartId}`,
  getGraph: (chartId: any, scenarioId: number) => `/instrument/graph/${chartId}/${scenarioId}`,
  conditionalFormatting: () => `/instrument/conditional-formatting`,
  addMetric: () => `/instrument/indicator`,
  chartFilter: () => `/instrument/filter`,
  updateLayout: () => `/dashboard/`,
  dashboardMetadata: (dashboardId: string) => `/dashboard/metadata/${dashboardId}`,
  add_instrument: (dashboardId: string) => `/dashboard/metadata/${dashboardId}`,
  updateInstrumentType: () => `/instrument/update`,
  deleteFilter: (filterId: string) => `chart/filter/${filterId}`,
  deleteConditional: (conditionId: string) => `chart/conditional-formatting/${conditionId}`,
  allCharts: (dashboardId: string) => `/instrument/all/${dashboardId}`,
  deleteChart: (chartId: string) => `/instrument/${chartId}`,
  deleteChartMetric: (metricId: string) => `/instrument/indicator/${metricId}`,
  reorderChartMetric: () => `/instrumentreorder/indicator`,
  // output-optimizaton-v2
  block_outputs_V2: (blockId: number) => `/block/${blockId}/outputs/v2`,
};

export default breakpoints;

import { Button, HStack } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { STATUS } from 'projectConstants';
import { useAuthContext } from 'context/authContext';
import { getSharedModelUsers } from 'redux/HeaderSlice';
import { getIndicatorDetailStatus, getModelTime, setTabShimmer } from 'redux/IndicatorPageSlice';
import { UpdatePlangPageIndicatorTableData } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface Properties {
  inputDimensions: { id: string }[];
  inputType: string;
  indicatorId: number;
  blockId: number;
  refetchDrivers: () => void;
  refetchRequest: () => void;
  inputDataValues: Record<string, string>[];
  timeDimension: number;
  timeRange: string[];
  timeRangeYears: string[];
}

const InputTypeGroupedButton = ({
  inputDimensions,
  inputType,
  indicatorId,
  refetchRequest,
}: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('');
  const modelTime = useSelector(getModelTime);
  const timeGranularity = modelTime?.properties?.time_granularity.toLowerCase();
  const indicatorDetailStatus = useSelector(getIndicatorDetailStatus);
  const baseScenario = useSelector(getBaseScenario);
  const [isAPiLoading, setApiLoading] = useState(false);

  const { user } = useAuthContext();
  const shareModelUsers = useSelector(getSharedModelUsers);

  const currentUserPermissions = shareModelUsers.find((sharedUser: any) => {
    return user && sharedUser?.user_id === user?.id;
  });

  const disableChange =
    indicatorDetailStatus !== STATUS.SUCCESS ||
    isAPiLoading ||
    currentUserPermissions?.role === 'view';

  const onChangeDataInput = debounce(async (newType: string) => {
    if (activeTab === newType) return;
    if (inputDimensions) {
      setApiLoading(true);
      const payloadNew: any = {
        indicator_id: indicatorId,
        request_body: {
          type: newType,
          scenario_id: baseScenario?.id,
          bulk_input: true,
          format_excel_data: true,
        },
      };
      setActiveTab(newType);

      dispatch(setTabShimmer(true));
      await dispatch(UpdatePlangPageIndicatorTableData(payloadNew));
      setTimeout(() => {
        setApiLoading(false);
      }, 600);
      refetchRequest();
    } else {
      setApiLoading(true);
      const payloadNew: any = {
        indicator_id: indicatorId,
        request_body: {
          type: newType,
          scenario_id: baseScenario?.id,
          bulk_input: true,
          format_excel_data: true,
        },
      };
      setActiveTab(newType);
      dispatch(setTabShimmer(true));
      await dispatch(UpdatePlangPageIndicatorTableData(payloadNew));
      setTimeout(() => {
        setApiLoading(false);
      }, 600);
      refetchRequest();
    }
  }, 1000);
  useEffect(() => {
    if (timeGranularity === 'y' && inputType === 'raw') {
      setActiveTab('constant_by_year');
      onChangeDataInput('constant_by_year');
    } else {
      setActiveTab(inputType);
    }
  }, [inputType]);
  return (
    <HStack
      spacing={0}
      align='center'
      w='full'
      mb={{
        base: 2,
        md: 2,
      }}
      borderRadius='20px'
      borderWidth={1}
      borderColor='transparent'
      bg={'#8885DD'}
    >
      <Button
        isDisabled={disableChange}
        height={10}
        variant='tab'
        size='tab'
        fontSize={{ base: '11px', md: '12px' }}
        className={inputType === 'constant' ? '#4BB6B9' : 'transparent'}
        onClick={() => onChangeDataInput('constant')}
        bgColor={activeTab === 'constant' ? '#4BB6B9' : 'transparent'}
        borderRadius={activeTab === 'constant' ? '20px' : '2px'}
        borderColor='transparent'
      >
        Constant
      </Button>
      <>
        <Button
          height={10}
          variant='tab'
          size='tab'
          fontSize={{ base: '11px', md: '12px' }}
          bgColor={activeTab === 'constant_by_year' ? '#4BB6B9' : 'transparent'}
          borderRightWidth={1}
          borderLeftWidth={1}
          onClick={() => {
            onChangeDataInput('constant_by_year');
          }}
          borderRadius={activeTab === 'constant_by_year' ? '20px' : '2px'}
          borderColor='transparent'
          isDisabled={disableChange}
        >
          Yearly
        </Button>
        {timeGranularity && timeGranularity === 'm' && (
          <Button
            height={10}
            variant='tab'
            size='tab'
            fontSize={{ base: '11px', md: '12px' }}
            className={activeTab === 'raw' ? '#4BB6B9' : 'transparent'}
            onClick={() => {
              onChangeDataInput('raw');
            }}
            bgColor={activeTab === 'raw' ? '#4BB6B9' : 'transparent'}
            borderRadius={activeTab === 'raw' ? '20px' : '2px'}
            borderColor='transparent'
            isDisabled={disableChange}
          >
            Monthly
          </Button>
        )}
      </>
      <Button
        fontSize={{ base: '11px', md: '12px' }}
        height={10}
        variant='tab'
        size='tab'
        borderColor='transparent'
        bgColor={activeTab === 'growth' ? '#4BB6B9' : 'transparent'}
        onClick={() => {
          onChangeDataInput('growth');
        }}
        borderRadius={activeTab === 'growth' ? '20px' : '2px'}
        isDisabled={disableChange}
      >
        Growth rate
      </Button>
    </HStack>
  );
};
export default InputTypeGroupedButton;

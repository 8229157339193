import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { DragHandleIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import RemoveIconComponent from 'components/Icons/RemoveIcon';
import { getDashboardBlocks } from 'redux/DashboardSliceV2';
import SearchIcon from 'components/Icons/SearchIcon';
import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import CustomToast from 'components/CustomToast';

export interface Properties {
  indicator: any;
  selectBlock: (block_id: any) => void;
  selectIndicator: (indicator: any) => void;
  selectDimension: (dimension: any) => void;
  removeIndicator: (indicator_id: any) => void;
  showPopup: boolean;
}

const IndicatorCard = ({
  indicator,
  selectBlock,
  selectIndicator,
  selectDimension,
  removeIndicator,
  showPopup,
}: Properties) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const blockDetails = useSelector(getDashboardBlocks);
  const [hover, setHover] = useState<boolean>(false);
  const [blockMenuOpen, setBlockMenuOpen] = useState(false);
  const [indicatorMenuOpen, setIndicatorMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueIndicator, setSearchValueIndicator] = useState('');
  console.log(searchValue, searchValueIndicator);
  const [blocksData, setBlocksData] = useState(blockDetails);
  const [indicators, setIndicators] = useState([]);
  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id: indicator?.indicator_id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };
  const selectedBlock = useMemo(() => {
    if (indicator?.block_id) {
      const blockData = blockDetails?.find(
        (item: any) => item.block_id === Number(indicator.block_id),
      );
      return blockData?.block_name || 'Select block';
    }
    return null;
  }, [indicator?.block_id, blockDetails]);

  const blockIndicators = useMemo(() => {
    if (indicator?.block_id) {
      const blockData = blockDetails?.find(
        (item: any) => item.block_id === Number(indicator.block_id),
      );
      return blockData.indicators;
    }
    return [];
  }, [indicator?.block_id, blockDetails]);

  const dimensions = useMemo(() => {
    if (indicator?.block_id) {
      const blockData = blockDetails?.find(
        (item: any) => item.block_id === Number(indicator.block_id),
      );
      return blockData?.dimensions
        ? blockData?.dimensions?.filter((dim: any) => dim.dimension_name !== 'Time')
        : [];
    }
    return [];
  }, [indicator?.block_id, blockDetails]);

  const selectedDimensionName = useMemo(() => {
    if (indicator?.dimension_id && blockIndicators?.length) {
      const dimData = dimensions?.find(
        (item: any) => item.dimension_id === Number(indicator.dimension_id),
      );
      return dimData?.dimension_name;
    }
    return null;
  }, [indicator?.dimension_id, dimensions]);

  const selectedIndicatorName = useMemo(() => {
    if (indicator?.block_id && blockIndicators?.length) {
      const blockData = blockIndicators?.find(
        (item: any) => item.indicator_id === indicator.indicator_id,
      );
      return blockData?.indicator_name || 'Select indicator';
    }
    return null;
  }, [indicator?.indicator_id, blockIndicators]);

  useEffect(() => {
    setIndicators(blockIndicators);
  }, [blockIndicators]);

  const handleRemoveIndicator = (indicator_id: any) => {
    removeIndicator(indicator_id);
  };

  const handleBlockItemClick = (block: any) => {
    selectBlock(block.block_id);
    setIndicatorMenuOpen(true);
  };

  const handleAddIndicator = (item: any) => {
    selectIndicator(item);
    setIndicatorMenuOpen(false);
  };

  const handleDimensionSelect = (dim: any) => {
    if (!showPopup) {
      selectDimension(dim);
    } else {
      setDisplayPopup(true);
      setTimeout(() => {
        setDisplayPopup(false);
      }, 3000);
    }
  };

  const handleBlockSearch = (value: string) => {
    setBlocksData(
      blockDetails?.filter((item: any) =>
        item.block_name.toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setSearchValue(value);
  };

  const handleSearchValueIndicator = (value: string) => {
    setIndicators(
      blockIndicators?.filter((item: any) =>
        item.indicator_name.toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setSearchValueIndicator(value);
  };

  return (
    <>
      <Box alignItems={'center'} ref={setNodeRef} style={style}>
        {indicator && (
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Flex
              display={'flex'}
              flexDirection={'row'}
              width={'100%'}
              justifyContent={'space-between'}
              paddingY={'8px'}
              bgColor={'#F5F5F5'}
              borderRadius={'7px'}
              pe={'3px'}
            >
              <Flex alignItems={'center'}>
                <DragHandleIcon
                  visibility={hover ? 'visible' : 'hidden'}
                  {...listeners}
                  {...attributes}
                  cursor={'grab'}
                  color={'#959595'}
                  _focus={{ outline: 'none' }}
                  height={'13px'}
                />
                <Flex flexDirection={'column'} p={1}>
                  <Menu
                    isOpen={blockMenuOpen}
                    closeOnSelect={true}
                    strategy={'fixed'}
                    placement='left'
                    onClose={() => setBlockMenuOpen(!blockMenuOpen)}
                  >
                    <MenuButton
                      onClick={() => setBlockMenuOpen(true)}
                      title={selectedIndicatorName}
                    >
                      <Text
                        fontSize={'14px'}
                        textAlign={'left'}
                        textOverflow='ellipsis'
                        whiteSpace={'nowrap'}
                        overflow='hidden'
                        width='100px'
                      >
                        {selectedIndicatorName || 'Select indicator'}
                      </Text>
                    </MenuButton>
                    <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
                      <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents='none'
                            h='100%'
                            children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                          />
                          <Input
                            width={'93%'}
                            color={'black.900'}
                            _placeholder={{ color: '#D9D9D9' }}
                            // ref={inputReference}
                            placeholder='Search'
                            onChange={(event: any) => {
                              handleBlockSearch(event.target.value);
                            }}
                          />
                        </InputGroup>
                      </Flex>
                      {blockDetails?.length !== 0 ? (
                        <Box className='scroll' overflow='scroll' maxHeight={'150px'}>
                          {blocksData?.map((data: any) => (
                            <Flex
                              key={data?.blockId}
                              alignItems={'center'}
                              borderRadius={'6px'}
                              padding={1}
                              _hover={{ backgroundColor: '#EFF2F6' }}
                              onClick={() => {
                                handleBlockItemClick(data);
                              }}
                              // style={itemStyle}
                              cursor={'pointer'}
                            >
                              <Text>{data.block_name}</Text>
                            </Flex>
                          ))}
                        </Box>
                      ) : (
                        <Text>No Block Found</Text>
                      )}
                    </MenuList>
                  </Menu>
                  {indicator?.block_id !== null && (
                    <Text fontSize={'10px'} color={'#787878'}>
                      {selectedBlock}
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex alignItems={'center'} justifyContent={'space-around'}>
                <Box>
                  {/* Indicator menu */}
                  <Menu
                    isOpen={indicatorMenuOpen}
                    strategy={'fixed'}
                    placement='right'
                    offset={[0, 0]}
                    closeOnSelect={true}
                    onClose={() => setIndicatorMenuOpen(false)}
                  >
                    <MenuButton></MenuButton>
                    <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
                      <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents='none'
                            h='100%'
                            children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                          />
                          <Input
                            width={'93%'}
                            color={'black.900'}
                            _placeholder={{ color: '#D9D9D9' }}
                            // ref={inputReference}
                            placeholder='Search'
                            onChange={(event: any) => {
                              handleSearchValueIndicator(event.target.value);
                            }}
                          />
                        </InputGroup>
                      </Flex>
                      {blockIndicators?.length ? (
                        <Box className='scroll' overflow='scroll' maxHeight={'150px'}>
                          {indicators?.map((indicatorData: any) => (
                            <Flex
                              key={indicatorData?.indicator_id}
                              alignItems={'center'}
                              borderRadius={'6px'}
                              paddingInline={2}
                              _hover={{ backgroundColor: '#EFF2F6' }}
                              onClick={() => {
                                handleAddIndicator(indicatorData);
                                // setIndValue(indicatorData);
                              }}
                              // style={itemStyle}
                              cursor={'pointer'}
                            >
                              <Text>{indicatorData.indicator_name}</Text>
                            </Flex>
                          ))}
                        </Box>
                      ) : (
                        <MenuItem>No Indicator Found</MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Box>
              </Flex>
              {dimensions?.length >= 1 && (
                <Menu strategy='fixed'>
                  <MenuButton>
                    <Flex alignItems='center' mr='5px'>
                      <DimensionTabIcon color={'#4BB6B9'} />
                      <Text fontSize={'14px'} color={'#4BB6B9'}>
                        {selectedDimensionName || '+ add'}
                      </Text>
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {dimensions?.map((dim: any) => {
                      return (
                        <MenuItem
                          key={dim?.dimension_id}
                          onClick={() => handleDimensionSelect(dim)}
                        >
                          {dim?.dimension_name}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              )}
            </Flex>
            <Flex alignItems={'center'} mx={2}>
              <RemoveIconComponent onClick={() => handleRemoveIndicator(indicator.indicator_id)} />
            </Flex>
          </Flex>
        )}
      </Box>
      {displayPopup && (
        <CustomToast
          title='Dimensions not available when displaying multiple indicators'
          status='error'
        />
      )}
    </>
  );
};
export default IndicatorCard;

import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

// import setTokenWithExpiry from 'utils/setTokenWithExpiry';
import breakpoints from './breakpoints';

const { REACT_APP_BLOX_BI_URL } = process.env;

class BloxBiService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({ baseURL: REACT_APP_BLOX_BI_URL });
    this.instance.interceptors.request.use(async (config) => {
      const token = localStorage.getItem('BLOX_USER_TOKEN');

      (config.headers as AxiosRequestHeaders).authorization = `Bearer ${token}` as string;
      config.timeout = 60 * 4 * 1000;
      return config;
    });

    // this.instance.interceptors.response.use(
    //   (response) => {
    //     if (response.headers.token !== 'null') {
    //       setTokenWithExpiry(response.headers.token);
    //     } else if (localStorage.getItem('BLOX_USER_TOKEN') === 'null') {
    //       localStorage.removeItem('BLOX_USER_TOKEN');
    //     }
    //     return response;
    //   },
    //   (error) => {
    //     if (
    //       error.response.data.message === 'Token is invalid !!' &&
    //       !window.location.pathname.includes('create')
    //     ) {
    //       const isSignUpPage = window.location.pathname === '/signup';

    //       localStorage.removeItem('BLOX_USER_TOKEN');
    //       localStorage.removeItem('BLOX_USER_TOKEN_EXPIRY');

    //       window.location.href = !isSignUpPage ? '/' : '/signup';
    //     }
    //     return Promise.reject(error);
    //   },
    // );
  }

  // bi services
  createDashboardChart = ({ request_body }: any) => {
    return this.instance.post(breakpoints.createchart(), request_body);
  };

  updateDashboardChart = ({ request_body }: any) => {
    return this.instance.put(breakpoints.createchart(), request_body);
  };

  getDashboardChart = (chartId: any) => {
    return this.instance.get(breakpoints.getChart(chartId));
  };

  getDashboardGraph = (chartId: any, scenarioId: number, requestBody: any) =>
    this.instance.post(breakpoints.getGraph(chartId, scenarioId), requestBody);

  addChartMetric = ({ request_body }: any) =>
    this.instance.post(breakpoints.addMetric(), request_body);

  updateChartMetric = ({ request_body }: any) =>
    this.instance.put(breakpoints.addMetric(), request_body);

  addChartFilter = ({ request_body }: any) =>
    this.instance.post(breakpoints.chartFilter(), request_body);

  updateConditionalFormatting = ({ request_body }: any) =>
    this.instance.put(breakpoints.conditionalFormatting(), request_body);

  addConditionalFormatting = ({ request_body }: any) =>
    this.instance.post(breakpoints.conditionalFormatting(), request_body);

  getAllCharts = (dashboardId: string) => this.instance.get(breakpoints.allCharts(dashboardId));

  updateChartLayout = ({ request_body }: any) =>
    this.instance.put(breakpoints.updateLayout(), request_body);

  updateInstrumentType = ({ request_body }: any) =>
    this.instance.put(breakpoints.updateInstrumentType(), request_body);

  updateChartFilter = ({ request_body }: any) =>
    this.instance.put(breakpoints.chartFilter(), request_body);

  deleteChartFilter = (filterId: string) =>
    this.instance.delete(breakpoints.deleteFilter(filterId));

  deleteConditionalFormatting = (conditionId: string) =>
    this.instance.delete(breakpoints.deleteConditional(conditionId));

  deleteChart = (chartId: any) => this.instance.delete(breakpoints.deleteChart(chartId));

  deleteChartMetric = (metricId: any) =>
    this.instance.delete(breakpoints.deleteChartMetric(metricId));

  reorderChartMetric = ({ request_body }: any) =>
    this.instance.put(breakpoints.reorderChartMetric(), request_body);

  fetch_dashboardid = (modelId: any) => this.instance.get(breakpoints.dashboard(modelId));

  create_dashboard = ({ request_body }: any) =>
    this.instance.post(breakpoints.createDashboard(), request_body);

  dashboard_time_filter = ({ request_body }: any) =>
    this.instance.post(breakpoints.dashboardTimeFilter(), request_body);

  dashboard_metadata = (dashboardId: string) =>
    this.instance.get(breakpoints.dashboardMetadata(dashboardId));

  dashboard_dataValues = (chartId: any, scenarioId: number) =>
    this.instance.get(breakpoints.dashboardDataValues(chartId, scenarioId));
}

export default new BloxBiService();

import { Flex, Button, Text, Link } from '@chakra-ui/react';

import Assistant from 'components/Icons/Assistant';

export interface Properties {
  onRetry: () => void;
}
const ErrorFull = ({ onRetry }: Properties) => {
  const mailto = `mailto:support@blox.so?subject=Problem%20loading%20dashboard&body=Hi%20team,%20I%20had%20a%20persistent%20issue%20loading%20data%20on%20my%20dashboard,%20could%20you%20please%20have%20a%20look?`;
  return (
    <Flex h='calc(100% - 70px)' direction='column' align='center' justify='center' marginTop='10px'>
      <Flex position='relative'>
        <Assistant
          styles={{
            transform: 'scaleX(-1)',
            position: { base: 'absolute', sm: 'static' },
            top: '-125%',
            left: '25%',
          }}
        />

        <Flex direction='column' justify='space-between'>
          <Text
            mb='2.5'
            fontFamily='bold_roboto'
            fontSize={{ base: 'sm', sm: 'md' }}
            lineHeight='9'
          >
            Oops something went wrong,
          </Text>
          <Text
            width='200px'
            fontFamily='regular_roboto'
            fontSize={{ base: '2xs', sm: 'xs' }}
            lineHeight='5'
          >
            There was an error fetching data, please try again.
          </Text>
          <Link
            href={mailto}
            width={'fit-content'}
            fontFamily='regular_roboto'
            fontWeight='bold'
            isExternal
            fontSize={{ base: '2xs', sm: 'xs' }}
            lineHeight='5'
          >
            Need Help ?
          </Link>
        </Flex>
      </Flex>
      <Button
        variant='outlined'
        size='md'
        w={{ base: '70', sm: '80' }}
        mt={{
          base: 10,
          sm: 14,
          md: 17,
        }}
        onClick={onRetry}
      >
        Refresh
      </Button>
    </Flex>
  );
};

export default ErrorFull;

import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getIndicatorDetail } from 'redux/IndicatorPageSlice';

import MultiDimensionTable from './MultiDimensionTable';

interface Properties {
  refetchRequest: () => void;
  isPercentage: any;
}

const IndicatorTable = ({ refetchRequest, isPercentage }: Properties) => {
  const indicator: any = useSelector(getIndicatorDetail);

  if (!indicator) {
    return (
      <Flex direction='column' pb='1'>
        {[...new Array(6)].map((_, index) => (
          <Flex key={index} p={2} justifyContent={'space-between'}>
            {[...new Array(4)].map((__, subIndex: number) => (
              <Box key={subIndex} flex={1} mr={2}>
                <Skeleton startColor='white' endColor='#CCD4E8' height='35px' width={'100%'} />
              </Box>
            ))}
          </Flex>
        ))}
      </Flex>
    );
  }

  return (
    <Flex ms={2} mt={2}>
      <MultiDimensionTable refetchRequest={refetchRequest} isPercentage={isPercentage} />
    </Flex>
  );
};
export default IndicatorTable;
